import React from 'react';
import Box from '@mui/material/Box';
import styles from './StoryWaterfall.module.scss';
import EngagementRow from 'components/atoms/EngagementRow/EngagementRow.';
import WaterfallInfo from 'components/atoms/waterfallInfo/WaterfallInfo';
import QuickComment from 'components/atoms/quickComment/QuickComment';

interface IProps {
  waterfallId: string;
  userAvatar?: string;
  firstName: string;
  lastName: string;
  userRating: number;
  placeImage: string;
  country: string;
  placeName: string;
  caption: string;
  likes: number;
  dislikes: number;
  comments: number;
  views: number;
  shares: number;
  mediaUrl?: string | null;
  isVideo: boolean;
  reply?: string;
  sendReply: (waterfallId: string, commentText: string) => void;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const StoryWaterfall = ({
  waterfallId,
  userAvatar,
  firstName,
  lastName,
  userRating,
  placeImage,
  country,
  placeName,
  caption,
  likes,
  dislikes,
  comments,
  views,
  shares,
  mediaUrl,
  isVideo,
  sendReply,
  reply,
  updateWaterfallReply,
}: IProps) => {
  return (
    <Box
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${mediaUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      {isVideo
        ? mediaUrl && (
            <video autoPlay loop className={styles.backgroundVideo}>
              <source src={mediaUrl} type="video/mp4" />
            </video>
          )
        : null}

      <Box className={styles.container}>
        <Box className={styles.infoBlock}>
          <WaterfallInfo
            userAvatar={userAvatar}
            firstName={firstName}
            lastName={lastName}
            userRating={userRating}
            placeImage={placeImage}
            country={country}
            placeName={placeName}
            caption={caption}
          />
          <EngagementRow
            likes={likes}
            dislikes={dislikes}
            comments={comments}
            views={views}
            shares={shares}
          />
        </Box>
        <QuickComment
          sendComment={sendReply}
          waterfallId={waterfallId}
          comment={reply}
          updateWaterfallReply={updateWaterfallReply}
        />
      </Box>
    </Box>
  );
};

export default StoryWaterfall;
