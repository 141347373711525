import {Box, Typography} from '@mui/material';
import {SettingsModal} from 'components/molecules';
import {FC} from 'react';
import Colors from 'styles/colors.scss';
import {TERMS_AND_CONDITIONS} from './data/terms-and-conditions';
import {Header} from './components';
import {useTranslation} from 'react-i18next';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const TermsAndConditions: FC<IProps> = ({open, onClose}) => {
  const {t} = useTranslation();

  const lastUpdate = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(2023, 10, 9));

  return (
    <SettingsModal open={open} onClose={onClose}>
      <Box display="flex" flexDirection="column" gap="40px">
        <Header
          title={t('Terms.and.Conditions')}
          text={`${t('Last.Update')}: ${lastUpdate}`}
        />
        {TERMS_AND_CONDITIONS.map(({id, title, content}) => (
          <Box key={id} display="flex" flexDirection="column" gap="16px">
            <Typography variant="text-xl" color={Colors.HEXB8FE61}>
              {id}. {title}
            </Typography>
            {content}
          </Box>
        ))}
      </Box>
    </SettingsModal>
  );
};

export default TermsAndConditions;
