import {Box, Typography} from '@mui/material';
import {GradientIcon} from 'components/molecules';
import {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './ComingSoon.module.scss';

const ComingSoon: FC<PropsWithChildren> = ({children}) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.overlay}>
        <Typography className={styles.text} variant="app-title">
          {t('Coming.soon')}
        </Typography>
        <GradientIcon name="nlnm" size="80px" />
      </Box>
      {children}
    </Box>
  );
};

export default ComingSoon;
