import {Box, Button, Divider, Typography} from '@mui/material';
import {ScrollContainer} from 'components/atoms';
import {TagDropdown} from 'components/molecules';
import SettingsSection from 'components/molecules/settingsSection/SettingsSection';
import {CategoriesOfTags} from 'environment/types/api/shared';
import {FC, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import EditTags from '../../modals/editTags/EditTags';
import styles from './CurrentTags.module.scss';
import {getCurrentAndAvailableTags} from './utils';

interface IProps {
  tags: Required<CategoriesOfTags>;
}

const CurrentTags: FC<IProps> = ({tags}) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const [currentTags, availableTags] = useMemo(
    () => getCurrentAndAvailableTags(tags),
    [tags],
  );

  return (
    <>
      <EditTags
        open={open}
        onClose={() => setOpen(false)}
        defaultValues={{tags}}
      />

      <SettingsSection padding="40px 20px 40px 40px">
        <Box className={styles.header}>
          <Typography variant="text-xl">{t('Current.Tags')}</Typography>
          <Button variant="outlined" onClick={() => setOpen(true)}>
            <Typography className={styles.buttonText}>{t('Edit')}</Typography>
          </Button>
        </Box>

        <Divider orientation="horizontal" sx={{marginY: '16px'}} />

        <ScrollContainer className={styles.scrollContainer}>
          <Box className={styles.tagsList}>
            {currentTags.map(({name, category, emoji}) => (
              <TagDropdown
                key={name}
                category={name}
                label={t(`tagsLabels.${category}.${name}`)}
                emoji={emoji}
                size="small"
                withGradient
              />
            ))}
          </Box>

          <Divider
            orientation="horizontal"
            sx={{marginY: '12px', background: Colors['default-gray-600']}}
          />

          <Box className={styles.availableTagsContainer}>
            <Typography variant="text-xl">
              {t('Other.available.tags')}
            </Typography>
            <Box className={styles.tagsList}>
              {availableTags.map(({name, emoji}) => (
                <TagDropdown
                  key={name}
                  category={name}
                  label={t(`tagsCategories.${name}`)}
                  emoji={emoji}
                  size="small"
                />
              ))}
            </Box>
          </Box>
        </ScrollContainer>
      </SettingsSection>
    </>
  );
};

export default CurrentTags;
