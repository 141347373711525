import {Dispatch, RootState} from 'store';
import RangeButtons from './RangeButtons';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

const AnalyticsRangeButtons = () => {
  const dispatch = useDispatch<Dispatch>();

  const {period} = useSelector((state: RootState) => state.recentActivity);

  return (
    <RangeButtons
      period={period}
      setPeriod={dispatch.recentActivity.setPeriod}
    />
  );
};

export default AnalyticsRangeButtons;
