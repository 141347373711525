import {Box, Button, Divider, Typography} from '@mui/material';
import {ScrollContainer} from 'components/atoms';
import {Mantra, SettingsSection} from 'components/molecules';
import {FormQuestions} from 'hooks/forms/default-values';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import EditPersonality from '../../modals/editPersonality/EditPersonality';
import styles from './Personality.module.scss';

interface IProps {
  questions: FormQuestions;
}

const Personality: FC<IProps> = ({questions}) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <EditPersonality
        open={open}
        onClose={() => setOpen(false)}
        defaultValues={{questions}}
      />

      <SettingsSection padding="40px 20px 40px 40px">
        <Box className={styles.header}>
          <Typography variant="text-xl">{t('Personality')}</Typography>
          <Button variant="outlined" onClick={() => setOpen(true)}>
            <Typography className={styles.buttonText}>{t('Edit')}</Typography>
          </Button>
        </Box>

        <Divider orientation="horizontal" sx={{marginY: '12px'}} />

        <ScrollContainer className={styles.scrollContainer}>
          {questions.map(({title, content}, index) => {
            const titleWithoutCategory = title.split(':').pop();

            return titleWithoutCategory ? (
              <Mantra
                key={index}
                title={t(`mantrasQuestions.${titleWithoutCategory}`)}
                text={content}
              />
            ) : null;
          })}
        </ScrollContainer>
      </SettingsSection>
    </>
  );
};

export default Personality;
