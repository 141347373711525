import {Box, Typography} from '@mui/material';
import {Link} from 'components/atoms';
import {
  PrivacyPolicy,
  SignUpMainForm,
  TermsAndConditions,
} from 'components/organisms';
import {ROUTES} from 'environment/constants/routes';
import {useState, type FC} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './SignUpMain.module.scss';

interface IProps {
  onSubmit: () => void;
  hideRedirectToSignIn?: boolean;
}

const SignUpMainTemplate: FC<IProps> = ({
  onSubmit,
  hideRedirectToSignIn = false,
}) => {
  const {t} = useTranslation();

  const [termsOpen, setTermsOpen] = useState(false);
  const [policyOpen, setPolicyOpen] = useState(false);

  return (
    <>
      <TermsAndConditions
        open={termsOpen}
        onClose={() => setTermsOpen(false)}
      />
      <PrivacyPolicy open={policyOpen} onClose={() => setPolicyOpen(false)} />

      <Typography variant="app-title">{t('Sign.up')}</Typography>
      <Box display="flex" flexDirection="column" gap="28px">
        <SignUpMainForm
          onSubmit={onSubmit}
          onTermsAndConditionsClick={() => setTermsOpen(true)}
          onPrivacyPolicyClick={() => setPolicyOpen(true)}
        />
        {!hideRedirectToSignIn ? (
          <Box className={styles.bottomText}>
            <Typography variant="text-sm-light">
              {t('Do.you.already.have.an.account')}
            </Typography>
            <Link navigateTo={ROUTES.login.home}>{t('Sign.in')}</Link>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default SignUpMainTemplate;
