// function for checking if data is empty and generation fake data to show chart with ranges based to design

export const checkAndGenerateArray = (data: [string, number][]) => {
  let allZeros = true;

  for (const item of data) {
    if (item[1] !== 0) {
      allZeros = false;
      break;
    }
  }

  if (allZeros) {
    const newArray = data.map((item) => [item[0], 1000]);
    return {modifiedArray: newArray, allZeros: true};
  }

  return {modifiedArray: data, allZeros: false};
};
