import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {UseFormReturn} from 'react-hook-form';

import styles from './Dashboard.module.scss';
import {
  currentRestourant,
  nearbyRestourant,
} from 'environment/configs/mockData';
import GetBoostCard from 'components/molecules/getBoostCard/GetBoostCard';
import {
  ConsumerDemographicsCard,
  ExpendituresCard,
  FeaturedWaterfallsCard,
  RecentActivityCard,
} from 'components/organisms';
import {DashboardTotalActivity, MapContainer} from 'components/molecules';
import {GetBoostFormValues} from 'pages/dashboard/Dashboard';
import GetYourBoost from 'components/molecules/getYourBoost/GetYourBoost';
import FoodTrackLocation from 'components/molecules/foodTrackLocation/FoodTrackLocation';
import {
  ITotalActivity,
  IEngagementsQuantityForDay,
  IConsumerDemographics,
  IRecentActivity,
  IComparedToWaterfalls,
} from 'store/models/dashboard';
import {ComingSoon} from 'components/molecules';
import {IBusinessUnit} from 'environment/types/business';
import {useSelector} from 'react-redux';
import {RootState} from 'store';
import {LoaderOverlay2} from 'components/atoms';
import EngagementCard from 'components/organisms/engagementCard/EngagementCard';

interface IProps {
  getBoostMethods: UseFormReturn<GetBoostFormValues>;
  totalActivity: ITotalActivity;
  engagementsQuantity: IEngagementsQuantityForDay[];
  consumerDemographics: IConsumerDemographics;
  recentActivity: IRecentActivity;
  openBoostModal: boolean;
  handleBoostModal: (value: boolean) => void;
  handleReferModal: (value: boolean) => void;
  handleGiveAdviceModal: (value: boolean) => void;
  onSubmitBoost: () => void;
  currentUnit: IBusinessUnit;
  comparedToWaterfalls: IComparedToWaterfalls;
  currentUnitCategory: string;
}

const DashboardTemplate = ({
  openBoostModal,
  getBoostMethods,
  onSubmitBoost,
  handleBoostModal,
  handleReferModal,
  totalActivity,
  engagementsQuantity,
  consumerDemographics,
  recentActivity,
  handleGiveAdviceModal,
  currentUnit,
  comparedToWaterfalls,
  currentUnitCategory,
}: IProps) => {
  const {t} = useTranslation();

  const isFoodTrack = currentUnitCategory === 'food_truck_stationary';
  const handleUpdateLiveLocation = () => {};

  const {
    getAllRecentActivity: isRATotalCountLoading,
    getDashboardRecentActivity: isRALoading,
    getDashboardEngagementsQuantity: isEngagementLoading,
    getDashboardConsumerDemographics: isConsumerDemographicsLoading,
    getDashboardComparedToWaterfalls: isWaterfallsLoading,
    getDashboardTotalActivity: isTotalActivityLoading,
  } = useSelector((state: RootState) => state.loading.effects.dashboard);

  const isRecentActivityLoading = isRATotalCountLoading || isRALoading;

  return (
    <>
      <Box className={styles.headerWrapper}>
        <Box className={styles.headerContainer}>
          <Typography variant="app-title">{t('Dashboard')}</Typography>

          {/* need it in future */}
          {/* <Box className={styles.currentBoostWrapper}>
            <Typography fontWeight={300} variant="text-sm-light">
              {t('Current.boost')}
            </Typography>
            <Typography
              variant="text-base-light"
              className={styles.currentBoostValue}>
              10 h
            </Typography>
          </Box> */}
        </Box>
      </Box>

      {isFoodTrack ? (
        <Box className={styles.cardsWrapper}>
          <Box className={styles.cards}>
            <Box className={styles.foodTrackLocationWrapper}>
              <FoodTrackLocation
                number={currentUnit.location?.number}
                country={currentUnit.location?.country}
                city={currentUnit.location?.city}
                street={currentUnit.location?.street}
                updateLocation={handleUpdateLiveLocation}
              />
            </Box>
            <LoaderOverlay2 loading={isTotalActivityLoading}>
              <DashboardTotalActivity data={totalActivity} />
            </LoaderOverlay2>
            <LoaderOverlay2 loading={isRecentActivityLoading}>
              <RecentActivityCard
                activityData={recentActivity.recentActivity}
                allActivity={recentActivity.totalCount}
              />
            </LoaderOverlay2>
            <LoaderOverlay2 loading={isEngagementLoading}>
              <EngagementCard engagementsQuantity={engagementsQuantity} />
            </LoaderOverlay2>
            <LoaderOverlay2 loading={isConsumerDemographicsLoading}>
              <ConsumerDemographicsCard
                consumerDemographicsData={consumerDemographics}
              />
            </LoaderOverlay2>
          </Box>

          <Box className={styles.cards}>
            <Box className={styles.getBoostWrapper}>
              <GetBoostCard
                onOpenGiveAdviceModal={() => handleGiveAdviceModal(true)}
                onOpenReferModal={() => handleReferModal(true)}
                // onOpenBoostModal={() => handleBoostModal(true)}
              />
            </Box>
            <LoaderOverlay2 loading={isWaterfallsLoading}>
              <FeaturedWaterfallsCard data={comparedToWaterfalls} />
            </LoaderOverlay2>
            <ComingSoon>
              <ExpendituresCard />
            </ComingSoon>
            <ComingSoon>
              <MapContainer
                currentRestourant={currentRestourant}
                nearbyRestourant={nearbyRestourant}
              />
            </ComingSoon>
          </Box>
        </Box>
      ) : (
        <Box className={styles.cardsWrapper}>
          <Box className={styles.topCard}>
            <LoaderOverlay2 loading={isTotalActivityLoading}>
              <DashboardTotalActivity data={totalActivity} />
            </LoaderOverlay2>
            <Box
              className={`${styles.getBoostWrapper} ${
                !isFoodTrack ? styles.getBoostWrapperStatic : ''
              }`}>
              <GetBoostCard
                onOpenGiveAdviceModal={() => handleGiveAdviceModal(true)}
                onOpenReferModal={() => handleReferModal(true)}
                // onOpenBoostModal={() => handleBoostModal(true)}
              />
            </Box>
          </Box>

          <Box className={styles.cards}>
            <LoaderOverlay2 loading={isRecentActivityLoading}>
              <RecentActivityCard
                activityData={recentActivity.recentActivity}
                allActivity={recentActivity.totalCount}
              />
            </LoaderOverlay2>
            <LoaderOverlay2 loading={isEngagementLoading}>
              <EngagementCard engagementsQuantity={engagementsQuantity} />
            </LoaderOverlay2>
            <LoaderOverlay2 loading={isConsumerDemographicsLoading}>
              <ConsumerDemographicsCard
                consumerDemographicsData={consumerDemographics}
              />
            </LoaderOverlay2>
          </Box>

          <Box className={styles.cards}>
            <LoaderOverlay2 loading={isWaterfallsLoading}>
              <FeaturedWaterfallsCard data={comparedToWaterfalls} />
            </LoaderOverlay2>
            <ComingSoon>
              <ExpendituresCard />
            </ComingSoon>
            <ComingSoon>
              <MapContainer
                currentRestourant={currentRestourant}
                nearbyRestourant={nearbyRestourant}
              />
            </ComingSoon>
          </Box>
        </Box>
      )}

      {/* <Modal open={openBoostModal}>
        <FormProvider {...getBoostMethods}>
          <GetYourBoost
            onClose={() => handleBoostModal(false)}
            onSubmit={onSubmitBoost}
          />
        </FormProvider>
      </Modal> */}
    </>
  );
};

export default DashboardTemplate;
