export const engagementsType = [
  {
    value: 'watch',
    text: 'Watched.your.Waterfall',
  },
  {
    value: 'like',
    text: 'liked',
  },
  {
    value: 'comment',
    text: 'Added.comments',
  },
  {
    value: 'review',
    text: 'Added.review',
  },
  {
    value: 'storyTag',
    text: 'Added.Waterfall.tag',
  },
  {
    value: 'forumTag',
    text: 'Added.Forum.tag',
  },
  {
    value: 'pageVisit',
    text: 'Visited.your.page',
  },
  {
    value: 'share',
    text: 'Shared',
  },
];
