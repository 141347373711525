import {Box} from '@mui/material';

import styles from './DemographicsCharts.module.scss';
import ChartCard from 'components/molecules/chartCard/ChartCard';
import {CounterData} from 'store/models/consumer-demographics';

interface IProps {
  chartCardData: CounterData[];
}

const DemographicsCharts = ({chartCardData}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      {chartCardData.map((item: CounterData, index: number) => {
        const {dislikes, ...newEndorsements} = item.endorsements;
        return (
          <ChartCard
            key={`${item.group.max}-${index}`}
            endorsements={newEndorsements}
            ageRange={item.group.max}
            statistics={item.statistics}
          />
        );
      })}
    </Box>
  );
};

export default DemographicsCharts;
