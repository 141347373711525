import {EXTRA_DROPDOWNS, MAIN_DROPDOWNS} from 'environment/constants/tags';
import {CategoriesOfTags} from 'environment/types/api/shared';

type Tag = {
  name: string;
  emoji: string | string[];
};

export function getCurrentAndAvailableTags(
  tags: Required<CategoriesOfTags>,
): [(Tag & {category: string})[], Tag[]] {
  const dropdowns = [...MAIN_DROPDOWNS, ...EXTRA_DROPDOWNS];

  const currentTags = [] as (Tag & {category: string})[];
  const availableTags = [] as Tag[];

  for (const {name, emoji, items} of dropdowns) {
    const pickedTag = tags[name];

    if (pickedTag) {
      const tag = items.find(({id}) => id === pickedTag);

      if (tag) {
        currentTags.push({
          name: tag.id,
          category: name,
          emoji: tag.emoji,
        });
      }
    } else {
      availableTags.push({
        name,
        emoji,
      });
    }
  }

  return [currentTags, availableTags];
}
