import {Box, Typography} from '@mui/material';
import {
  FormButton,
  GoBackLink,
  Input,
  ServerErrorMessage,
} from 'components/atoms';
import {ROUTES} from 'environment/constants/routes';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import styles from './NewPasswordForm.module.scss';

const NewPasswordForm = () => {
  const {t} = useTranslation();

  const {
    formState: {errors},
  } = useFormContext();

  return (
    <Box className={styles.container}>
      <GoBackLink navigateTo={ROUTES.login.home} />

      <Box className={styles.mainText}>
        <Typography variant="app-title">{t('Create.new.password')}</Typography>
        <Typography variant="text-sm-light" className={styles.description}>
          {t('The.new.password.should.not.repeat.the.previous.one')}
        </Typography>
      </Box>

      <Box className={styles.fields}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
          <Input
            name="newPassword"
            type="password"
            label={t('fields.password')}
            placeholder={t('fields.password')}
            autoComplete="new-password"
          />

          <Input
            name="confirmNewPassword"
            type="password"
            label={t('fields.confirmPassword')}
            placeholder={t('fields.confirmPassword')}
            autoComplete="new-password"
          />
        </div>

        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}

        <FormButton fullWidth>{t('Update')}</FormButton>
      </Box>
    </Box>
  );
};

export default NewPasswordForm;
