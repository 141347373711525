import {Box, Typography} from '@mui/material';
import {type FC, type PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './Settings.module.scss';

const SettingsTemplate: FC<PropsWithChildren> = ({children}) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Typography variant="app-title">{t('Settings')}</Typography>
      <Box className={styles.sections}>{children}</Box>
    </Box>
  );
};

export default SettingsTemplate;
