import moment from 'moment';
import {formatDate} from './formatDate';
import {t} from 'i18next';

export const getDisplayTextByData = (date: string) => {
  const formattedDate = formatDate(date, 'date');
  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

  if (formattedDate === today) {
    return t('Today');
  } else if (formattedDate === yesterday) {
    return t('Yesterday');
  } else {
    return formatDate(date, 'day');
  }
};
