import {Box, Typography} from '@mui/material';
import {roundNumberToDecimal} from 'environment/utils/roundNumberToDecimal';
import {useTranslation} from 'react-i18next';
import styles from './LikesDislikes.module.scss';

interface IProps {
  percent?: string;
  likes: number;
  dislikes?: number;
}

const LikesDislikes = ({percent, likes, dislikes}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Typography variant="text-xs" textAlign="left">
        {percent ? percent : 100}%
      </Typography>
      <Typography className={styles.likes} variant="text-xs" textAlign="left">
        {roundNumberToDecimal(likes) + ' ' + t('likes')}
      </Typography>
      {dislikes ? (
        <Typography
          className={styles.likes}
          variant="text-xs"
          textAlign="right">
          {roundNumberToDecimal(dislikes) + ' ' + t('dislikes')}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default LikesDislikes;
