import {CategoriesOfTags} from 'environment/types/api/shared';
import {FormQuestions} from 'hooks/forms/default-values';

export function isQuestionsEqual(a: FormQuestions, b: FormQuestions) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function isThreeTagsEqual(
  a: Required<CategoriesOfTags>,
  b: Required<CategoriesOfTags>,
) {
  return (
    a.cuisineLabel === b.cuisineLabel &&
    a.vibeAndServiceLabel === b.vibeAndServiceLabel &&
    a.ambianceLabel === b.ambianceLabel
  );
}

export function isFifteenTagsEqual(
  a: Required<CategoriesOfTags>,
  b: Required<CategoriesOfTags>,
) {
  let value = true;

  for (const k in a) {
    const key = k as keyof Required<CategoriesOfTags>;

    if (
      key === 'cuisineLabel' ||
      key === 'vibeAndServiceLabel' ||
      key === 'ambianceLabel'
    ) {
      continue;
    }

    if (a[key] === b[key]) {
      continue;
    } else {
      value = false;
      break;
    }
  }

  return value;
}
