import {FC, useState} from 'react';
import {Button, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

import 'react-calendar/dist/Calendar.css';
import styles from './CalendarButton.module.scss';
import Popover from '../../atoms/popover/Popover';
import Calendar from 'react-calendar';
import './styles.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {IconMark} from 'components/atoms';
import {CustomPeriod} from 'types/business-unit-analytics';

interface Props {
  period: CustomPeriod | null;
  setPeriod: (period: CustomPeriod) => void;
}

const CalendarButton: FC<Props> = ({period, setPeriod}) => {
  const {t} = useTranslation();

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleButtonClick = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(currentTarget);
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <>
      <Popover
        anchorEl={popoverAnchor}
        noStyles
        onClose={() => {
          setPopoverAnchor(null);
          setIsCalendarOpen(!isCalendarOpen);
        }}>
        <Box className="container">
          <Calendar
            onChange={(value) => {
              if (Array.isArray(value)) {
                setPeriod({
                  start: new Date(value[0]!),
                  end: new Date(value[1]!),
                });
              }
            }}
            value={period ? [period.start, period.end] : null}
            selectRange
            returnValue="range"
            locale="en-US"
            nextLabel={<NavigateNextIcon className={styles.arrowButton} />}
            prevLabel={<NavigateBeforeIcon className={styles.arrowButton} />}
            className={styles.calendar}
          />
        </Box>
      </Popover>

      <Box className={`${styles.buttonContainer}`}>
        <Button
          className={`${styles.button} ${period ? styles.active : ''}`}
          key={'choose_range'}
          size="medium"
          variant="outlined"
          onClick={handleButtonClick}>
          <Box className={styles.binIcon}>
            <IconMark
              name="calendar"
              color={period ? '#080808' : ''}
              size="24px"
            />
          </Box>
          {t('choose.range')}
          <Box
            className={`${styles.arrowContainer} ${
              isCalendarOpen ? styles.open : ''
            }`}>
            <IconMark
              name={'arrow-down'}
              color={period ? '#080808' : ''}
              size="24px"
            />
          </Box>
        </Button>
      </Box>
    </>
  );
};

export default CalendarButton;
