import {Box, Typography} from '@mui/material';
import {
  FormButton,
  GoBackLink,
  Input,
  ServerErrorMessage,
} from 'components/atoms';
import {ROUTES} from 'environment/constants/routes';
import {useTranslation} from 'react-i18next';
import styles from './PasswordRecoveryForm.module.scss';
import {useFormContext} from 'react-hook-form';

const PasswordRecoveryForm = () => {
  const {t} = useTranslation();

  const {
    formState: {errors},
  } = useFormContext();

  return (
    <>
      <Box className={styles.container}>
        <GoBackLink navigateTo={ROUTES.login.home} />

        <Box className={styles.mainText}>
          <Typography variant="app-title">{t('Password.recovery')}</Typography>
          <Typography variant="text-sm-light" className={styles.description}>
            {t('We.will.send.you.an.email.to.reset.your.password')}
          </Typography>
        </Box>

        <Box className={styles.fields}>
          <Input
            name="email"
            type="email"
            label={t('fields.email')}
            placeholder={t('fields.email')}
            autoComplete="email"
          />
          {errors.root?.serverError?.message ? (
            <ServerErrorMessage message={errors.root.serverError.message} />
          ) : null}
          <FormButton fullWidth>{t('Submit')}</FormButton>
        </Box>
      </Box>
    </>
  );
};

export default PasswordRecoveryForm;
