import {useState} from 'react';
import {Box} from '@mui/material';

import Switcher from 'components/atoms/switcher/Switcher';
import DemographicsActivity from 'components/organisms/demographicsActivity/DemographicsActivity';
import DemographicsCharts from 'components/organisms/demographicsCharts/DemographicsCharts';
import DemographicsTable from 'components/organisms/tables/demographicsTable/DemographicsTable';
import styles from './ConsumerDemographics.module.scss';
import {CounterData} from 'store/models/consumer-demographics';
import {emptyConsumerDemographicsData} from 'environment/configs/mockData';
import {Analytics} from 'types/business-unit-analytics';

interface IProps {
  analytics: Analytics;
  columnChartData: {[key: string]: number};
  chartCardData: CounterData[];
  getConsumerDemographicsCounters: (
    businessUnitId: string,
    startDate: string,
    endDate: string,
  ) => void;
}

const ConsumerDemographicsTemplate = ({
  analytics,
  columnChartData,
  chartCardData,
  getConsumerDemographicsCounters,
}: IProps) => {
  const [activeButton, setActiveButton] = useState<string>('first');

  const handleSwitch = (isActive: string) => {
    setActiveButton(isActive);
  };

  const isData = chartCardData[0].group.max !== 0;

  return (
    <Box paddingTop="32px">
      <DemographicsActivity
        analytics={analytics}
        getConsumerDemographicsCounters={getConsumerDemographicsCounters}
        columnChartData={columnChartData}
      />

      <Box className={styles.wrapper}>
        <Box className={styles.switcherBlock}>
          <Switcher
            firstTitle="Charts"
            secondTitle="Table"
            firstIcon="charts"
            secondIcon="table"
            handleSwitch={handleSwitch}
            activeButton={activeButton}
          />
        </Box>

        {activeButton === 'first' ? (
          <DemographicsCharts
            chartCardData={
              isData ? chartCardData : emptyConsumerDemographicsData
            }
          />
        ) : (
          <DemographicsTable
            tableData={isData ? chartCardData : emptyConsumerDemographicsData}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConsumerDemographicsTemplate;
