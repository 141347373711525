import React, {useState} from 'react';
import {IWaterfall} from 'types/waterfall';
import place4 from 'assets/temporary/reviewsFood.png';
import ActiveSlide from 'components/molecules/slide/ActiveSlide/ActiveSlide';
import Slide from 'components/molecules/slide/Slide/Slide';
import './CustomCarousel.scss';

interface IProps {
  loop?: boolean;
  data: IWaterfall[];
  clickedItemIndex: number;
  sendQuickCommentToWaterfall: (
    waterfallId: string,
    commentText: string,
  ) => void;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const Carousel: React.FC<IProps> = ({
  loop = false,
  data,
  clickedItemIndex,
  sendQuickCommentToWaterfall,
  updateWaterfallReply,
}) => {
  const [centerTile, setCenterTile] = useState<number>(clickedItemIndex);

  const scrollLeft = () => {
    let nextTile = Math.max(0, centerTile - 1);

    if (loop && nextTile === centerTile) {
      nextTile = data.length - 1;
    }

    setCenterTile(nextTile);
  };

  const scrollRight = () => {
    let nextTile = Math.min(data.length - 1, centerTile + 1);

    if (loop && nextTile === centerTile) {
      nextTile = 0;
    }

    setCenterTile(nextTile);
  };

  const isAlone = data.length === 1;

  const tiles = data.map((item: any, i: number) => {
    let position = parseInt(String(i - centerTile));
    let slideComponent;

    if (loop) {
      if (position > data.length / 2) {
        position = position - data.length;
      } else if (position < 0 - data.length / 2) {
        position = position + data.length;
      }
    }

    if (Math.abs(position) > 2) {
      return null;
    }

    const {likes, dislikes, watches, shares, comments} = item.engagement;
    const {firstName, lastName, rating} = item.user;

    if (position === 0) {
      slideComponent = (
        <ActiveSlide
          isAlone={isAlone}
          mediaSrc={item?.media?.url}
          mediaType={item?.media?.type}
          waterfallId={item.id}
          userAvatar={item.user.avatar}
          likes={likes.count}
          dislikes={dislikes.count}
          comments={comments.count}
          views={watches.count}
          shares={shares.count}
          firstName={firstName}
          lastName={lastName}
          userRating={rating}
          // todo replace with actual image when it is
          placeImage={place4}
          placeName={item.businessUnit.business.title}
          country={item.businessUnit.location.country}
          caption={item.caption}
          handleNext={scrollLeft}
          handlePrev={scrollRight}
          reply={item.reply}
          updateWaterfallReply={updateWaterfallReply}
          sendReply={sendQuickCommentToWaterfall}
        />
      );
    } else {
      slideComponent = (
        <div onClick={() => setCenterTile(i)}>
          <Slide
            mediaSrc={item?.media?.url}
            mediaThumbnail={item?.media?.thumbnailUrl}
            key={item.id}
          />
        </div>
      );
    }

    return (
      <div className="carousel-tile" data-position={position} key={i}>
        {slideComponent}
      </div>
    );
  });

  return (
    <div className="wrapper">
      <div className="carousel">{tiles}</div>
    </div>
  );
};

export default Carousel;
