import {Box} from '@mui/material';
import {TitleAndProgress} from 'components/atoms';
import {ANALYTICS_COLORS} from 'environment/constants/analytics';
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Analytics, AnalyticsCategory} from 'types/business-unit-analytics';
import styles from './AnalyticsTiles.module.scss';

interface Props {
  analytics: Analytics;
  tileWidth?: string;
}

const AnalyticsTiles: FC<Props> = ({analytics, tileWidth = '100px'}) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.cardsContainer}>
      {Object.entries(analytics).map(([key, item]) => {
        const category = key as AnalyticsCategory;
        return (
          <Box className={styles.cardWrapper} key={key} width={tileWidth}>
            <TitleAndProgress
              data={item.count}
              title={t(`analytics.${category}`)}
              color={ANALYTICS_COLORS[category]}
              percent={Math.round(item.change)}
              noWrap
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(AnalyticsTiles);
