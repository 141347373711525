import {useState} from 'react';
import {Box, Typography, Button} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './PaymentHistoryList.module.scss';
import PaymentListRow from '../../molecules/paymentListRow/PaymentListRow';
import {RANGE_BUTTONS} from './constant';
import emptyWallet from 'assets/icons/empty-wallet.svg';
import {CalendarButton} from 'components/molecules';

interface IProps {
  historyBalanceData: any; // TODO add type
}

const PaymentHistoryList = ({historyBalanceData}: IProps) => {
  const {t} = useTranslation();

  const [active, setActive] = useState<string | null>(null);

  const handleClick = (value: string) => {
    setActive(value);
  };

  const todayItems = historyBalanceData.today;
  const yesterdayItems = historyBalanceData.yesterday;

  // todo add checking of historyData
  const isHistory = false;

  return (
    <Box>
      <Box className={styles.header}>
        <Typography variant="text-lg" className={styles.title}>
          {t('Payment.history')}
        </Typography>
        <Box className={styles.buttonsContainer}>
          {RANGE_BUTTONS.map(({value, label}) => (
            <Button
              className={`${styles.button} ${
                active === value ? styles.active : ''
              }`}
              key={value}
              size="medium"
              variant="outlined"
              onClick={() => handleClick(value)}>
              {label}
            </Button>
          ))}
          <CalendarButton period={null} setPeriod={() => {}} />
        </Box>
      </Box>

      {isHistory ? (
        <Box className={styles.listBlock}>
          <Box>
            <Box className={styles.subtitle}>
              <Typography variant="text-sm">{t('Today')}</Typography>
            </Box>
            <Box className={styles.rowsBlock}>
              {todayItems.map((item: any, index: number) => (
                <PaymentListRow
                  key={`${item.title}-${index}`}
                  title={item.title}
                  cardNumber={item.cardNumber}
                  icon={item.icon}
                  count={item.count}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Box className={styles.subtitle}>
              <Typography variant="text-sm">{t('Yesterday')}</Typography>
            </Box>
            <Box className={styles.rowsBlock}>
              {yesterdayItems.map((item: any, index: number) => (
                <PaymentListRow
                  key={`${item.title}-${index}`}
                  title={item.title}
                  icon={item.icon}
                  count={item.count}
                />
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={styles.emptyBlock}>
          <Box sx={{width: 280, textAlign: 'center'}}>
            <Box
              component="img"
              src={emptyWallet}
              alt="empty wallet"
              width={80}
              height={80}
              sx={{marginBottom: '10px'}}
            />
            <Typography>
              {t('Here.you.will.see.all.your.payments.activity')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PaymentHistoryList;
