import {useTranslation} from 'react-i18next';
import {TableBody, TableCell, TableRow, Box, Typography} from '@mui/material';
import {TableHeader, TableRoot} from 'components/atoms/tablePrimitives';
import {TablePagination} from 'components/molecules';
import {Order} from 'environment/types';
import {useMemo, useState} from 'react';
import styles from './FeaturedWaterfallsTable.module.scss';
import {columns} from './columns';
import {IWaterfall} from 'types/waterfall';
import {useSelector} from 'react-redux';
import {RootState} from 'store';
import {LoaderOverlay} from 'components/atoms';

interface IProps {
  tableData: IWaterfall[];
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowPerPage: number) => void;
  toggleWaterfall: (
    businessUnitId: string,
    waterfallId: string,
    featured: boolean,
  ) => void;
  sendQuickCommentToWaterfall: (
    waterfallId: string,
    commentText: string,
  ) => void;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const FeaturedWaterfallsTable = ({
  tableData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  toggleWaterfall,
  sendQuickCommentToWaterfall,
  updateWaterfallReply,
}: IProps) => {
  const {t} = useTranslation();

  const isLoading = useSelector(
    (state: RootState) =>
      state.loading.effects.waterfalls.getWaterfallPaginated,
  );

  type Property = keyof (typeof tableData)[number];

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<Property>('engagement');

  const handleRequestSort = (property: Property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    const sortedRows = [...tableData].sort((a, b) => {
      const aValue = (a as any)[orderBy] ?? '';
      const bValue = (b as any)[orderBy] ?? '';

      if (aValue < bValue) return -1;
      else if (aValue > bValue) return 1;
      else return 0;
    });

    if (order === 'desc') return sortedRows.reverse();

    return sortedRows;
  }, [tableData, order, orderBy]);

  const handleChageRowsPerPage = (value: string) => {
    setRowsPerPage(parseInt(value));
    setPage(1);
  };

  return (
    <Box className={styles.wrapper}>
      {isLoading && <LoaderOverlay />}
      <TableRoot
        pagination={
          tableData.length > 0 && (
            <TablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              count={tableData.length}
              onChangePage={setRowsPerPage}
              onChangeRowsPerPage={handleChageRowsPerPage}
            />
          )
        }>
        <TableHeader
          columns={columns(
            toggleWaterfall,
            sendQuickCommentToWaterfall,
            updateWaterfallReply,
          )}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {visibleRows.map((row, index) => (
            <TableRow key={`${index} ${row.id}`}>
              {columns(
                toggleWaterfall,
                sendQuickCommentToWaterfall,
                updateWaterfallReply,
              ).map((column) => (
                <TableCell
                  key={column.id}
                  className={`${styles.cell} ${
                    column.id === 'quickReply' ? styles.cellComment : ''
                  }`}
                  align={column.alignCenter ? 'center' : 'left'}
                  padding="none">
                  {column.renderCell(row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableRoot>

      {tableData.length === 0 && (
        <Box className={styles.emptyBlock}>
          <Box sx={{width: 380, textAlign: 'center'}}>
            <Typography variant="text-base-semibold">
              {t('empty.waterfall.table')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FeaturedWaterfallsTable;
