import {DevTool} from '@hookform/devtools';
import {useSignUpForm} from 'hooks/forms';
import {FormProvider} from 'react-hook-form';
import {Outlet} from 'react-router-dom';

const SignUpMainProvider = () => {
  const form = useSignUpForm();

  return (
    <FormProvider {...form}>
      <Outlet />
      <DevTool control={form.control} />
    </FormProvider>
  );
};

export default SignUpMainProvider;
