import {useTranslation} from 'react-i18next';

import ErrorOrSuccess from 'components/templates/errorOrSucceess/ErrorOrSuccess';
import {successImages} from './constatnts';
import {getRandomNumber} from 'environment/utils/getRandomNumber';

const Success = () => {
  const {t} = useTranslation();

  return (
    <ErrorOrSuccess
      title={t('Successfully.action')}
      secondButtonLabel={t('Continue')}
      onClickSecondButton={() => {}} // TODO add function
      image={successImages[getRandomNumber(successImages.length)]}
    />
  );
};

export default Success;
