import {Typography} from '@mui/material';
import {type FC} from 'react';
import Colors from 'styles/colors.scss';

interface IProps {
  message: string;
}

const ServerErrorMessage: FC<IProps> = ({message}) => {
  return (
    <Typography
      color={Colors['profile-red']}
      variant="text-sm"
      sx={{'&::first-letter': {textTransform: 'uppercase'}}}>
      {message}
    </Typography>
  );
};

export default ServerErrorMessage;
