import answerImg from 'assets/temporary/answer140.png';
import story1 from 'assets/temporary/storyPreviews/1.jpg';
import story10 from 'assets/temporary/storyPreviews/10.jpg';
import story2 from 'assets/temporary/storyPreviews/2.jpg';
import story3 from 'assets/temporary/storyPreviews/3.jpg';
import story4 from 'assets/temporary/storyPreviews/4.jpg';
import story5 from 'assets/temporary/storyPreviews/5.jpg';
import story6 from 'assets/temporary/storyPreviews/6.jpg';
import story7 from 'assets/temporary/storyPreviews/7.jpg';
import story8 from 'assets/temporary/storyPreviews/8.jpg';
import story9 from 'assets/temporary/storyPreviews/9.jpg';

export const waterfalls = [
  story5,
  story6,
  story1,
  story7,
  story8,
  story9,
  story10,
];

export const featuredWaterfallsTableData = [
  {
    story: {
      imageSrc: story1,
      progress: 89,
      badgeText: '2h',
    },
    addStory: true,
    user: {
      firstName: 'Guy',
      lastName: 'Hawkins',
      profession: 'UX/UI | notion | coffee',
      image: answerImg,
      nickname: '@sadasda',
    },
    date: '22 Jun 22:25:15',
    views: 12300000,
    share: 2138,
    comments: 130000,
    likes: 256000,
    quickReply: '',
  },
  {
    story: {
      imageSrc: story2,
      progress: 21,
      badgeText: '16h',
    },
    addStory: false,
    user: {
      firstName: 'Brooklyn',
      lastName: 'Simmons',
      profession: 'UX/UI | notion | coffee',
      image: answerImg,
      nickname: '@sadasda',
    },
    date: '22 Jun 22:25:15',
    views: 12300000,
    share: 2138,
    comments: 130000,
    likes: 256000,
    quickReply: '',
  },
  {
    story: {
      imageSrc: story3,
      progress: 49,
      badgeText: '12h',
    },
    addStory: false,
    user: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      profession: 'Chef | Pizza | sun solutions',
      image: answerImg,
      nickname: '@sadasda',
    },
    date: '23 Jun 22:25:15',
    views: 12300000,
    share: 2138,
    comments: 130000,
    likes: 256000,
    quickReply: '',
  },
  {
    story: {
      imageSrc: story4,
      progress: 65,
      badgeText: '8h',
    },
    addStory: false,
    user: {
      firstName: 'Darlene',
      lastName: 'Robertson',
      profession: 'CEO | She/Her | dreamer',
      image: answerImg,
      nickname: '@sadasda',
    },
    date: '24 Jun 22:25:15',
    views: 12300000,
    share: 2138,
    comments: 130000,
    likes: 256000,
    quickReply: '',
  },
  {
    story: {
      imageSrc: story5,
      progress: 10,
      badgeText: '20h',
    },
    addStory: false,
    user: {
      firstName: 'Guy',
      lastName: 'Hawkins',
      profession: 'UX/UI | notion | coffee',
      image: answerImg,
      nickname: '@sadasda',
    },
    date: '25 Jun 22:25:15',
    views: 12300000,
    share: 2138,
    comments: 130000,
    likes: 256000,
    quickReply: '',
  },
];

export type FeaturedWaterfallsTableData = typeof featuredWaterfallsTableData;

export const recentActivityCardData = [
  {
    user: {
      firstName: 'Jane',
      lastName: 'Cooper',
      image: answerImg,
      nickname: 'fsdfsd',
      profession: 'UI/UX ',
      rating: 1,
    },
    action: 'added review',
    time: '1 minute ago',
  },
  {
    user: {
      firstName: 'Guy',
      lastName: 'Hawkins',
      image: answerImg,
      nickname: 'fsdfsd',
      profession: 'UI/UX ',
      rating: 1,
    },
    action: 'added comments',
    time: '1 h ago',
  },
  {
    user: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      image: answerImg,
      nickname: 'fsdfsd',
      profession: 'UI/UX ',
      rating: 1,
    },
    action: 'liked',
    time: '1 h ago',
  },
  {
    user: {
      firstName: 'Jane',
      lastName: 'Cooper',
      image: answerImg,
      nickname: 'fsdfsd',
      profession: 'UI/UX ',
      rating: 1,
    },
    action: 'liked',
    time: '1 h ago',
  },
];

export type RecentActivityCardData = typeof recentActivityCardData;

export const donutChartRaceData = {
  total: {
    '24h': '10334',
    '72h': '33.3',
    week: '20555',
    month: '30555',
  },
  Review: {
    '24h': '7000',
    '72h': '10.3',
    week: '55.3',
    month: '66.2',
  },
  Likes: {
    '24h': '8395',
    '72h': '5.3',
    week: '55.3',
    month: '66.2',
  },
  Comments: {
    '24h': '1521',
    '72h': '13.3',
    week: '55.3',
    month: '66.2',
  },
  Share: {
    '24h': '8584',
    '72h': '8.33',
    week: '55.3',
    month: '66.2',
  },
  View: {
    '24h': '5805',
    '72h': '10.3',
    week: '55.3',
    month: '66.2',
  },
  'Story Tag': {
    '24h': '5835',
    '72h': '10.3',
    week: '55.3',
    month: '66.2',
  },
  'Forum Tag': {
    '24h': '4305',
    '72h': '10.3',
    week: '55.3',
    month: '66.2',
  },
  'Page visit': {
    '24h': '2605',
    '72h': '10.3',
    week: '55.3',
    month: '66.2',
  },
};

export const pieChartData = [
  {
    name: 'Returning.visitors',
    y: 70,
  },
  {
    name: 'New.visitors',
    y: 14,
  },
  {
    name: 'Old.returning.visitor',
    y: 4,
  },
];

export const basicAreaData = [
  {
    name: 'Total Page likes',
    data: [
      30008, 17008, 20000, 6638, 22229, 20540, 16133, 28463, 5139, 12175, 15255,
      9008, 23708, 4511, 18979, 25904, 19011, 10903, 25732, 22685, 7577, 14526,
      9457, 10027, 8570, 19008, 19008, 2000, 18638, 22229, 25540, 6133, 9463,
      17139, 13175, 15000, 30008, 4708, 6511, 19008, 22000,
    ],
  },
];
export const basicAreaData2 = [
  {
    name: 'Likes',
    // color: 'rgba(184, 254, 97, 0.4)',
    // color: {
    //   linearGradient: {
    //     x1: 0,
    //     y1: 0,
    //     x2: 1,
    //     y2: 0,
    //   },
    //   stops: [
    //     [0, "rgba(255, 249, 97, 0.61)"],
    //     [1, "rgba(52, 211, 153, 0)"],
    //   ],
    // },
    data: [12298, 12298, 12298],
  },
  {
    name: 'Unlikes',
    // color: '#FF482C',
    // color: {
    //   linearGradient: {
    //     x1: 0,
    //     y1: 0,
    //     x2: 1,
    //     y2: 0,
    //   },
    //   stops: [
    //     [0, "rgba(255, 72, 44, 0.55)"],
    //     [1, "rgba(255, 72, 44, 0)"],
    //   ],
    // },
    data: [
      5242, 6144, 7091, 8400, 9490, 10671, 11736, 13279, 14600, 15878, 17286,
      19235, 22165, 24281, 26169, 28258, 30665, 32146, 33486, 35130, 36825,
      38582, 40159, 38107, 36538, 35078, 32980, 29154, 26734, 24403, 21339,
      18179, 15942, 15442, 14368, 13188, 12188, 11152, 10114, 9076, 8038, 7000,
      6643, 6286, 5929, 5527, 5215, 4858, 4750, 4650, 4600, 4500, 4490, 4300,
      4350, 4330, 4310, 4495, 4477,
    ],
  },
];

export const barChartData = [631, 814, 1044, 1534, 300, 32, 1000, 2000];
export const columnChartData = [
  {
    name: 'Review',
    data: [1800, 2100, 4300], ///  [1 - 18-24,   2 -  25 -34,   3 35- 44]
  },
  {
    name: 'Likes',
    data: [2600, 2900, 1200],
  },
  {
    name: 'Comments',
    data: [3300, 5500, 2800],
  },
  {
    name: 'Share',
    data: [1900, 1500, 1200],
  },
  {name: 'View', data: [2600, 3500, 4500]},
  {name: 'Story Tag', data: [1000, 4500, 1000]},
  {name: 'Forum Tag', data: [3000, 2500, 3400]},
  {name: 'Page Visit', data: [4000, 1500, 3000]},
];

export const basicChartWithBenchmark = [
  ['1am', 5],
  ['2am', 8],
  ['3am', 1],
  ['4am', 5],
  ['5am', 4],
  ['6am', 3],
  ['7am', 5],
  ['8am', 8],
  ['9am', 1],
  ['10am', 5],
  ['11am', 4],
  ['12am', 3],
  ['1pm', 5],
  ['2pm', 8],
  ['3pm', 1],
  ['4pm', 5],
  ['5pm', 4],
  ['6pm', 3],
  ['7pm', 5],
  ['8pm', 8],
  ['9pm', 1],
  ['10pm', 5],
  ['11pm', 4],
  ['12pm', 3],
];

export const basicChartWithBenchmarkLikes = [
  {
    name: 'Likes',
    data: [
      ['0am', 1008],
      ['1am', 10008],
      ['4am', 13008],
      ['5am', 15008],
      ['8am', 17008],
      ['9am', 19008],
      ['10am', 20000],
      ['11am', 22229],
      ['1pm', 25540],
      ['3pm', 25540],
      ['5pm', 28133],
      ['7pm', 28133],
      ['9pm', 29463],
      ['11pm', 31139],
    ],
    total: 26400,
  },
  {
    name: 'Unlikes',
    data: [
      ['0am', 4008],
      ['1am', 5000],
      ['4am', 5308],
      ['5am', 5508],
      ['8am', 6408],
      ['9am', 8908],
      ['10am', 8000],
      ['11am', 9229],
      ['1pm', 9540],
      ['3pm', 9540],
      ['5pm', 9133],
      ['7pm', 9133],
      ['9pm', 10463],
      ['11pm', 11139],
    ],
    total: 5800,
  },
];

export const nearbyRestourant = [
  {
    name: 'Chez Cuisine',

    latitude: 40.361145,
    longitude: -100.0156,

    data: {
      amount: 1639,
      percent: 10,
    },

    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Flavors Galore',

    latitude: 44.986656,
    longitude: -93.258133,

    data: {
      amount: 1639,
      percent: 10,
    },

    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Savory Spots',

    latitude: 45.424721,
    longitude: -75.695,

    data: {
      amount: 1639,
      percent: -10,
    },

    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Meat place',

    latitude: 45.424721,
    longitude: -95.695,

    data: {
      amount: 1639,
      percent: 10,
    },

    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Soup space',

    latitude: 44.424721,
    longitude: -79.695,

    data: {
      amount: 1639,
      percent: 10,
    },

    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Lalalend',

    latitude: 44.524721,
    longitude: -81.695,

    data: {
      amount: 1639,
      percent: 10,
    },

    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
];

export const currentRestourant = {
  latitude: 41.739685,
  longitude: -87.55442,
};

export const boardAreaData = [
  {
    name: 'Anonymus',
    count: '29,340.20',
    percentage: 3.4,
    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Anonymus',
    count: '29,340.20',
    percentage: 3.4,
    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Anonymus',
    count: '29,340.20',
    percentage: 3.4,
    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Anonymus',
    count: '29,340.20',
    percentage: 3.4,
    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Anonymus',
    count: '29,340.20',
    percentage: 3.4,
    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
  {
    name: 'Anonymus',
    count: '29,340.20',
    percentage: 3.4,
    graphData: [
      22229, 25540, 28133, 29463, 31139, 31175, 31255, 19008, 13708, 11511,
      10979, 10904, 11011, 10903, 10732, 10685,
    ],
  },
];

export const speedometerData = [
  {
    header: 'Your current boost',
    amount: 0,
    value: 0,
    title: 'Current.boost.length',
  },
  {
    header: 'Your previous boost',
    amount: 0,
    value: 0,
    title: 'Boost.length',
  },
  {
    header: 'History of boosts',
    amount: 0,
    value: 0,
    title: 'Total.boost.length',
  },
];

export const chartCardData = [
  {
    title: 'Under 21',
    allCount: '1574',
    allUsers: {count: 12345, percent: 10},
    data: {
      Review: {
        week: '10334',
        month: '33',
      },
      Comments: {
        week: '10334',
        month: '33',
      },
      Views: {
        week: '10334',
        month: '33',
      },
      Likes: {
        week: '10334',
        month: '33',
      },
      'Story.Tag': {
        week: '10334',
        month: '33',
      },
      'Forum.Tag': {
        week: '10334',
        month: '33',
      },
      Share: {
        week: '10334',
        month: '33',
      },
      'Page.visit': {
        week: '10334',
        month: '33',
      },
    },
  },
  {
    title: '21-24',
    allCount: '1574',
    allUsers: {count: 12345, percent: 10},
    data: {
      Review: {
        week: '10334',
        month: '33',
      },
      Comments: {
        week: '10334',
        month: '33',
      },
      Views: {
        week: '10334',
        month: '33',
      },
      Likes: {
        week: '10334',
        month: '33',
      },
      'Story.Tag': {
        week: '10334',
        month: '33',
      },
      'Forum.Tag': {
        week: '10334',
        month: '33',
      },
      Share: {
        week: '10334',
        month: '33',
      },
      'Page.visit': {
        week: '10334',
        month: '33',
      },
    },
  },
  {
    title: '25-31 age',
    allCount: '1574',
    allUsers: {count: 12345, percent: 10},
    data: {
      Review: {
        week: '10334',
        month: '33',
      },
      Comments: {
        week: '10334',
        month: '33',
      },
      Views: {
        week: '10334',
        month: '33',
      },
      Likes: {
        week: '10334',
        month: '33',
      },
      'Story.Tag': {
        week: '10334',
        month: '33',
      },
      'Forum.Tag': {
        week: '10334',
        month: '33',
      },
      Share: {
        week: '10334',
        month: '33',
      },
      'Page.visit': {
        week: '10334',
        month: '33',
      },
    },
  },
  {
    title: '32-37 age',
    allCount: '1574',
    allUsers: {count: 12345, percent: 10},
    data: {
      Review: {
        week: '10334',
        month: '33',
      },
      Comments: {
        week: '10334',
        month: '33',
      },
      Views: {
        week: '10334',
        month: '33',
      },
      Likes: {
        week: '10334',
        month: '33',
      },
      'Story.Tag': {
        week: '10334',
        month: '33',
      },
      'Forum.Tag': {
        week: '10334',
        month: '33',
      },
      Share: {
        week: '10334',
        month: '33',
      },
      'Page.visit': {
        week: '10334',
        month: '33',
      },
    },
  },
  {
    title: '38-44',
    allCount: '1574',
    allUsers: {count: 12345, percent: 10},
    data: {
      Review: {
        week: '10334',
        month: '33',
      },
      Comments: {
        week: '10334',
        month: '33',
      },
      Views: {
        week: '10334',
        month: '33',
      },
      Likes: {
        week: '10334',
        month: '33',
      },
      'Story.Tag': {
        week: '10334',
        month: '33',
      },
      'Forum.Tag': {
        week: '10334',
        month: '33',
      },
      Share: {
        week: '10334',
        month: '33',
      },
      'Page.visit': {
        week: '10334',
        month: '33',
      },
    },
  },
  {
    title: '45+',
    allCount: '1574',
    allUsers: {count: 12345, percent: 10},
    data: {
      Review: {
        week: '10334',
        month: '33',
      },
      Comments: {
        week: '10334',
        month: '33',
      },
      Views: {
        week: '10334',
        month: '33',
      },
      Likes: {
        week: '10334',
        month: '33',
      },
      'Story.Tag': {
        week: '10334',
        month: '33',
      },
      'Forum.Tag': {
        week: '10334',
        month: '33',
      },
      Share: {
        week: '10334',
        month: '33',
      },
      'Page.visit': {
        week: '10334',
        month: '33',
      },
    },
  },
];

export const consumerDemographicsTable = [
  {
    age: '18',
    allUsers: {amount: 12876, percent: 12},
    newUsers: 11,
    reviews: 346,
    views: 34356,
    share: 200,
    comments: 130,
    likesDislikes: {percent: '100%', likes: 1350},
    storyTag: '0',
    forumTag: '0',
    pageVisit: 0,
  },
  {
    age: '19-21',
    allUsers: {amount: 12876, percent: 12},
    newUsers: 12,
    reviews: 346,
    views: 34356,
    share: 200,
    comments: 130,
    likesDislikes: {percent: '100%', likes: 1350},
    storyTag: '0',
    forumTag: '0',
    pageVisit: 0,
  },
  {
    age: '22-25',
    allUsers: {amount: 12876, percent: 12},
    newUsers: 9,
    reviews: 346,
    views: 34356,
    share: 200,
    comments: 110,
    likesDislikes: {percent: '100%', likes: 1350},
    storyTag: '0',
    forumTag: '0',
    pageVisit: 0,
  },
  {
    age: '26-30',
    allUsers: {amount: 12876, percent: 12},
    newUsers: 2,
    reviews: 316,
    views: 34356,
    share: 200,
    comments: 130,
    likesDislikes: {percent: '100%', likes: 1350},
    storyTag: '0',
    forumTag: '0',
    pageVisit: 1,
  },
  {
    age: '31-45',
    allUsers: {amount: 12876, percent: 12},
    newUsers: 123,
    reviews: 346,
    views: 34356,
    share: 200,
    comments: 120,
    likesDislikes: {percent: '100%', likes: 1350},
    storyTag: '0',
    forumTag: '0',
    pageVisit: 0,
  },
];

export type ConsumerDemographicsTableData = typeof consumerDemographicsTable;

export const paymentsBalanceData = {
  mainBalance: '0',
  history: {
    today: [
      {
        title: 'Money.transfer',
        cardNumber: '4465',
        icon: 'creditCard.svg',
        count: '- $1000.00',
      },
      {
        title: 'Like.activity',
        icon: 'likeActivity.svg',
        count: '- $14.00',
      },
    ],
    yesterday: [
      {
        title: 'Share.activity',
        icon: 'share.svg',
        count: '- $14.00',
      },
      {
        title: 'Share.activity',
        icon: 'share.svg',
        count: '- $14.00',
      },
    ],
  },
};

export const emptyConsumerDemographicsData = [
  {
    endorsements: {
      comments: 0,
      dislikes: 0,
      forumTag: 0,
      likes: 0,
      pageVisit: 0,
      reviews: 0,
      shares: 0,
      storyTag: 0,
      watches: 0,
    },
    group: {
      min: 0,
      max: 21,
    },
    statistics: {
      allUsers: 0,
      newUsers: 0,
    },
  },
  {
    endorsements: {
      comments: 0,
      dislikes: 0,
      forumTag: 0,
      likes: 0,
      pageVisit: 0,
      reviews: 0,
      shares: 0,
      storyTag: 0,
      watches: 0,
    },
    group: {
      min: 21,
      max: 25,
    },
    statistics: {
      allUsers: 0,
      newUsers: 0,
    },
  },
  {
    endorsements: {
      comments: 0,
      dislikes: 0,
      forumTag: 0,
      likes: 0,
      pageVisit: 0,
      reviews: 0,
      shares: 0,
      storyTag: 0,
      watches: 0,
    },
    group: {
      min: 26,
      max: 31,
    },
    statistics: {
      allUsers: 0,
      newUsers: 0,
    },
  },
  {
    endorsements: {
      comments: 0,
      dislikes: 0,
      forumTag: 0,
      likes: 0,
      pageVisit: 0,
      reviews: 0,
      shares: 0,
      storyTag: 0,
      watches: 0,
    },
    group: {
      min: 32,
      max: 37,
    },
    statistics: {
      allUsers: 0,
      newUsers: 0,
    },
  },
  {
    endorsements: {
      comments: 0,
      dislikes: 0,
      forumTag: 0,
      likes: 0,
      pageVisit: 0,
      reviews: 0,
      shares: 0,
      storyTag: 0,
      watches: 0,
    },
    group: {
      min: 38,
      max: 44,
    },
    statistics: {
      allUsers: 0,
      newUsers: 0,
    },
  },
  {
    endorsements: {
      comments: 0,
      dislikes: 0,
      forumTag: 0,
      likes: 0,
      pageVisit: 0,
      reviews: 0,
      shares: 0,
      storyTag: 0,
      watches: 0,
    },
    group: {
      min: 45,
      max: 100,
    },
    statistics: {
      allUsers: 0,
      newUsers: 0,
    },
  },
];
