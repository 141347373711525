import {Box, Typography} from '@mui/material';
import {FormButton, GoBackLink} from 'components/atoms';
import {ROUTES} from 'environment/constants/routes';
import {useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import styles from './CheckYourEmail.module.scss';

interface IProps {
  type: 'password-recovery' | 'signup';
  onClick?: () => void;
}

const CheckYourEmail = ({type, onClick}: IProps) => {
  const {t} = useTranslation();

  const {control} = useFormContext();
  const email = useWatch({control, name: 'email'});

  const passwordRecovery = type === 'password-recovery';

  return (
    <>
      <Box className={styles.container}>
        <GoBackLink
          onClick={onClick}
          // todo
          navigateTo={
            onClick
              ? undefined
              : passwordRecovery
              ? ROUTES.login.home
              : ROUTES.signup.home
          }
        />

        <Box className={styles.fields}>
          <Typography variant="app-title">{t('Check.your.email')}</Typography>
          <Typography variant="text-sm-light" className={styles.description}>
            {passwordRecovery
              ? t('We.have.sent.you.a.password.recovery.instruction.to')
              : t(
                  'We.have.sent.you.a.link.to.continue.signing.up.to.your.email.address',
                )}{' '}
            <Typography variant="text-sm-light" className={styles.email}>
              {email}
            </Typography>
          </Typography>
        </Box>
      </Box>

      <a
        href={`https://${email?.split('@').pop()}`}
        target="_blank"
        rel="noreferrer">
        <FormButton type="button" fullWidth>
          {t('Check.email')}
        </FormButton>
      </a>
    </>
  );
};

export default CheckYourEmail;
