import Colors from 'styles/colors.scss';
import {IUserStatus} from 'store/models/engagement';

type ValidEndorsementKey = keyof IUserStatus;

type Item = {
  title: string;
  value: ValidEndorsementKey;
  color: string;
};

export const items: Item[] = [
  {
    title: 'Returning.visitors',
    value: 'returningVisitor',
    color: Colors['default-green-600'],
  },
  {
    title: 'New.visitors',
    value: 'newUser',
    color: Colors['default-green-400'],
  },
  {
    title: 'Old.returning.visitor',
    value: 'oldReturningVisitor',
    color: Colors['default-green-900'],
  },
];
