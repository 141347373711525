import {Box, Button, Typography} from '@mui/material';
import {Modal} from 'components/atoms';
import {AddReply} from 'components/molecules';
import {useState} from 'react';
import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AddReplyFormData, useAddReplyForm} from '../hooks/useAddReplyForm';
import styles from './CommentBlock.module.scss';
import {IReviewMediaDataProcessed, IReviewUserProcessed} from 'types';
import useReviewReply from 'hooks/useReviewReply';
import {IReviewReply} from 'types';
import {ReactComponent as CheckIcon} from 'assets/icons/checkedReview.svg';

interface IProps {
  text: string;
  user: IReviewUserProcessed;
  rollScore: number;
  textLiked: string;
  textDisLiked: string;
  mediaData: IReviewMediaDataProcessed[] | null;
  reviewId: string;
  reply?: IReviewReply;
}

const CommentBlock = ({
  text,
  mediaData,
  user,
  rollScore,
  textLiked,
  textDisLiked,
  reviewId,
  reply,
}: IProps) => {
  const {t} = useTranslation();

  const {replyOnReview, allReviewReplies} = useReviewReply();

  const [openReplyModal, setOpenReplyModal] = useState<boolean>(false);
  const [sentReply, setSentReply] = useState<boolean>(
    allReviewReplies.length > 0,
  );

  const addReplyForm = useAddReplyForm();

  const handleReplyClick = () => {
    setOpenReplyModal(true);
  };

  const onSubmit = (data: AddReplyFormData) => {
    //TODO: add images array after media upload implementation
    replyOnReview(reviewId, data.reply);
    setOpenReplyModal(false);
    setSentReply(true);
  };

  const media = mediaData?.at(0);

  return (
    <>
      <Modal open={openReplyModal}>
        <FormProvider {...addReplyForm}>
          <AddReply
            text={text}
            textLiked={textLiked}
            textDisLiked={textDisLiked}
            mediaData={mediaData}
            user={user}
            rollScore={rollScore}
            reviewReplies={allReviewReplies}
            onSubmit={onSubmit}
            onClose={() => {
              setOpenReplyModal(false);
              addReplyForm.reset();
            }}
          />
        </FormProvider>
      </Modal>

      <Box className={styles.container}>
        {media &&
          (media.type === 'photo' ? (
            <img className={styles.media} src={media.url} alt="Media" />
          ) : (
            <video className={styles.media} src={media.url} />
          ))}

        <Typography className={styles.text} variant="text-xs">
          {text}
        </Typography>

        {sentReply ? (
          <Button
            className={styles.checkedButton}
            sx={{
              background: `linear-gradient(142deg, #5a5b5b 37%, #78895f 91%)`,
              height: 36,
              cursor: 'default',
            }}
            size="small">
            <CheckIcon className={styles.checkIcon} />
          </Button>
        ) : (
          <>
            {reply && reply.text && (
              <Button
                className={styles.checkedButton}
                sx={{
                  background: `linear-gradient(142deg, #5a5b5b 37%, #78895f 91%)`,
                  height: 36,
                  cursor: 'default',
                }}
                size="small">
                <CheckIcon className={styles.checkIcon} />
              </Button>
            )}

            {!reply && !sentReply && (
              <Button
                className={styles.button}
                variant="contained"
                size="small"
                onClick={handleReplyClick}>
                {t('Reply')}
              </Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default CommentBlock;
