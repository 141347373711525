import {t} from 'i18next';
import Colors from 'styles/colors.scss';

export const mockData = {
  total: 22000,
  mainFaterfall: 5000,
  newWaterfalls: 3000,
  addFeaturedWaterfalls: 8000,
  timelineLastWaterfall: 6000,
};

export const donutChartColorsFirst = [
  Colors['profile-blue'],
  Colors['profile-purple'],
];

export const donutChartColorsSecond = [
  Colors['HEXB8FE61'],
  Colors['profile-red'],
];

export const donutChartColors = [
  Colors['profile-blue'],
  Colors['profile-purple'],
  `linear-gradient(${Colors['gr-landing']})`,
  Colors['profile-red'],
];

export const progressBarText = [
  t('Main.waterfall'),
  t('Featured.waterfall'),
  t('Main.waterfall'),
  t('Featured.waterfall'),
];
