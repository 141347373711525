import {Box, Typography} from '@mui/material';

import styles from './TextLogo.module.scss';
import Colors from 'styles/colors.scss';

interface IProps {
  text?: string;
  dark?: boolean;
  size?: 'small' | 'medium' | 'big';
}

const TextLogo = ({text, dark = false, size = 'small'}: IProps) => {
  const getLogoFromText = (text: string) => {
    const array = text.split(' ');
    const firstLetter = array[0].charAt(0).toUpperCase();

    if (array.length === 1) {
      return firstLetter;
    }

    const secondLetter = array[array.length - 1].charAt(0).toUpperCase();

    return firstLetter + secondLetter;
  };

  const padding = {
    small: '32px',
    medium: '42px',
    big: '56px',
  };

  return (
    <Box
      sx={{
        backgroundColor: dark ? Colors['default-gray-900'] : Colors['bg-color'],
        width: padding[size],
        height: padding[size],
      }}
      className={styles.container}>
      <Typography
        className={styles.text}
        variant={size === 'small' ? 'text-sm-semibold' : 'text-lg'}>
        {text && getLogoFromText(text)}
      </Typography>
    </Box>
  );
};

export default TextLogo;
