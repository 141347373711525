import {ThemeOptions} from '@mui/material/styles/createTheme';

import Colors from 'styles/colors.scss';

export const MuiDivider: ThemeOptions['components'] = {
  MuiDivider: {
    styleOverrides: {
      root: {
        background: Colors['default-gray-700'],
      },
    },
  },
};
