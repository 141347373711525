import {Box} from '@mui/material';

import NLNM from 'assets/icons/NLNM.svg';
import {GradientIcon} from 'components/molecules';
import Gradient from 'styles/gradients.scss';
import styles from './Logo.module.scss';

interface IProps {
  onClick?: () => void;
  isClickable?: boolean;
}

const Logo = ({isClickable = false, onClick = () => {}}: IProps) => {
  return (
    <Box
      sx={{cursor: isClickable ? 'pointer' : 'initial'}}
      onClick={isClickable ? onClick : () => {}}
      className={styles.logoContainer}>
      <GradientIcon name="nlnm" size="36px" gradient={Gradient.logo} />
      <Box component="img" src={NLNM} />
    </Box>
  );
};

export default Logo;
