import React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {InfoLabel} from 'components/atoms';

import styles from './InfoTooltip.module.scss';

interface IProps {
  text: string;
}

const InfoTooltip = ({text}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <InfoLabel text={t(text)} />
    </Box>
  );
};

export default InfoTooltip;
