import {useEffect} from 'react';

import useConsumerDemographics from 'hooks/useConsumerDemographics';
import ConsumerDemographicsTemplate from 'components/templates/consumerDemographics/ConsumerDemographics';
import useUnits from 'hooks/useUnits';
import {processObjectByAge} from 'environment/utils/processObjectByAge';
import {useSelector} from 'react-redux';
import {RootState} from 'store';

const ConsumerDemographics = () => {
  const {
    counters,
    graph,
    getConsumerDemographicsCounters,
    getConsumerDemographicsGraph,
  } = useConsumerDemographics();
  const {currentUnit} = useUnits();

  const {analytics} = useSelector((state: RootState) => state.recentActivity);

  useEffect(() => {
    const now = new Date();

    const twentyFourHoursAgo = new Date(now);
    twentyFourHoursAgo.setHours(now.getHours() - 24);
    const startDate = twentyFourHoursAgo.toISOString();
    const endDate = now.toISOString();

    getConsumerDemographicsCounters(currentUnit.id, startDate, endDate);
    getConsumerDemographicsGraph(currentUnit.id, startDate, endDate);
  }, [currentUnit.id]);

  return (
    <ConsumerDemographicsTemplate
      analytics={analytics}
      columnChartData={processObjectByAge(graph)}
      chartCardData={counters}
      getConsumerDemographicsCounters={getConsumerDemographicsCounters}
    />
  );
};

export default ConsumerDemographics;
