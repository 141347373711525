import {Box, IconButton, Typography} from '@mui/material';
import {IconMark} from 'components/atoms';
import {NewBusinessFormData} from 'hooks/forms';
import useUnits from 'hooks/useUnits';
import {useState, type FC} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'store';
import Colors from 'styles/colors.scss';
import EditBusinessDetails from '../../modals/editBusinessDetails/EditBusinessDetails';
import styles from './BusinessInfo.module.scss';
import BusinessUnitAvatarHandler from './BusinessUnitAvatarHandler';

interface IProps {
  id: string;
  name: string;
  location: string;
  businessDetails: NewBusinessFormData['businessDetails'];
}

const BusinessInfo: FC<IProps> = ({id, name, location, businessDetails}) => {
  const [open, setOpen] = useState<boolean>(false);

  const {currentUnit} = useUnits();

  const isUnitsLoading = useSelector(
    (state: RootState) => state.loading.effects.units.getBusinessUnits,
  );

  return (
    <>
      <EditBusinessDetails
        open={open}
        onClose={() => setOpen(false)}
        defaultValues={{businessDetails}}
      />

      <Box className={styles.businessInfoWrapper}>
        <Box className={styles.businessInfoContainer}>
          <Box className={styles.businessInfo}>
            <Box className={styles.leftSection}>
              {isUnitsLoading ? (
                <Box width="56px" height="56px" />
              ) : (
                <BusinessUnitAvatarHandler
                  ownerId={id}
                  mediaId={currentUnit.avatar?.mediaId}
                />
              )}
              <Box className={styles.nameAndLocation}>
                <Typography variant="text-xl">{name}</Typography>
                <Typography
                  variant="text-sm-light"
                  color={Colors['default-green-200']}>
                  {location}
                </Typography>
              </Box>
            </Box>
            <IconButton sx={{padding: 0}} onClick={() => setOpen(true)}>
              <IconMark name="edit-2" size="24px" color={Colors['HEXB8FE61']} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BusinessInfo;
