import {useEffect, useState} from 'react';
import moment from 'moment';
import EngagementTemplate from 'components/templates/engagement/Engagement';
import useEngagement from 'hooks/useEngagement';
import useUnits from 'hooks/useUnits';
import {getMonthArray} from 'environment/utils/getMonthsArray';
import {generateMonthsString} from 'environment/utils/generateMonthsString';
import {useSelector} from 'react-redux';
import {RootState} from 'store';

const Engagement = () => {
  const {currentUnit} = useUnits();

  const {analytics} = useSelector((state: RootState) => state.recentActivity);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  const {
    review,
    like,
    comment,
    share,
    forumTag,
    waterfallTag,
    view,
    pageVisit,
    getEngagementData,
    getEngagementPageVisitData,
  } = useEngagement();

  const monthArray = getMonthArray(currentUnit.createdAt);
  const monthString = generateMonthsString(monthArray);

  useEffect(() => {
    getEngagementData(currentUnit.id, 'review', monthString);
  }, []);

  const invokeFunctionBasedOnTab = () => {
    switch (selectedTabIndex) {
      case 0:
        getEngagementData(currentUnit.id, 'review', monthString);
        break;
      case 1:
        getEngagementData(currentUnit.id, 'like', monthString);
        break;
      case 2:
        getEngagementData(currentUnit.id, 'comment', monthString);
        break;
      case 3:
        getEngagementData(currentUnit.id, 'share', monthString);
        break;
      case 4:
        getEngagementData(currentUnit.id, 'view', monthString);
        break;
      case 5:
        getEngagementData(currentUnit.id, 'waterfallTag', monthString);
        break;
      case 6:
        getEngagementData(currentUnit.id, 'forumTag', monthString);
        break;
      case 7:
        const now = moment();
        const startDate = now.clone().subtract(24, 'hours').toISOString();
        const endDate = now.toISOString();
        getEngagementPageVisitData(
          currentUnit.id,
          'pageVisitByData',
          startDate,
          endDate,
        );
        getEngagementPageVisitData(currentUnit.id, 'pageVisitByAge');
        getEngagementPageVisitData(currentUnit.id, 'pageVisitUserStatus');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    invokeFunctionBasedOnTab();
  }, [selectedTabIndex, currentUnit]);

  const engagementData = {
    review,
    like,
    comment,
    share,
    forumTag,
    waterfallTag,
    view,
  };

  return (
    <EngagementTemplate
      analytics={analytics}
      onTabChange={handleTabChange}
      selectedIndex={selectedTabIndex}
      engagementData={engagementData}
      pageVisitData={pageVisit}
      currentUnitCreatedAt={currentUnit.createdAt}
      getEngagementPageVisitData={getEngagementPageVisitData}
    />
  );
};

export default Engagement;
