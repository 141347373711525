import {memo} from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './DashboardTotalActivity.module.scss';
import {COLORS_VIEW_CHARTS} from 'environment/constants/colorsViewCharts';
import {ViewOnlyGraph} from 'components/molecules';
import {TitleAndProgress} from 'components/atoms';
import {ITotalActivity} from 'store/models/dashboard';

interface IProps {
  data: ITotalActivity;
}

const DashboardTotalActivity = ({data}: IProps) => {
  const {t} = useTranslation();

  const {engagers, engagements} = data;

  const engagementDataForWeek = engagements.last7days.map(({count}) => count);
  const engagersDataForWeek = engagers.last7days.map(({count}) => count);

  const emptyDataEngagements = engagementDataForWeek.every(
    (item) => item === 0,
  );
  const emptyDataEngagers = engagersDataForWeek.every((item) => item === 0);

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.statsBlock}>
        <Box className={styles.chartContainer}>
          <ViewOnlyGraph
            data={
              emptyDataEngagements ? [100, 100, 100] : engagementDataForWeek
            }
            colors={
              engagements.change >= 0
                ? [COLORS_VIEW_CHARTS[0].color]
                : [COLORS_VIEW_CHARTS[1].color]
            }
            gradient={
              engagements.change >= 0
                ? COLORS_VIEW_CHARTS[0].gradient
                : COLORS_VIEW_CHARTS[1].gradient
            }
            type="areaspline"
          />
        </Box>
        <TitleAndProgress
          position="flex-start"
          title={t('Total.activity')}
          titleVariant="text-base-light"
          data={engagements.total}
          percent={engagements.change}
          isPercentValue={false}
        />
      </Box>
      <Box className={styles.statsBlock}>
        <Box className={styles.chartContainer}>
          <ViewOnlyGraph
            data={emptyDataEngagers ? [100, 100, 100] : engagersDataForWeek}
            colors={
              engagers.change >= 0
                ? [COLORS_VIEW_CHARTS[0].color]
                : [COLORS_VIEW_CHARTS[1].color]
            }
            gradient={
              engagers.change >= 0
                ? COLORS_VIEW_CHARTS[0].gradient
                : COLORS_VIEW_CHARTS[1].gradient
            }
            type="areaspline"
          />
        </Box>
        <TitleAndProgress
          position="flex-start"
          title={t('Total.engagers')}
          titleVariant="text-base-light"
          data={engagers.total}
          percent={engagers.change}
          isPercentValue={false}
        />
      </Box>
    </Box>
  );
};

export default memo(DashboardTotalActivity);
