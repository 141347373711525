import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Box, Typography, MenuItem} from '@mui/material';
import {IconMark, TextLogo} from 'components/atoms';
import styles from './FoldersDropdown.module.scss';
import Colors from 'styles/colors.scss';
import {IBusinessUnit} from 'environment/types/business';
import {IFolder} from 'store/models/folders';
import {ILocation} from 'types';

const FolderIcon = () => (
  <IconMark name="folder" size="20px" color={Colors['default-gray-100']} />
);

interface Column {
  name: string;
  items: {
    id: string;
    content: string;
    unit?: IBusinessUnit[];
    location: ILocation;
  }[];
}

export interface Columns {
  [key: string]: Column;
}

const emptyLocation: ILocation = {
  id: '',
  country: '',
  street: '',
  number: '',
  city: '',
  state: '',
  zipcode: '',
  latitude: 0,
  longitude: 0,
  createdAt: '',
  updatedAt: '',
};

interface IProps {
  businessUnits: IBusinessUnit[] | null;
  setCurrentBusinessUnit: (unit: IBusinessUnit) => void;
  allFolders: IFolder[];
  createNewFolder: (folderName: string) => void;
  editFolderName: (folderId: string, folderName: string) => void;
  moveUnitToFolder: (businessUnitId: string, folderId: string) => void;
}

const FoldersDropdown = ({
  businessUnits,
  setCurrentBusinessUnit,
  allFolders,
  createNewFolder,
  editFolderName,
  moveUnitToFolder,
}: IProps) => {
  const {t} = useTranslation();

  const [showNewFolderInput, setShowNewFolderInput] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [editedFolderId, setEditedFolderId] = useState<string>('');
  const [editedFolderNames, setEditedFolderNames] = useState<{
    [key: string]: string;
  }>({});
  const [isListVisible, setListVisible] = useState<{[key: string]: boolean}>(
    {},
  );

  const transformFoldersToColumns = (folders: IFolder[]) => {
    const columns: Columns = {};

    folders.forEach((folder) => {
      const columnId = folder.id;
      const column: Column = {
        name: folder.name,
        items: folder.businessUnits
          ? folder.businessUnits.map((unit) => {
              const matchingItems =
                businessUnits?.filter((item) => item.id === unit.id) || [];

              const content =
                matchingItems.length > 0
                  ? matchingItems[0]?.companyName || unit.companyName
                  : unit.companyName;

              const location: ILocation =
                matchingItems.length > 0
                  ? (matchingItems[0]?.location as ILocation)
                  : emptyLocation;

              return {
                id: unit.id,
                content: content,
                unit: matchingItems,
                location: location,
              };
            })
          : [],
      };

      columns[columnId] = column;
    });

    return columns;
  };

  const updatedFolders = transformFoldersToColumns(allFolders);
  const [columns, setColumns] = useState(updatedFolders);

  let columnIdCounter = Math.floor(Math.random() * 1000);
  const addNewFolder = () => {
    const newColumnId = 'column' + columnIdCounter++;
    const newColumn = {
      name: newFolderName || 'New Folder',
      items: [],
    };

    setColumns((prevColumns) => {
      const updatedColumns = {
        ...prevColumns,
        [newColumnId]: newColumn,
      };
      return updatedColumns;
    });

    setNewFolderName('');
    setShowNewFolderInput(false);
    createNewFolder(newFolderName);
  };

  const startEditingFolder = (columnId: string) => {
    setEditedFolderId(columnId);
    setEditedFolderNames({
      ...editedFolderNames,
      [columnId]: columns[columnId].name,
    });
  };

  const saveEditedFolder = () => {
    const editedFolder = editedFolderNames[editedFolderId!] || 'New Folder';

    setColumns({
      ...columns,
      [editedFolderId!]: {
        ...columns[editedFolderId!],
        name: editedFolder,
      },
    });

    setEditedFolderId('');
    setEditedFolderNames({});

    if (editedFolderId !== null) {
      editFolderName(editedFolderId, editedFolder);
    }
  };

  const toggleListVisibility = (columnId: string) => {
    setListVisible({
      ...isListVisible,
      [columnId]: !isListVisible[columnId],
    });
  };

  const toggleNewFolderInput = () => {
    setShowNewFolderInput(!showNewFolderInput);
  };

  useEffect(() => {
    setColumns(transformFoldersToColumns(allFolders));
  }, [allFolders]);

  const onDragEnd = (
    result: {
      source: {
        droppableId: string;
        index: number;
      };
      destination?: {
        droppableId: string;
        index: number;
      } | null;
    },
    columns: Columns,
    setColumns: React.Dispatch<React.SetStateAction<Columns>>,
  ) => {
    if (!result.destination) return;
    const {source, destination} = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index !== destination.index
    ) {
      const column = columns[source.droppableId];
      const items = [...column.items];
      const [movedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, movedItem);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: items,
        },
      });

      return;
    }

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const movedUnit = sourceColumn.items[source.index];

    moveUnitToFolder(movedUnit.id, destination.droppableId);

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      {showNewFolderInput && (
        <Box className={styles.newFolderInputWrapper}>
          <FolderIcon />
          <input
            className={styles.newFolderInput}
            type="text"
            value={newFolderName}
            maxLength={50}
            onChange={(e) => setNewFolderName(e.target.value)}
          />

          <Box onClick={addNewFolder} className={styles.button}>
            <Typography color={Colors['HEXB8FE61']} variant="text-xs">
              {t('Save')}
            </Typography>
          </Box>
        </Box>
      )}

      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
        {Object.entries(columns).map(([columnId, column], index) => {
          const isEditing = editedFolderId === columnId;

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#262626',
                userSelect: 'none',
              }}
              key={columnId}>
              {!isEditing ? (
                <Box className={styles.folderHeader}>
                  <Box
                    className={styles.folderLeft}
                    onClick={() => toggleListVisibility(columnId)}>
                    <FolderIcon />
                    <Typography variant="text-sm-light">
                      {column.name}
                    </Typography>
                    {isListVisible[columnId] ? (
                      <IconMark
                        name="arrow-top"
                        size="28px"
                        color={Colors['default-gray-300']}
                      />
                    ) : (
                      <IconMark
                        name="arrow-down"
                        size="28px"
                        color={Colors['default-gray-300']}
                      />
                    )}
                  </Box>
                  <Box
                    className={styles.button}
                    onClick={() => startEditingFolder(columnId)}>
                    <Typography color={Colors['HEXB8FE61']} variant="text-xs">
                      {t('Edit')}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box className={styles.folderHeader}>
                  <Box className={styles.folderLeft}>
                    <FolderIcon />
                    <input
                      className={styles.newFolderInput}
                      type="text"
                      value={editedFolderNames[columnId] || ''}
                      maxLength={50}
                      onChange={(e) =>
                        setEditedFolderNames({
                          ...editedFolderNames,
                          [columnId]: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box className={styles.button}>
                    <Typography
                      onClick={saveEditedFolder}
                      color={Colors['HEXB8FE61']}
                      variant="text-xs">
                      {t('Save')}
                    </Typography>
                  </Box>
                </Box>
              )}

              <div
                style={{
                  margin: 8,
                  height: isListVisible[columnId] ? 'auto' : '1px',
                  overflow: isListVisible[columnId] ? '' : 'hidden',
                  width: '100%',
                }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={styles.folderItemsWrapper}>
                        {column.items.map((item, index) => {
                          const {number, city, country, street} = item.location;
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={index}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',

                                      backgroundColor: '#262626',
                                      ...provided.draggableProps.style,
                                    }}>
                                    <MenuItem
                                      value={item.id}
                                      onClick={() => {
                                        if (item.unit && item.unit[0]) {
                                          setCurrentBusinessUnit(item.unit[0]);
                                        }
                                      }}
                                      className={styles.unitBlock}>
                                      <TextLogo text={item.content} />
                                      <Box className={styles.title}>
                                        <Typography variant="text-base">
                                          {item.content}
                                        </Typography>
                                        <Typography
                                          variant="text-xs"
                                          color={Colors['default-gray-300']}>
                                          {`${number} ${street}, ${city}, ${country}`}
                                        </Typography>
                                      </Box>
                                    </MenuItem>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>

      <Box className={styles.addNewFolder} onClick={toggleNewFolderInput}>
        <IconMark name="folder-add" size="20px" color={Colors.HEXB8FE61} />
        <Typography color={Colors.HEXB8FE61} variant="text-base-light">
          {t('Add.new.folder')}
        </Typography>
      </Box>
    </div>
  );
};
export default FoldersDropdown;
