import {IReview, IReviewProcessed} from 'types';
import {processReviewData} from './processReviewData';

export const processAllReviews = async (
  reviews: IReview[],
): Promise<IReviewProcessed[]> => {
  const processingPromises = reviews.map(async (review) => {
    return await processReviewData(review);
  });

  const settledResults = await Promise.allSettled(processingPromises);

  return settledResults
    .filter((result) => result.status === 'fulfilled')
    .map(
      (result) => (result as PromiseFulfilledResult<IReviewProcessed>).value,
    );
};
