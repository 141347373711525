import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {ReactComponent as DoubleArrowRight} from 'assets/icons/doubleArrowRight.svg';
import styles from './GetBoostCard.module.scss';
import Colors from 'styles/colors.scss';
import IconMark from 'components/atoms/icon/IconMark';

interface IProps {
  onOpenBoostModal?: () => void;
  onOpenReferModal: () => void;
  onOpenGiveAdviceModal: () => void;
}

const GetBoostCard = ({
  onOpenBoostModal,
  onOpenReferModal,
  onOpenGiveAdviceModal,
}: IProps) => {
  const {t} = useTranslation();

  return (
    <>
      <Box className={styles.wrapper}>
        <Typography className={styles.title}>
          {t('Let.us.know.how.to.improve')}
        </Typography>
        <Box className={styles.buttonsBlock}>
          <Button
            onClick={onOpenGiveAdviceModal}
            className={styles.buttonBoost}
            endIcon={<DoubleArrowRight />}>
            <Typography variant="text-base-light">
              {t('Give.Advice')}
            </Typography>
          </Button>
          <Button
            onClick={onOpenReferModal}
            className={styles.buttonQR}
            variant="contained"
            endIcon={<IconMark name="qr-code" color={Colors['bg-color']} />}>
            <Typography variant="text-base-light">
              {t('Refer.a.business')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GetBoostCard;
