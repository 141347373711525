import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import type {RootModel} from '.';
import {
  getEngagementReviewForDayRequest,
  getEngagementReviewForMonthRequest,
  getEngagementLikeForDayRequest,
  getEngagementLikeForMonthRequest,
  getEngagementCommentForDayRequest,
  getEngagementCommentForMonthRequest,
  getEngagementShareForDayRequest,
  getEngagementShareForMonthRequest,
  getEngagementForumTagForDayRequest,
  getEngagementForumTagForMonthRequest,
  getEngagementWaterfallTagForDayRequest,
  getEngagementWaterfallTagForMonthRequest,
  getEngagementViewForDayRequest,
  getEngagementViewForMonthRequest,
  getEngagementPageVisitByDateRequest,
  getEngagementPageVisitByAgeRequest,
  getEngagementPageVisitUserStatusRequest,
  getAllEngagementReviews,
} from 'environment/api/services/engagement';
import {IReview} from 'types';
import {processAllReviews} from 'environment/utils/processAllReviews';

export interface IDayDataItem {
  id: string;
  createdAt: string;
}

export interface IMonthData {
  [month: string]: IDayDataItem[];
}

export interface IEngagementCharts {
  [interactionType: string]: {
    dayData: IDayDataItem[];
    monthData: IMonthData;
  };
}

export interface IUserStatus {
  newUser: number;
  returningVisitor: number;
  oldReturningVisitor: number;
}

export interface IDataByAge {
  group: {
    min: number;
    max: number;
  };
  quantity: number;
}

export interface IPageVisitData {
  dataByDate: IDayDataItem[];
  dataByAge: IDataByAge[];
  userStatus: IUserStatus;
}

interface IEngagementState {
  charts: IEngagementCharts;
  allReviews: IReview[];
  pageVisit: IPageVisitData;
}

const defaultEngagementState: IEngagementState = {
  charts: {
    review: {
      dayData: [],
      monthData: {},
    },
    like: {
      dayData: [],
      monthData: {},
    },
    comment: {
      dayData: [],
      monthData: {},
    },
    share: {
      dayData: [],
      monthData: {},
    },
    forumTag: {
      dayData: [],
      monthData: {},
    },
    waterfallTag: {
      dayData: [],
      monthData: {},
    },
    view: {
      dayData: [],
      monthData: {},
    },
  },
  allReviews: [],
  pageVisit: {
    dataByDate: [],
    dataByAge: [],
    userStatus: {
      newUser: 0,
      returningVisitor: 0,
      oldReturningVisitor: 0,
    },
  },
};

export const engagement = createModel<RootModel>()({
  state: defaultEngagementState,
  reducers: {
    updateReviewForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          review: {
            ...state.charts.review,
            dayData: payload,
          },
        },
      };
    },
    updateReviewForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          review: {
            ...state.charts.review,
            monthData: payload,
          },
        },
      };
    },
    updateAllReviews: (state, payload) => {
      return {
        ...state,
        allReviews: payload,
      };
    },
    updateLikeForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          like: {
            ...state.charts.like,
            dayData: payload,
          },
        },
      };
    },
    updateLikeForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          like: {
            ...state.charts.like,
            monthData: payload,
          },
        },
      };
    },
    updateCommentForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          comment: {
            ...state.charts.comment,
            dayData: payload,
          },
        },
      };
    },
    updateCommentForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          comment: {
            ...state.charts.comment,
            monthData: payload,
          },
        },
      };
    },
    updateShareForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          share: {
            ...state.charts.share,
            dayData: payload,
          },
        },
      };
    },
    updateShareForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          share: {
            ...state.charts.share,
            monthData: payload,
          },
        },
      };
    },
    updateForumTagForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          forumTag: {
            ...state.charts.forumTag,
            dayData: payload,
          },
        },
      };
    },
    updateForumTagForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          forumTag: {
            ...state.charts.forumTag,
            monthData: payload,
          },
        },
      };
    },
    updateWaterfallTagForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          waterfallTag: {
            ...state.charts.waterfallTag,
            dayData: payload,
          },
        },
      };
    },
    updateWaterfallTagForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          waterfallTag: {
            ...state.charts.waterfallTag,
            monthData: payload,
          },
        },
      };
    },
    updateViewForDay: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          view: {
            ...state.charts.view,
            dayData: payload,
          },
        },
      };
    },
    updateViewForMonth: (state, payload) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          view: {
            ...state.charts.view,
            monthData: payload,
          },
        },
      };
    },
    updatePageVisitByDate: (state, payload) => {
      return {
        ...state,
        pageVisit: {
          ...state.pageVisit,
          dataByDate: payload,
        },
      };
    },
    updatePageVisitByAge: (state, payload) => {
      return {
        ...state,
        pageVisit: {
          ...state.pageVisit,
          dataByAge: payload,
        },
      };
    },
    updatePageVisitUserStatus: (state, payload) => {
      return {
        ...state,
        pageVisit: {
          ...state.pageVisit,
          userStatus: payload,
        },
      };
    },
  },
  effects: {
    async getEngagementReviewForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const response = await getEngagementReviewForDayRequest(businessUnitId);
        this.updateReviewForDay(response.data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementReviewForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const response = await getEngagementReviewForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateReviewForMonth(response.data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getAllEngagementReviews(payload) {
      try {
        const {businessUnitId, take, scoreSort, scoreFilter, cursorId} =
          payload;
        const response = await getAllEngagementReviews(
          businessUnitId,
          take,
          scoreSort,
          scoreFilter,
          cursorId,
        );
        const processedData = await processAllReviews(response.data);
        this.updateAllReviews(processedData);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementLikeForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const response = await getEngagementLikeForDayRequest(businessUnitId);
        this.updateLikeForDay(response.data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementLikeForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const {data} = await getEngagementLikeForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateLikeForMonth(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementCommentForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementCommentForDayRequest(businessUnitId);
        this.updateCommentForDay(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementCommentForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const {data} = await getEngagementCommentForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateCommentForMonth(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementShareForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementShareForDayRequest(businessUnitId);
        this.updateShareForDay(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementShareForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const {data} = await getEngagementShareForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateShareForMonth(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementForumTagForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementForumTagForDayRequest(businessUnitId);
        this.updateForumTagForDay(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementForumTagForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const {data} = await getEngagementForumTagForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateForumTagForMonth(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementWaterfallTagForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementWaterfallTagForDayRequest(
          businessUnitId,
        );
        this.updateWaterfallTagForDay(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementWaterfallTagForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const {data} = await getEngagementWaterfallTagForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateWaterfallTagForMonth(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementViewForDay(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementViewForDayRequest(businessUnitId);
        this.updateViewForDay(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementViewForMonth(payload) {
      try {
        const {businessUnitId, months} = payload;
        const {data} = await getEngagementViewForMonthRequest(
          businessUnitId,
          months,
        );
        this.updateViewForMonth(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementPageVisitByDate(payload) {
      try {
        const {businessUnitId, startDate, endDate} = payload;
        const {data} = await getEngagementPageVisitByDateRequest(
          businessUnitId,
          startDate,
          endDate,
        );
        this.updatePageVisitByDate(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementPageVisitByAge(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementPageVisitByAgeRequest(businessUnitId);
        this.updatePageVisitByAge(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async getEngagementPageVisitUserStatus(payload) {
      try {
        const {businessUnitId} = payload;
        const {data} = await getEngagementPageVisitUserStatusRequest(
          businessUnitId,
        );
        this.updatePageVisitUserStatus(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },
  },
});
