import Colors from 'styles/colors.scss';

export const RANGE_BUTTONS = [
  {
    value: '24hours',
    label: '24 hours',
  },
  {
    value: '72hours',
    label: '72 hours',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

export const COLORS_GREEN = [
  Colors['default-green-200'],
  Colors['default-green-300'],
  Colors['default-green-400'],
  Colors['default-green-500'],
  Colors['default-green-600'],
  Colors['default-green-700'],
  Colors['default-green-800'],
  Colors['default-green-900'],
];

export const categories = ['18-24', '25-34', '35-44'];

export const mockDataArray = {
  '26': 7900,
};
