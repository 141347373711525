import {IMonthData} from 'store/models/engagement';
import {getMonthArray} from './getMonthsArray';
import {countDataInMonthsRange} from './countDataInMonthsRange';

export const handleMonthsForCharts = (createdAt: string, data: IMonthData) => {
  const monthArray = getMonthArray(createdAt);

  const dataInMonthsRange = countDataInMonthsRange(data);

  const checked =
    monthArray.length === 1
      ? [dataInMonthsRange[1]]
      : dataInMonthsRange.reverse();

  const resultArray = checked;

  return resultArray;
};
