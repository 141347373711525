import {Box} from '@mui/material';
import {ComingSoon} from 'components/molecules';
import {
  CurrentTags,
  Members,
  PaymentMethods,
  Personality,
  YourLocations,
} from 'components/organisms';
import {SettingsTemplate} from 'components/templates';
import {stringifyLocation} from 'environment/utils/stringifyLocation';
import {NewBusinessFormData} from 'hooks/forms';
import {
  castQuestionsForFrontend,
  castScheduleForFrontend,
  castTagsForFrontend,
} from 'hooks/forms/helpers';
import useAuth from 'hooks/useAuth';
import useUnits from 'hooks/useUnits';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, RootState} from 'store';
import styles from './Settings.module.scss';

const Settings = () => {
  const dispatch = useDispatch<Dispatch>();

  const {business, refetchBusiness} = useAuth();
  const {currentUnit} = useUnits();

  const {members} = useSelector((state: RootState) => state.business);

  useEffect(() => {
    dispatch.business.getMembers();
  }, [dispatch.business]);

  if (!currentUnit.location || !business) {
    return null;
  }

  const email = business.email;

  const businessUnitName = currentUnit.companyName;
  const locationString = stringifyLocation(currentUnit.location);
  const schedule = castScheduleForFrontend(currentUnit.operationHours);

  const location = currentUnit.location;
  const businessDetails = {
    city: location.city,
    state: location.state || '',
    country: location.country,
    street: location.street,
    number: location.number || '',
    zipcode: location.zipcode || '',
    companyName: businessUnitName,
    websiteUrl: currentUnit.websiteUrl || '',
    phone: currentUnit.contactNumber,
  } satisfies NewBusinessFormData['businessDetails'];

  const tags = castTagsForFrontend(currentUnit.businessUnitPickedLabels);
  const questions = castQuestionsForFrontend(currentUnit.questions);

  return (
    <SettingsTemplate>
      <Box className={styles.container}>
        <YourLocations
          id={currentUnit.id}
          name={businessUnitName}
          email={email}
          location={locationString}
          schedule={schedule}
          businessDetails={businessDetails}
          refetchBusiness={refetchBusiness}
        />
        <Personality questions={questions} />
      </Box>

      <Box className={styles.container}>
        <Members
          members={members}
          businessUnitName={businessUnitName}
          businessUnitId={currentUnit.id}
        />
        <CurrentTags tags={tags} />
        <ComingSoon>
          <PaymentMethods />
        </ComingSoon>
      </Box>
    </SettingsTemplate>
  );
};

export default Settings;
