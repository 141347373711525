import {Box, Button, Divider, Typography} from '@mui/material';
import {ReactComponent as MasterCard} from 'assets/icons/mastercard.svg';
import {SettingsSection} from 'components/molecules';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AddPaymentMethodModal} from '../../modals';
import styles from './PaymentMethods.module.scss';

const PaymentMethods = () => {
  const {t} = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <AddPaymentMethodModal open={open} onClose={() => setOpen(false)} />

      <SettingsSection>
        <Typography variant="text-xl">{t('Payment.methods')}</Typography>

        <Divider sx={{marginY: '24px'}} />

        <Box className={styles.paymentMethod}>
          <Box className={styles.iconWithText}>
            <MasterCard />
            <Box className={styles.left}>
              <Typography variant="text-base-light">
                {t('American.Express')}
              </Typography>
              <Typography className={styles.dimmed}>****4560</Typography>
            </Box>
          </Box>
          <Button type="button" variant="text" className={styles.button}>
            <Typography variant="text-sm-light">{t('Delete')}</Typography>
          </Button>
        </Box>

        <Divider sx={{marginY: '24px'}} />

        <Button
          type="button"
          variant="text"
          className={styles.button}
          onClick={() => setOpen(true)}>
          <Typography variant="text-sm-light">
            {t('Add.a.payment.method')}
          </Typography>
        </Button>
      </SettingsSection>
    </>
  );
};

export default PaymentMethods;
