import {Box} from '@mui/material';
import {DashboardCardWrapper, PlaceMarker} from 'components/atoms';
import {ROUTES} from 'environment/constants/routes';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './MapContainer.module.scss';

import 'mapbox-gl/dist/mapbox-gl.css';
import Map, {Marker} from 'react-map-gl';

const MAPBOX_TOKEN = process.env.REACT_APP_TEST_MAPBOX_ACCESS_TOKEN;

interface IProps {
  currentRestourant: any; //TODD add types
  nearbyRestourant: any; //TODD add types
}

const MapContainer = ({currentRestourant, nearbyRestourant}: IProps) => {
  const {t} = useTranslation();

  const {latitude, longitude} = currentRestourant;

  return (
    <DashboardCardWrapper
      title={t('Business.boosts.in.your.area')}
      navigateTo={ROUTES.dashboard.boosts}>
      <Box className={styles.map}>
        <Map
          mapboxAccessToken={MAPBOX_TOKEN}
          initialViewState={{
            longitude: -89,
            latitude: 43,
            zoom: 3.5,
          }}
          mapStyle="mapbox://styles/mapbox/dark-v10">
          <Marker longitude={longitude} latitude={latitude} anchor="bottom">
            <PlaceMarker isOwner={true} />
          </Marker>
          {/* TODO add types */}
          {nearbyRestourant.map((item: any, index: any) => {
            return (
              <Marker
                key={index + 1}
                longitude={item.longitude}
                latitude={item.latitude}
                anchor="bottom">
                <PlaceMarker
                  isOwner={false}
                  title={item.name}
                  data={item.data}
                  showRate={true}
                />
              </Marker>
            );
          })}
        </Map>
      </Box>
    </DashboardCardWrapper>
  );
};

export default memo(MapContainer);
