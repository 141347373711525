import {t} from 'i18next';

export function toSortedTags(
  category: string,
  items: {
    id: string;
    emoji: string | string[];
  }[],
) {
  return [...items].sort((a, b) => {
    return t(`tagsLabels.${category}.${a.id}`).localeCompare(
      t(`tagsLabels.${category}.${b.id}`),
      undefined,
      {
        sensitivity: 'base',
      },
    );
  });
}
