import {Box, Typography} from '@mui/material';

import styles from './Zoom.module.scss';

interface IProps {
  icon: string;
  onClick: () => void;
}

const Zoom = ({icon, onClick}: IProps) => {
  return (
    <Box onClick={onClick} className={styles.container}>
      <Typography className={styles.icon}>{icon}</Typography>
    </Box>
  );
};

export default Zoom;
