import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
} from '@mui/material';

import styles from './QuickComment.module.scss';
import {GradientIcon} from 'components/molecules';

interface IProps {
  waterfallId: string;
  comment?: string;
  sendComment: (waterfallId: string, commentText: string) => any;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const QuickComment = ({
  sendComment,
  comment,
  waterfallId,
  updateWaterfallReply,
}: IProps) => {
  const {t} = useTranslation();

  const [commentText, setCommentText] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= 200) {
      setCommentText(newValue);
    }
  };

  const handleSendComment = async () => {
    try {
      setIsSending(true);

      if (commentText.length >= 1) {
        const response = await sendComment(waterfallId, commentText);
        if (response.status === 201) {
          setIsSuccess(true);
          updateWaterfallReply(waterfallId, response.data.text);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {isSuccess ? (
        <FormControl variant="outlined" className={styles.inputComplete}>
          <OutlinedInput
            disabled
            value={commentText}
            endAdornment={
              <InputAdornment position="end">
                <GradientIcon name="check" size="24px" />
              </InputAdornment>
            }
          />
        </FormControl>
      ) : comment && comment !== null ? (
        <FormControl variant="outlined" className={styles.inputComplete}>
          <OutlinedInput
            disabled
            value={comment}
            endAdornment={
              <InputAdornment position="end">
                <GradientIcon name="check" size="24px" />
              </InputAdornment>
            }
          />
        </FormControl>
      ) : commentText && isSending ? (
        <FormControl variant="outlined" className={styles.inputComplete}>
          <OutlinedInput
            disabled
            value={commentText}
            endAdornment={
              <InputAdornment position="end">
                <Typography color="#B8FE61" variant="text-sm-light">
                  {t('Sending')}...
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      ) : (
        <FormControl variant="outlined" className={styles.input}>
          <OutlinedInput
            placeholder={t('Quick.comment')}
            value={commentText}
            onChange={handleChange}
            endAdornment={
              commentText.length >= 1 && (
                <InputAdornment
                  position="end"
                  sx={{cursor: 'pointer'}}
                  onClick={handleSendComment}>
                  <GradientIcon name="double-right-arrows" size="24px" />
                </InputAdornment>
              )
            }
          />
        </FormControl>
      )}
    </>
  );
};

export default QuickComment;
