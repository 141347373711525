import {Box} from '@mui/material';
import {
  AnalyticsRangeButtons,
  DonutChart,
  PageHeader,
} from 'components/molecules';
import {ROUTES} from 'environment/constants/routes';
import {FC, memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './Activity.module.scss';
import {Analytics, AnalyticsCategory} from 'types/business-unit-analytics';
import {DONUT_COLORS} from 'environment/constants/analytics';
import AnalyticsTiles from '../analyticsTiles/AnalyticsTiles';
import {roundNumberToDecimal} from 'environment/utils/roundNumberToDecimal';

interface Props {
  title: string;
  analytics: Analytics;
}

const Activity: FC<Props> = ({title, analytics}) => {
  const {t} = useTranslation();

  const totalActivity = useMemo(
    () =>
      Object.values(analytics).reduce((acc, item) => (acc += item.count), 0),
    [analytics],
  );

  const donutChartData = useMemo(
    () =>
      Object.entries(analytics).map(([key, value]) => {
        const category = key as AnalyticsCategory;
        return [t(`analytics.${category}`), value.count];
      }),
    [analytics, t],
  );

  return (
    <Box>
      <PageHeader
        title={title}
        backButtonNavigateTo={ROUTES.dashboard.home}
        rightSection={<AnalyticsRangeButtons />}
      />
      <Box className={styles.blockActivity}>
        {/* todo add checking of data, and if all data is 0 - render that block */}
        {/* <Box className={styles.emptyContainer}>
          <Box component="img" alt="empty chart" src={emptyCircle} />
          <Box className={styles.zeroActivity}>
            <Typography
              variant="text-sm-light"
              color={Colors['default-gray-100']}>
              {t('Total.activity')}
            </Typography>
            <Typography sx={{fontSize: '28px', fontWeight: 500}}>0</Typography>
          </Box>
        </Box> */}
        <Box className={styles.chartContainer}>
          <DonutChart
            data={donutChartData}
            colors={DONUT_COLORS}
            height={220}
            subtitle={t('Total.activity')}
            title={roundNumberToDecimal(totalActivity)}
          />
        </Box>
        <AnalyticsTiles analytics={analytics} tileWidth="208px" />
      </Box>
    </Box>
  );
};

export default memo(Activity);
