import Colors from 'styles/colors.scss';

export const configStyles = {
  chart: {
    backgroundColor: Colors['bg-color'],
    spacingBottom: 0,
    spacingTop: 5,
    spacingRight: 0,
    spacingLeft: 2,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    lineWidth: 0,
    gridLineWidth: 0,
    tickWidth: 0,
    tickInterval: 2,
    labels: {
      y: 15,
      style: {
        color: Colors['default-gray-200'],
        fontSize: '8px',
      },
    },
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      style: {
        color: Colors['default-gray-200'],
        fontSize: '8px',
      },
      formatter: (context: Highcharts.AxisLabelsFormatterContextObject) => {
        return context.value;
      },
    },
    title: {
      enabled: false,
    },
  },
  tooltip: {
    borderWidth: 1,
    borderColor: ' rgba(255, 255, 255, 0.20)',
    backgroundColor: 'rgb(97, 97, 97)',
    padding: 10,
    borderRadius: 8,
    style: {
      color: Colors.white,
      fontSize: '12px',
    },
  },
};
