import {Box, Typography} from '@mui/material';
import styles from './Mantra.module.scss';
import {type FC} from 'react';

interface IProps {
  title: string;
  text: string;
}

const Mantra: FC<IProps> = ({title, text}) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Typography variant="text-base-light">{title}</Typography>
        <Typography className={styles.mantraText}>{text}</Typography>
      </Box>
    </Box>
  );
};

export default Mantra;
