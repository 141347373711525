import {Box, Typography} from '@mui/material';
import {SettingsModal} from 'components/molecules';
import {FC} from 'react';
import Colors from 'styles/colors.scss';
import {PRIVACY_POLICY} from './data/privacy-policy';
import {Header, LinkTypography} from './components';
import {Link} from 'components/atoms';
import {useTranslation} from 'react-i18next';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const PrivacyPolicy: FC<IProps> = ({open, onClose}) => {
  const {t} = useTranslation();

  const lastUpdate = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(2023, 10, 9));

  return (
    <SettingsModal open={open} onClose={onClose}>
      <Box display="flex" flexDirection="column" gap="40px">
        <Header
          uppercase
          title={t('Privacy.and.Cookie.Policy')}
          text={`${t('Last.Update')}: ${lastUpdate}`}
        />
        {PRIVACY_POLICY.map(({id, title, content}) => (
          <Box key={id} display="flex" flexDirection="column" gap="16px">
            <Typography variant="text-xl" color={Colors.HEXB8FE61}>
              {id}. {title}
            </Typography>
            {content}
          </Box>
        ))}
        <Link navigateTo="mailto:message@nolemon.app">
          <LinkTypography>message@nolemon.app</LinkTypography>
        </Link>
        <Typography>
          Australian users can complain to the Office of the Australian
          Information Commissioner (OAIC) if they believe there is a breach of
          privacy{' '}
          <Link
            navigateTo="https://www.oaic.gov.au/privacy/privacy-complaints"
            target="_blank">
            <LinkTypography>
              https://www.oaic.gov.au/privacy/privacy-complaints
            </LinkTypography>
          </Link>
        </Typography>
      </Box>
    </SettingsModal>
  );
};

export default PrivacyPolicy;
