import {toSortedTags} from './utils';

const DOG_FRIENDLY = [
  {
    id: 'dog-friendly',
    emoji: 'bg-dogfriendly',
  },
  {
    id: 'dog-friendly-patio',
    emoji: 'bg-dogfriendlypatio',
  },
  {
    id: 'doggie-treats',
    emoji: 'bg-doggietreats',
  },
  {
    id: 'pup-friendly-menu',
    emoji: 'bg-pupfriendlymenu',
  },
  {
    id: 'fresh-water-bowls',
    emoji: 'bg-freshwaterbowls',
  },
  {
    id: 'outdoor-seating',
    emoji: 'bg-outdoorseating',
  },
  {
    id: 'canine-specialties',
    emoji: 'bg-caninespecialties',
  },
  {
    id: 'pet-friendly',
    emoji: 'bg-petfriendly',
  },
  {
    id: 'doggy-play-area',
    emoji: 'bg-doggyplayarea',
  },
  {
    id: 'dog-themed',
    emoji: 'bg-dogthemed',
  },
  {
    id: 'yappy-hour',
    emoji: ['bg-dog', 'bg-beamingface'],
  },
];

export default toSortedTags('dogFriendlyLabel', DOG_FRIENDLY);
