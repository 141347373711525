import axios from '../axios';
import {FOLDERS, UPDATE_FOLDER_NAME} from 'environment/constants/url';

export const getAllFoldersRequest = () => {
  return axios.get(FOLDERS().ALL_FOLDERS);
};

export const createNewFolderRequest = (folderName: string) => {
  const name = {
    name: folderName,
  };
  return axios.post(FOLDERS().NEW_FOLDER, name);
};

export const deleteFolderRequest = (folderId: string) => {
  return axios.delete(FOLDERS(folderId).DELETE_FOLDER);
};

export const updateFolderNameRequest = (
  folderId: string,
  folderName: string,
) => {
  const name = {
    name: folderName,
  };
  return axios.put(UPDATE_FOLDER_NAME(folderId).UPDATE, name);
};

export const moveUnitToFolderRequest = (
  folderId: string,
  businessUnitId: string,
) => {
  return axios.put(FOLDERS(folderId, businessUnitId).ADD_UNIT_TO_FOLDER);
};

export const deleteUnitFromFolderRequest = (
  folderId: string,
  businessUnitId: string,
) => {
  return axios.delete(
    FOLDERS(folderId, businessUnitId).DELETE_UNIT_FROM_FOLDER,
  );
};
