import {ADD_PAYMENT_METHOD_SCHEMA} from 'environment/validation/resolvers';
import {useForm} from 'react-hook-form';

const defaultValues = {
  nameOnCard: '',
  cardNumber: '',
  expiryDate: '',
  securityCode: '',
};

export type AddPaymentMethodFormData = typeof defaultValues;

export const useAddPaymentMethodForm = () => {
  return useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: ADD_PAYMENT_METHOD_SCHEMA,
  });
};
