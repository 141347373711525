import {Box} from '@mui/material';
import {Header} from 'components/organisms';
import {useLayoutEffect, useState, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import styles from './LayoutWithHeader.module.scss';
import useUnits from 'hooks/useUnits';
import useFolders from 'hooks/useFolders';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'store';

const LayoutWithHeaderTemplate = () => {
  const dispatch = useDispatch<Dispatch>();

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const {businessUnits, currentUnit, setCurrentBusinessUnit} = useUnits();
  const {
    getAllBusinessFolders,
    allFolders,
    createNewFolder,
    editFolderName,
    moveUnitToFolder,
  } = useFolders();

  useEffect(() => {
    getAllBusinessFolders();
    dispatch.units.getBusinessUnits();
  }, [dispatch.units]);

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const {pathname} = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box className={styles.background}>
      <Header
        handleDrawerToggle={handleDrawerToggle}
        businessUnits={businessUnits}
        currentUnit={currentUnit}
        setCurrentBusinessUnit={setCurrentBusinessUnit}
        allFolders={allFolders}
        editFolderName={editFolderName}
        createNewFolder={createNewFolder}
        moveUnitToFolder={moveUnitToFolder}
      />
      <Box className={styles.container}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default LayoutWithHeaderTemplate;
