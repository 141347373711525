import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';

import {BasicArea} from 'components/molecules';
import styles from './ExpendituresCharts.module.scss';
import Colors from 'styles/colors.scss';
import {countDataInWeekRange} from 'environment/utils/countDataInWeekRange';
import {checkAndGenerateArray} from 'environment/utils/checkAndGenerateArray';

interface IProps {
  chartData: any; //TODO add type
  areasData: any; //TODO add type
}

const ExpendituresCharts = ({chartData, areasData}: IProps) => {
  const {t} = useTranslation();

  const {total, Review, ...remainingChartData} = chartData;

  const weekData = countDataInWeekRange([]);
  const {modifiedArray} = checkAndGenerateArray(weekData);
  const categories = weekData.map((item) => item[0].substring(0, 2));

  return (
    <Box className={styles.chartsContainer}>
      <div className={styles.wireframesChart}>
        <BasicArea
          data={modifiedArray}
          categories={categories}
          isEmpty={true}
          colors={[Colors.HEXB8FE61]}
          gradient={[
            [0, Colors['linear-green1']],
            [1, Colors['linear-green2']],
          ]}
          title={t('Review')}
          legend={false}
          grid={true}
        />
      </div>

      {Object.entries(remainingChartData).map(([title, item]: any, index) => {
        return (
          <Box className={styles.wireframesChart} key={index}>
            <BasicArea
              data={modifiedArray}
              isEmpty={true}
              categories={categories}
              colors={[Colors.HEXB8FE61]}
              gradient={[
                [0, Colors['linear-green1']],
                [1, Colors['linear-green2']],
              ]}
              title={title}
              legend={false}
              grid={true}
              toggle={true}
              toggleChecked={false}
              toggleLables={{
                active: t('TotalCost'),
                inactive: t('BasicCost'),
              }}
              onChangeToggle={() => {}} //TODO add function that will change toggle
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ExpendituresCharts;
