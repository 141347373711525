import {Box, Typography} from '@mui/material';
import {Link} from 'components/atoms';
import {
  BulletList,
  Container,
  Italic,
  LinkTypography,
  LowerLatinOrderedList,
  LowerRomanOrderedList,
} from '../components';

export const PRIVACY_POLICY = [
  {
    id: 1,
    title: 'Scope',
    content: (
      <Container>
        <Typography>
          We care about your online privacy. This Privacy Policy (the “Policy”)
          describes our practices with respect to collection, use, disclosure
          and protection of your information when you visit{' '}
          <Link navigateTo="https://www.nolemon.app" target="_blank">
            <LinkTypography>https://www.nolemon.app/</LinkTypography>
          </Link>{' '}
          and (the “Site”) and nolemon (the “App”). The Site and the App are
          collectively referred to herein as the “Platform.”
        </Typography>
        <Typography>
          Note that this Policy is only valid on our Platform, not any third
          party networks, even if they are referenced on our Platform. It is
          your responsibility to familiarize yourself and comply with any
          relevant third party networks.
        </Typography>
        <Typography>
          If you visit our Platform, that signifies your legal acceptance of the
          Policy. You must exit the Platform if you do not agree with any
          provision(s) of this Privacy Policy. We reserve the right to change
          this Policy at any time at our sole discretion. The effective date of
          the last update is at the top of this page, so visit it occasionally
          to see if there are any changes.
        </Typography>
      </Container>
    ),
  },
  {
    id: 2,
    title: 'Collection of User Data',
    content: (
      <Container>
        <Typography>
          Here are the types of information regarding the Platform users we may
          collect:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            <Italic>Personal information </Italic>
            such as name, email, profile photo, if you choose to provide any.
          </Typography>
          <Typography component="li">
            <Italic>Payment Information.</Italic>
            <BulletList>
              <Box />
              <Typography component="li">Credit card numbers.</Typography>
              <Typography component="li">PayPal details.</Typography>
              <Typography component="li">Billing address.</Typography>
            </BulletList>
          </Typography>
          <Typography component="li">
            <Italic>Communications: </Italic>if you contact us for any reason,
            we will receive whatever information you voluntarily provide (e.g.,
            your feedback, ratings and reviews).
          </Typography>
          <Typography component="li">
            <Italic>Your Devices: </Italic>device identifiers, phone
            manufacturer and carrier, browser, IP address, operating system
            version, mobile advertising identifiers, application installations.
          </Typography>
          <Typography component="li">
            <Italic>Platform Interaction: </Italic>we see what content our
            Platform users access, when and how they interact with the Platform
            content/pages. If you use the Boost feature, you agree to allow us
            to share anonymized engagement metrics such as overall engagement
            score.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 3,
    title: 'Use of Data',
    content: (
      <Container>
        <Typography>
          We use the collected data for the following purposes:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            To provide the services and/or information that you have asked for.
          </Typography>
          <Typography component="li">
            To respond to a court order, regulatory request or legal process.
          </Typography>
          <Typography component="li">
            To enforce our rights, carry out our obligations, prevent fraud,
            facilitate disputes between users.
          </Typography>
          <Typography component="li">
            To accomplish any other purpose for which the information was
            provided.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 4,
    title: 'Reuse of Content',
    content: (
      <LowerLatinOrderedList>
        <Typography component="li">
          Any reviews, photos, videos, and other user-generated content that you
          voluntarily submit to public areas of our Platform may be reused by
          the reviewed businesses for promotional purposes on their own websites
          and social media pages. By contributing content on our Platform, you
          grant the reviewed businesses a non-exclusive, royalty-free license to
          display, transmit, distribute, and promote your content on their own
          sites in connection with promoting their business.
        </Typography>
        <Typography component="li">
          You can revoke this content reuse consent at any time by emailing{' '}
          <Link navigateTo="mailto:message@nolemon.app">
            <LinkTypography>message@nolemon.app</LinkTypography>
          </Link>
          . Upon receiving the revocation request, we will notify the applicable
          businesses that they must immediately cease reusing your content.
          Please note that revoking this consent will not affect any prior reuse
          of your content before we notified the business of the revocation.
        </Typography>
      </LowerLatinOrderedList>
    ),
  },
  {
    id: 5,
    title: 'Disclosure of Data',
    content: (
      <Container>
        <Typography>
          In addition to sharing your data as described above, we may disclose
          the collected personal information as follows:
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            In case there is a sale, merger or other transfer in the ownership
            of our Platform, the successor will receive personal information
            about our Platform users along with the assets transferred.
          </Typography>
          <Typography component="li">
            If we decide that disclosure is appropriate to protect the property,
            safety, rights of the Platform, its users or the public.
          </Typography>
          <Typography component="li">
            Aggregated, anonymized information that does not identify any
            particular user can be disclosed without restriction.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 6,
    title: 'Cookie Policy',
    content: (
      <Container>
        <Typography>
          Cookies are small bits of text data placed on your device when you
          visit sites. Cookies record data about your activity, such as which
          pages you view and what you click on. Cookies assist our Platform to
          recognize your device when you return. For example, cookies can help
          us to remember your preferences, username, analyze the performance of
          our Platform and recommend content that may be most relevant to your
          interests.
        </Typography>
        <Typography>Here are the reasons we may use cookies:</Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            <Italic>Analytics. </Italic>This type of cookies shows us which
            pages users view, which links are popular, etc. These cookies only
            provide anonymized information that does not identify anybody
            personally. This information is then bundled with the similar
            information from the other users, so that we can analyze the general
            usage patterns.
          </Typography>
          <Typography component="li">
            <Italic>To show relevant advertising. </Italic>If we allow ads on
            our Platform, they can contain cookies in order to provide ads most
            relevant to your interests. They can also help limit the number of
            times you see a particular ad and to assess the effectiveness of the
            advertising campaign.
          </Typography>
          <Typography component="li">
            <Italic>Essential cookies. </Italic>These are necessary to provide
            the services that you have asked for. Without these essential
            cookies, our Platform would not be able to operate. They are
            necessary to enable users to navigate through the Platform and use
            its main features. E.g., essential cookies identify registered users
            so that they can access member-only areas of the site. Essential
            cookies keep users logged in. If a subscriber disables essential
            cookies, that subscriber won’t be able to get to all of the content
            that a subscription entitles them to. These cookies don't collect
            information that could be utilized for the purposes of marketing or
            figuring out what places on the internet you have visited.
          </Typography>
          <Typography component="li">
            <Italic>To improve your browsing experience. </Italic>This type of
            cookies enables the site to remember users’ preferences and
            settings, such as geographic region or language. They can also be
            used to restrict the number of times an ad is shown, to remember
            which forms you have already filled in, so that you don’t have to do
            it again.
          </Typography>
          <Typography component="li">
            <Italic>To implement tracking technology on our Platform. </Italic>
            This tracking does not use your personal information; it uses
            deidentified data (i.e., data that cannot be tied specifically to
            you). We will not combine this data with your other personal
            information without your prior express permission.
          </Typography>
        </LowerLatinOrderedList>
        <Typography>
          There is a way to turn off cookies by going to your browser’s Help or
          Settings menu. However, keep in mind that disabling cookies may limit
          your use of the Platform and/or delay or affect the way in which it
          operates.
        </Typography>
      </Container>
    ),
  },
  {
    id: 7,
    title: 'Data Security',
    content: (
      <Typography>
        Only our administrators are allowed to access our Platform’s
        password-protected server where your personal information is stored. We
        utilize SSL. However, any transmission of information over the Internet
        has its inherent risks, so we cannot guarantee the absolute security of
        your personal information. Transmit personal information over the
        Internet at your own risk. We shall not be liable for circumvention of
        security measures or privacy settings on the Platform. It is your
        responsibility to keep your login credentials, if any, confidential.
      </Typography>
    ),
  },
  {
    id: 8,
    title: 'Children’s Privacy',
    content: (
      <Typography>
        We do not knowingly collect any personal information about children
        under the age of 13. Our Platform is not directed to children under the
        age of 13. If we become aware that a child under 13 has provided any
        personal info, it will be erased from our database as soon as reasonably
        possible, except when we need to keep that information for legal
        purposes or to notify a parent or guardian. However, portions of this
        data may remain in back-up archives or web logs even after we erase it
        from our databases. If a parent or guardian believes that a child has
        sent us personal information, send us an e-mail.
      </Typography>
    ),
  },
  {
    id: 9,
    title: 'Users’ Rights, CCPA',
    content: (
      <LowerLatinOrderedList>
        <Typography component="li">
          We will not share your personal information with third parties for
          their direct marketing purposes to the extent it is forbidden by law.
          If our practices change, we will do so in accordance with applicable
          laws and will notify you in advance. California law requires that
          operators of online services disclose how they respond to a Do Not
          Track signal. Some browsers have incorporated “Do Not Track” features.
          Most of these features, when turned on, send a signal or preference to
          the online service that a user visits, indicating that the user does
          not wish to be tracked. At this time we do not respond to Do Not Track
          signal.
        </Typography>
        <Typography component="li">
          You can request disclosure of your information collected by us by
          writing to the email at the end of this Policy. We will then provide
          the requested information, its sources and purposes of use, in a
          portable and easily accessible format within 45 days of the request.
        </Typography>
        <Typography component="li">
          You have the right to request deletion of your personal information
          from our systems by submitting a request to the email at the end of
          this Policy.
        </Typography>
        <Typography component="li">
          You have the right to nondiscrimination for exercising your rights.
          That means you cannot be denied goods or services, charged different
          prices, or provided different quality of goods/services for asserting
          your legal rights.
        </Typography>
      </LowerLatinOrderedList>
    ),
  },
  {
    id: 10,
    title: 'International Transfer',
    content: (
      <Typography>
        We process your personal information in the Unites States. This is where
        it will be transferred to in case you are located somewhere else. By
        submitting any personal information to us, you agree to its transfer to
        and processing in the Unites States.
      </Typography>
    ),
  },
  {
    id: 11,
    title: 'EU Users’ Rights',
    content: (
      <Container>
        <Typography>
          This section of our Privacy Policy applies to the users of our
          Platform in the European Union. We would like to inform you about your
          GDPR rights and how we safeguard them.
        </Typography>
        <LowerLatinOrderedList>
          <Typography component="li">
            <Italic>
              Your GDPR rights to be informed, to access, rectify, erase or
              restrict the processing of your personal information.{' '}
            </Italic>
            You have the right to obtain free information about what personal
            data we have obtained about you, where it is stored, for how long,
            for what purposes it is used, to whom it was disclosed. You have the
            right to have us, without undue delay, rectify of inaccurate
            personal data concerning you. That means you can request we change
            your personal data in our records, or have your incomplete personal
            data completed. You have the “right to be forgotten,” i.e. to have
            us delete your personal information, without undue delay, if the
            data is no longer necessary in relation to the purposes for which it
            was collected. However, GDPR gives us the right to refuse erasure if
            we can demonstrate compelling legitimate grounds for keeping your
            information.
          </Typography>
          <Typography component="li">
            <Italic>GDPR gives you the right to restrict processing </Italic>if
            any of the following applies:{' '}
            <LowerRomanOrderedList inner>
              <Typography component="li">
                If you contest the accuracy of your personal data, we will
                restrict processing it for a period enabling us to verify its
                accuracy.
              </Typography>
              <Typography component="li">
                The processing is unlawful and you oppose its erasure and
                request instead the restriction of its use.
              </Typography>
              <Typography component="li">
                We no longer need your personal data for the purposes of the
                processing, but you require us to restrict processing for the
                establishment, exercise or defense of legal claims.
              </Typography>
              <Typography component="li">
                You have objected to processing pursuant to Article 21(1) of the
                GDPR pending the verification whether our legitimate grounds
                override yours.
              </Typography>
            </LowerRomanOrderedList>
          </Typography>
          <Typography component="li">
            <Italic>Right to data portability. </Italic>Upon request, we will
            provide you your personal data in our possession, in a structured,
            commonly used and machine-readable format. You have the right to
            transmit that data to another controller if doing so does not
            adversely affect the rights and freedoms of others.
          </Typography>
          <Typography component="li">
            <Italic>Right to object. </Italic>You can object, on grounds
            relating your particular situation, at any time, to processing of
            your personal information, if based on point (e) or (f) of Article
            6(1) of the GDPR. We will then have to stop processing, unless we
            can demonstrate compelling legitimate grounds for the processing. If
            you object to the processing for direct marketing purposes, we will
            have to stop processing for these purposes.
          </Typography>
          <Typography component="li">
            <Italic>Right to withdraw consent. </Italic>GDPR grants you the
            right to withdraw your earlier given consent, if any, to processing
            of your personal data at any time.
          </Typography>
          <Typography component="li">
            <Italic>Rights related to automated decision making. </Italic>As a
            responsible business, we do not rely on any automated decision
            making, such as profiling.
          </Typography>
        </LowerLatinOrderedList>
      </Container>
    ),
  },
  {
    id: 12,
    title: 'Accessing and Correcting Your Personal Information',
    content: (
      <Typography>
        You can view and edit some of your account information yourself after
        logging in. If you terminate your account, we may retain some
        information for as long as necessary to evaluate Platform usage,
        troubleshoot issues, resolve disputes and collect any fees owed. If you
        have any questions or wish to ask to access, modify or delete any of
        your personal data on our Platform, please contact us. Note that we can
        deny your request if we think it would violate any law or cause the
        information to be incorrect.
      </Typography>
    ),
  },
];
