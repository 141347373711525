import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './PlaceMarker.module.scss';
import RestourantRate from '../restourantRate/RestourantRate';
import anyRestourant from 'assets/icons/anyRestourant.svg';
import yourRestourant from 'assets/icons/yourRestourant.svg';
import spot from 'assets/icons/spot.svg';

interface IProps {
  isOwner: boolean;
  title?: string;
  data?: any; // TODO add type
  showRate?: boolean;
  imageSize?: React.CSSProperties;
  imageCardSize?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  backgroundStyles?: React.CSSProperties;
}

const PlaceMarker = ({
  isOwner,
  title,
  data,
  showRate = false,
  imageSize,
  imageCardSize,
  textStyles,
  backgroundStyles,
}: IProps) => {
  const {t} = useTranslation();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderImage = () => {
    if (isOwner === true) {
      return (
        <Box className={styles.uniqueImageCard} style={imageCardSize}>
          <Box
            component="img"
            src={yourRestourant}
            className={styles.uniqueImage}
            style={imageSize}
          />
        </Box>
      );
    } else if (isOwner === false) {
      return (
        <Box style={{position: 'relative'}}>
          <Box className={styles.imageWrapper}>
            <Box
              component="img"
              src={anyRestourant}
              className={styles.basicImage}
              style={imageSize}
            />
            <Box component="img" src={spot} className={styles.spot} />
          </Box>

          {isHovered && showRate && (
            <Box className={styles.rate}>
              <RestourantRate
                title={title}
                amount={data.amount}
                percent={data.percent}
              />
            </Box>
          )}
        </Box>
      );
    }
  };

  const renderTitle = () => {
    if (isOwner === true) {
      return (
        <Box>
          <Box className={styles.textBackground}>
            <Typography className={styles.uniqueText} style={textStyles}>
              {t('Your.restourant')}
            </Typography>
          </Box>
          <Box className={styles.backgroundBorder} style={backgroundStyles} />
        </Box>
      );
    } else if (isOwner === false) {
      return (
        <Typography className={styles.basicText} style={textStyles}>
          {title}
        </Typography>
      );
    }
  };

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box className={styles.container}>
        {renderImage()}
        {renderTitle()}
      </Box>
    </Box>
  );
};

export default PlaceMarker;
