import {MembersPermissions} from 'environment/types';
import {CategoriesOfTags} from 'environment/types/api/shared';

type FoodTruck = {
  type: 'food-truck';
  checked: 'stationary' | 'move-around';
};

type MultipleLocations = {
  type: 'multiple-locations';
  checked: 'food-trucks' | 'fixed-locations';
};

type OneLocation = {
  type: 'one-location';
  checked: '';
};

export type NoBusinessType = {
  type: '';
  checked: '';
};

export type FormBusinessType = FoodTruck | OneLocation | MultipleLocations;

export const businessType = {
  type: '',
  checked: '',
} as FormBusinessType | NoBusinessType;

export const businessDetails = {
  country: '',
  companyName: '',
  websiteUrl: '',
  street: '',
  number: '',
  city: '',
  state: '',
  zipcode: '',
  phone: '',
};

export const tags = {
  cuisineLabel: '',
  vibeAndServiceLabel: '',
  ambianceLabel: '',
  farmProductsLabel: '',
  freshMeatLabel: '',
  specialsLabel: '',
  spicyFoodsLabel: '',
  kidsAreaLabels: '',
  freshSeafoodLabel: '',
  herbalLabel: '',
  coffeeLabel: '',
  dietOptionsLabel: '',
  healthDrinksLabel: '',
  wineLabel: '',
  beerLabel: '',
  treatsLabels: '',
  familyOwnedLabel: '',
  dogFriendlyLabel: '',
} satisfies CategoriesOfTags;

export const questions = Array(3).fill({title: '', content: ''}) as Array<{
  title: string;
  content: string;
}>;

export type FormQuestions = typeof questions;

export const members = Array(2).fill({fullName: '', email: ''}) as Array<{
  fullName: string;
  email: string;
}>;

export type FormMember = {
  fullName: string;
  email: string;
  permission: MembersPermissions | '';
};

export const member = {
  fullName: '',
  email: '',
  permission: '',
} satisfies FormMember;
