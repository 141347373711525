import {Box, Typography} from '@mui/material';

import styles from './RestourantRate.module.scss';
import {roundNumberToDecimal} from 'environment/utils/roundNumberToDecimal';

interface IProps {
  title: string | undefined;
  percent: number;
  amount: number;
}

const RestourantRate = ({title, percent, amount}: IProps) => {
  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{title}</Typography>

      <Box className={styles.statsBlock}>
        <Typography variant="text-sm">
          {roundNumberToDecimal(amount)}
        </Typography>
        <Box className={styles.percentBlock}>
          <Box
            className={`${styles.triangle} ${
              percent < 0 ? styles.negative : ''
            }`}
          />
          <Typography
            className={`${styles.percent} ${
              percent < 0 ? styles.negative : ''
            }`}>
            {percent < 0 ? percent * -1 : percent}%
          </Typography>
        </Box>
      </Box>
      <Box className={styles.backgroundBorder} />
    </Box>
  );
};

export default RestourantRate;
