import {IReview, IReviewProcessed} from 'types';
import {getMediaById} from 'environment/api/services/media';
import {getUserById} from 'environment/api/services/user';
import {getPersonalUserInfoById} from 'environment/api/services/user';
import {getAllReviewReplies} from 'environment/api/services/reviewReply';

export const processReviewData = async (
  review: IReview,
): Promise<IReviewProcessed> => {
  try {
    // Process user's personal info
    if (review.user) {
      const {data} = await getPersonalUserInfoById(review.user.id);

      if (data) {
        review.user.about = data.about;
      }
    }

    if (review.id) {
      const {data} = await getAllReviewReplies(review.id, 1);

      if (!!data?.length) {
        review.reply = data[0];
      }
    }

    if (review.user) {
      const user = await getUserById(review.user.id);
      review.user.engagersCount = user.data.engagersCount;
      review.user.engagingsCount = user.data.engagersCount;
    }
    // Process user's avatar
    if (review.user) {
      const user = await getUserById(review.user.id);

      const avatarId = user.data.avatar?.mediaId;

      if (avatarId) {
        const {data} = await getMediaById(avatarId);

        if (data.body && !Array.isArray(data.body)) {
          const avatarUrl = data.body.url;
          review.user.avatar = avatarUrl;
        }
      }
    }

    // Process media items
    if (review.media) {
      await Promise.all(
        review.media.map(async (mediaItem) => {
          if (mediaItem.id) {
            const {data} = await getMediaById(mediaItem.id);

            if (!Array.isArray(data?.body) && data?.body?.url) {
              mediaItem.url = data.body.url;
            }
          }
        }),
      );
    }
  } catch (error) {
    console.error('Error processing review data:', error);
  }

  return review;
};
