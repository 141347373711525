import {daysNames} from 'environment/constants/dayNames';
import type {Schedule} from 'environment/types';
import type {
  SignUpBusinessScheduleRequestBody,
  SignUpProgress,
} from 'environment/types/api/auth';
import type {
  BusinessMember,
  BusinessType,
  CategoriesOfTags,
  NullableCategoriesOfTags,
  PartialOperationHour,
  PartialQuestion,
  Question,
} from 'environment/types/api/shared';
import moment from 'moment';
import {FormBusinessType, FormQuestions} from './default-values';
import {SignUpBusinessFormData} from './useSignUpBusinessForm';

export const MOMENT_FROM = moment('2022-04-17T09:00').toISOString();
export const MOMENT_TO = moment('2022-04-17T20:00').toISOString();

export function mapDaysToSchedule(): Schedule {
  let schedule = {} as Schedule;

  for (const dayName of daysNames) {
    schedule[dayName] = {
      from: MOMENT_FROM,
      to: MOMENT_TO,
      closed: false,
    };
  }

  return schedule;
}

export const castTagsForFrontend = (
  tags: NullableCategoriesOfTags | null,
): Required<CategoriesOfTags> => {
  const result = {} as Required<CategoriesOfTags>;

  if (!tags) {
    return result;
  }

  const entries = Object.entries(tags) as [
    keyof NullableCategoriesOfTags,
    string | null,
  ][];

  for (const [category, tag] of entries) {
    if (tag && (category.endsWith('Label') || category.endsWith('Labels'))) {
      result[category] = tag;
    } else {
      result[category] = '';
    }
  }

  return result;
};

export const castTagsForBackend = (
  tags: CategoriesOfTags,
): NullableCategoriesOfTags => {
  const result = {} as NullableCategoriesOfTags;
  const entries = Object.entries(tags) as [keyof CategoriesOfTags, string][];

  for (const [category, tag] of entries) {
    if (category.endsWith('Label') || category.endsWith('Labels')) {
      if (tag) {
        result[category] = tag;
      } else {
        result[category] = null;
      }
    }
  }

  return result;
};

export const castScheduleForFrontend = (
  operationHours: SignUpProgress['operationHours'],
): Schedule => {
  const result = {} as Schedule;

  for (const {dayOfWeek, openTime, closeTime} of operationHours) {
    if (!openTime || !closeTime) {
      result[dayOfWeek] = {
        from: MOMENT_FROM,
        to: MOMENT_TO,
        closed: true,
      };
    } else {
      result[dayOfWeek] = {
        from: openTime,
        to: closeTime,
        closed: false,
      };
    }
  }

  return result;
};

export const castScheduleForBackend = (
  schedule: Schedule,
): SignUpBusinessScheduleRequestBody['operationHours'] => {
  type ScheduleEntries = [keyof Schedule, Schedule[keyof Schedule]][];

  return (
    Object.entries(schedule) as ScheduleEntries
  ).map<PartialOperationHour>(([dayOfWeek, {from, to, closed}]) =>
    closed
      ? {
          dayOfWeek,
          openTime: null,
          closeTime: null,
        }
      : {
          dayOfWeek,
          openTime: from,
          closeTime: to,
        },
  );
};

export const castBusinessTypeForFrontend = (
  type: BusinessType,
): FormBusinessType => {
  switch (type) {
    case 'one_location': {
      return {
        type: 'one-location',
        checked: '',
      };
    }
    case 'food_truck_stationary': {
      return {
        type: 'food-truck',
        checked: 'stationary',
      };
    }
    case 'food_truck_moving': {
      return {
        type: 'food-truck',
        checked: 'move-around',
      };
    }
    case 'multi_location_fixed_locations': {
      return {
        type: 'multiple-locations',
        checked: 'fixed-locations',
      };
    }
    case 'multi_location_food_trucks': {
      return {
        type: 'multiple-locations',
        checked: 'food-trucks',
      };
    }
  }
};

export const castBusinessTypeForBackend = ({
  type,
  checked,
}: FormBusinessType): BusinessType => {
  switch (type) {
    case 'one-location': {
      return 'one_location';
    }
    case 'food-truck': {
      return checked === 'stationary'
        ? 'food_truck_stationary'
        : 'food_truck_moving';
    }
    case 'multiple-locations': {
      return checked === 'food-trucks'
        ? 'multi_location_food_trucks'
        : 'multi_location_fixed_locations';
    }
  }
};

export const castQuestionsForFrontend = (
  questions: Question[],
): FormQuestions => {
  return questions.map(({content, questionTemplate: {category, title}}) => ({
    title: `${category}:${title}`,
    content,
  }));
};

export const castQuestionsForBackend = (
  questions: FormQuestions,
): PartialQuestion[] => {
  return questions.map(({title, content}) => {
    const [questionTemplateCategory, titleInCategory] = title.split(':');
    return {
      titleInCategory,
      questionTemplateCategory,
      content: content.trim(),
    };
  });
};

export const castSignUpMembersForFrontend = (
  members: BusinessMember[],
): SignUpBusinessFormData['members'] => {
  const result = members.map(({fullName, email}) => ({fullName, email}));

  if (result.length === 1) result.push({fullName: '', email: ''});

  return result;
};

export const castSignUpMembersForBackend = (
  members: SignUpBusinessFormData['members'],
): SignUpBusinessFormData['members'] => {
  return members
    .filter(({email, fullName}) => email && fullName)
    .map(({email, fullName}) => ({
      email: email.trim(),
      fullName: fullName.trim(),
    }));
};
