import {Box} from '@mui/material';
import {GradientIcon} from 'components/molecules';
import StoryWaterfall from '../../storyWaterfall/StoryWaterfall';
import styles from './ActiveSlide.module.scss';

interface IProps {
  isAlone: boolean;
  waterfallId: string;
  mediaSrc?: string | null;
  mediaType?: string | null;
  userAvatar?: string;
  firstName: string;
  lastName: string;
  userRating: number;
  placeImage: string;
  country: string;
  placeName: string;
  caption: string;
  likes: number;
  dislikes: number;
  comments: number;
  views: number;
  shares: number;
  reply?: string;
  handleNext: () => void;
  handlePrev: () => void;
  sendReply: (waterfallId: string, commentText: string) => void;
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void;
}

const ActiveSlide = ({
  waterfallId,
  mediaSrc,
  mediaType,
  userAvatar,
  firstName,
  lastName,
  userRating,
  placeImage,
  country,
  placeName,
  caption,
  likes,
  dislikes,
  comments,
  views,
  shares,
  handleNext,
  handlePrev,
  sendReply,
  isAlone,
  reply,
  updateWaterfallReply,
}: IProps) => {
  const isVideo = mediaType === 'video' ? true : false;

  return (
    <Box style={{display: 'flex', gap: '16px'}}>
      {!isAlone && (
        <Box className={styles.button} onClick={() => handleNext()}>
          <GradientIcon name="arrow-circle-left" size="40px" />
        </Box>
      )}
      <StoryWaterfall
        waterfallId={waterfallId}
        mediaUrl={mediaSrc}
        isVideo={isVideo}
        userAvatar={userAvatar}
        likes={likes}
        dislikes={dislikes}
        comments={comments}
        views={views}
        shares={shares}
        firstName={firstName}
        lastName={lastName}
        userRating={userRating}
        placeImage={placeImage}
        placeName={placeName}
        country={country}
        caption={caption}
        sendReply={sendReply}
        reply={reply}
        updateWaterfallReply={updateWaterfallReply}
      />
      <Box className={styles.button} onClick={() => handlePrev()}>
        {!isAlone && <GradientIcon name="arrow-circle-right" size="40px" />}
      </Box>
    </Box>
  );
};

export default ActiveSlide;
