import {Box} from '@mui/material';
import loaderSrc from 'assets/loader.gif';
import {useTranslation} from 'react-i18next';
import styles from './LoaderOverlay.module.scss';

const LoaderOverlay = () => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <img src={loaderSrc} alt={t('Loading...')} />
    </Box>
  );
};

export default LoaderOverlay;
