import {
  CreateBusinessUnitRequestBody,
  IBusinessUnit,
  UpdateBusinessUnitLocationRequestBody,
  UpdateBusinessUnitRequestBody,
} from 'environment/types/business';
import axios from '../axios';
import {BUSINESS_UNITS_URL} from 'environment/constants/url';
import {MyUnitsResponseBody} from 'environment/types/api/business';

export const getBusinessUnits = () => {
  return axios.get<MyUnitsResponseBody>(BUSINESS_UNITS_URL.BUSINESS_UNITS_MINE);
};

export const updateBusinessUnit = (
  id: string,
  body: UpdateBusinessUnitRequestBody,
) => {
  return axios.patch<IBusinessUnit>(
    BUSINESS_UNITS_URL.BUSINESS_UNIT_UPDATE(id),
    body,
  );
};

export const updateBusinessUnitLocation = (
  id: string,
  body: UpdateBusinessUnitLocationRequestBody,
) => {
  return axios.put<IBusinessUnit>(
    BUSINESS_UNITS_URL.BUSINESS_UNIT_UPDATE_LOCATION(id),
    body,
  );
};

export const createBusinessUnit = (body: CreateBusinessUnitRequestBody) => {
  return axios.post<IBusinessUnit>(
    BUSINESS_UNITS_URL.BUSINESS_UNIT_CREATE,
    body,
  );
};

export const updateBusinessUnitAvatar = (
  businessUnitId: string,
  body: {mediaId: string},
) => {
  return axios.put(
    BUSINESS_UNITS_URL.BUSINESS_UNIT_UPDATE_AVATAR(businessUnitId),
    body,
  );
};

export const deleteBusinessUnitAvatar = (businessUnitId: string) => {
  return axios.delete(
    BUSINESS_UNITS_URL.BUSINESS_UNIT_UPDATE_AVATAR(businessUnitId),
  );
};
