import ReceiptImage from 'components/atoms/receiptImage/ReceiptImage';
import {Controller, useFormContext} from 'react-hook-form';

interface IProps {
  fieldName: string;
  imageSize?: number;
}

const ReceiptsList = ({fieldName, imageSize}: IProps) => {
  const formContext = useFormContext();

  /* Controller is being used as a method to subscribe on the react-hook-form state */

  return (
    <Controller
      name={fieldName}
      control={formContext.control}
      render={() => {
        const images = formContext.getValues(fieldName) as File[];

        const handleClearButtonClick = (index: number) => {
          const filteredImages = images.filter((_, idx) => index !== idx);
          formContext.setValue(fieldName, filteredImages);
        };

        return (
          <>
            {images.map((imageFile, index) => (
              <ReceiptImage
                key={imageFile.name}
                file={imageFile}
                onClearButtonClick={() => handleClearButtonClick(index)}
                size={imageSize}
              />
            ))}
          </>
        );
      }}
    />
  );
};

export default ReceiptsList;
