import {t} from 'i18next';

export const RANGE_BUTTONS = [
  {
    value: 'week',
    label: t('Week'),
  },
  {
    value: 'month',
    label: t('Month'),
  },
];
