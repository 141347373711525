import {Box} from '@mui/material';
import {Tabs} from 'components/molecules';
import {Activity, PageVisitTab, StatisticsInCharts} from 'components/organisms';
import {createTab} from 'environment/utils/createTab';
import {useTranslation} from 'react-i18next';
import {IEngagementCharts, IPageVisitData} from 'store/models/engagement';
import {countDataInHoursRange} from 'environment/utils/countDataInHoursRange';
import {Analytics} from 'types/business-unit-analytics';

interface IProps {
  analytics: Analytics;
  onTabChange: (index: number) => void;
  selectedIndex: number;
  engagementData: IEngagementCharts;
  pageVisitData: IPageVisitData;
  currentUnitCreatedAt: string;
  getEngagementPageVisitData: (
    businessUnitId: string,
    value: string,
    startDate: string,
    endDate: string,
  ) => void;
}

const EngagementTemplate = ({
  analytics,
  onTabChange,
  selectedIndex,
  engagementData,
  pageVisitData,
  currentUnitCreatedAt,
  getEngagementPageVisitData,
}: IProps) => {
  const {t} = useTranslation();

  const tabs = [
    createTab({
      label: t('Review'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Reviews.as.of.today')}
          nameToFirstChart={t('Total.Page.Review')}
          dayData={countDataInHoursRange(engagementData.review.dayData)}
          monthsData={engagementData.review.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.reviewed.your.business'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Likes'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Likes.as.of.today')}
          nameToFirstChart={t('Total.Page.Likes')}
          dayData={countDataInHoursRange(engagementData.like.dayData)}
          monthsData={engagementData.like.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.liked.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Comments'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Comments.as.of.today')}
          nameToFirstChart={t('Total.Page.Comments')}
          dayData={countDataInHoursRange(engagementData.comment.dayData)}
          monthsData={engagementData.comment.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.commented.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Share'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Share.as.of.today')}
          nameToFirstChart={t('Total.Page.Share')}
          dayData={countDataInHoursRange(engagementData.share.dayData)}
          monthsData={engagementData.share.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.shared.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('View'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Views.as.of.today')}
          nameToFirstChart={t('Total.Views')}
          dayData={countDataInHoursRange(engagementData.view.dayData)}
          monthsData={engagementData.view.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.viewed.your.content'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Waterfall.tag'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Waterfall.tags.as.of.today')}
          nameToFirstChart={t('Total.Page.Waterfall.tags')}
          dayData={countDataInHoursRange(engagementData.waterfallTag.dayData)}
          monthsData={engagementData.waterfallTag.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={
            'People.who.tagged.your.business.in.Waterfall'
          }
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Forum.tag'),
      component: (
        <StatisticsInCharts
          titleToFirstChart={t('Total.Page.Forum.tags.as.of.today')}
          nameToFirstChart={t('Total.Page.Forum.tags')}
          dayData={countDataInHoursRange(engagementData.forumTag.dayData)}
          monthsData={engagementData.forumTag.monthData}
          currentUnitCreatedAt={currentUnitCreatedAt}
          titleForDemographicBlock={'People.who.tagged.your.business.in.forum'}
          // TODO replace with actual data
          dataByAge={pageVisitData.dataByAge}
          userStatus={pageVisitData.userStatus}
        />
      ),
    }),
    createTab({
      label: t('Page.visit'),
      component: (
        <PageVisitTab
          data={pageVisitData}
          getEngagementPageVisitData={getEngagementPageVisitData}
        />
      ),
    }),
  ];
  return (
    <Box sx={{pt: '32px'}}>
      <Activity title={t('Engagement')} analytics={analytics} />
      <Tabs
        tabs={tabs}
        onTabChange={onTabChange}
        selectedIndex={selectedIndex}
      />
    </Box>
  );
};

export default EngagementTemplate;
