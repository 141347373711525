import {Box} from '@mui/material';
import EngagementItem from '../EngagementItem/EngagementItem';
import styles from './EngagementRow.module.scss';

interface IProps {
  likes: number;
  dislikes: number;
  comments: number;
  views: number;
  shares: number;
}

const EngagementRow = ({likes, dislikes, comments, views, shares}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <EngagementItem name="like" amount={likes} />
      <EngagementItem name="dislike" amount={dislikes} />
      <EngagementItem name="comment" amount={comments} />
      <EngagementItem name="view" amount={views} />
      <EngagementItem name="share" amount={shares} />
    </Box>
  );
};

export default EngagementRow;
