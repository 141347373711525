import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return config;
  },
  error => Promise.reject(error),
);

export default instance;
