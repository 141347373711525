import {COUNTRIES_WITH_NO_ZIPCODE} from 'environment/constants/countries-with-no-zipcode';
import {daysNames} from 'environment/constants/dayNames';
import {DayName} from 'environment/types';
import {t} from 'i18next';
import * as yup from 'yup';

const NAME_REGEX = /^[' A-Za-z_-]+$/i;
const URL_REGEX = /^(https?:\/\/)?[\w.-]+\.\w{2,}(\/\S*)?$/;
const PHONE_NUMBER_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/i;

const FIRST_NAME_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.firstName)'}))
  .min(2, t('validation.minName', {number: 2}))
  .max(32, t('validation.maxName', {number: 32}))
  .matches(NAME_REGEX, t('validation.latinOnly'));

const LAST_NAME_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.lastName)'}))
  .min(2, t('validation.minName', {number: 2}))
  .max(32, t('validation.maxName', {number: 32}))
  .matches(NAME_REGEX, t('validation.latinOnly'));

const REQUIRED_FULL_NAME_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.fullName)'}))
  .min(2, t('validation.minName', {number: 2}))
  .max(32, t('validation.maxName', {number: 32}))
  .matches(NAME_REGEX, t('validation.latinOnly'))
  .trim();

const OPTIONAL_FULL_NAME_FIELD = yup
  .string()
  .matches(NAME_REGEX, {
    message: t('validation.latinOnly'),
    excludeEmptyString: true,
  })
  .matches(/.{2,}/, {
    message: t('validation.minName', {number: 2}),
    excludeEmptyString: true,
  })
  .max(32, t('validation.maxName', {number: 32}));

export const REQUIRED_EMAIL_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.email)'}))
  .matches(EMAIL_REGEX, t('validation.email'));

const OPTIONAL_EMAIL_FIELD = yup.string().matches(EMAIL_REGEX, {
  message: t('validation.email'),
  excludeEmptyString: true,
});

export const PASSWORD_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.password)'}))
  .min(8, t('validation.passwordMinLength', {number: 8}))
  .max(36, t('validation.passwordMaxLength', {number: 36}))
  .matches(/[0-9]/, t('validation.passwordOneNumber'))
  .matches(/[a-z]/, t('validation.passwordOneLowercase'))
  .matches(/[A-Z]/, t('validation.passwordOneUppercase'))
  .matches(/[\W_]/, t('validation.passwordOneSpecialChar'));

export const CONFIRM_PASSWORD_FIELD = (passwordFieldName: string) => {
  return yup
    .string()
    .required(
      t('validation.requiredField', {field: '$t(fields.confirmPassword)'}),
    )
    .oneOf([yup.ref(passwordFieldName)], t('validation.passwordsMatch'));
};

const URL_FIELD = yup.string().matches(URL_REGEX, {
  message: t('validation.url'),
  excludeEmptyString: true,
});

const PHONE_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.phone)'}))
  .matches(PHONE_NUMBER_REGEX, {
    message: t('validation.phone'),
  });

const ZIPCODE_FIELD = yup.string().when('country', ([country]) => {
  return COUNTRIES_WITH_NO_ZIPCODE.includes(country?.trim())
    ? yup.string()
    : yup
        .string()
        .required(t('validation.requiredField', {field: '$t(fields.zipcode)'}));
});

const COUNTRY_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.country)'}));

const COMPANY_NAME_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.companyName)'}));

const STREET_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.street)'}));

const APT_FIELD = yup.string();

const CITY_FIELD = yup
  .string()
  .required(t('validation.requiredField', {field: '$t(fields.city)'}));

const STATE_FIELD = yup.string();

const ONE_SCHEDULE_DAY = yup.object({
  from: yup.string().required(),
  to: yup.string().required(),
  closed: yup.boolean().required(),
});

type ScheduleSchema = Record<DayName, typeof ONE_SCHEDULE_DAY>;

function generateScheduleValidationSchema(): ScheduleSchema {
  const schema = {} as Partial<ScheduleSchema>;

  for (const dayName of daysNames) {
    schema[dayName] = ONE_SCHEDULE_DAY;
  }

  return schema as ScheduleSchema;
}

export const SCHEDULE_OBJECT = yup.object(generateScheduleValidationSchema());

export const BUSINESS_UNIT_DETAILS_OBJECT = yup.object({
  country: COUNTRY_FIELD,
  companyName: COMPANY_NAME_FIELD,
  websiteUrl: URL_FIELD,
  street: STREET_FIELD,
  number: APT_FIELD,
  city: CITY_FIELD,
  state: STATE_FIELD,
  zipcode: ZIPCODE_FIELD,
  phone: PHONE_FIELD,
});

const BUSINESS_OBJECT = yup.object({
  firstName: FIRST_NAME_FIELD,
  lastName: LAST_NAME_FIELD,
  websiteUrl: URL_FIELD,
});

export const BUSINESS_DETAILS_OBJECT =
  BUSINESS_UNIT_DETAILS_OBJECT.concat(BUSINESS_OBJECT);

export const QUESTIONS_OBJECT = yup
  .array()
  .of(
    yup.object({
      title: yup.string().required(t('validation.questionTitle')),
      content: yup.string().required(t('validation.questionContent')),
    }),
  )
  .required();

const SIGNUP_MEMBER_OBJECT = yup.object().shape(
  {
    email: yup.string().when('fullName', ([fullName]) => {
      return !fullName?.trim().length
        ? OPTIONAL_EMAIL_FIELD
        : REQUIRED_EMAIL_FIELD;
    }),
    fullName: yup.string().when('email', ([email]) => {
      return !email?.trim().length
        ? OPTIONAL_FULL_NAME_FIELD
        : REQUIRED_FULL_NAME_FIELD;
    }),
  },
  [['email', 'fullName']],
);

export const SIGNUP_MEMBERS_ARRAY = yup
  .array()
  .of(SIGNUP_MEMBER_OBJECT)
  .required()
  .test('is-unique', t('validation.membersEmailsUnique'), function (members) {
    const emails = [] as string[];
    for (const member of members) {
      if (member.email && emails.includes(member.email)) {
        return false;
      }
      member.email && emails.push(member.email);
    }
    return true;
  });

export const MEMBER_OBJECT = yup.object({
  fullName: REQUIRED_FULL_NAME_FIELD,
  email: REQUIRED_EMAIL_FIELD,
  permission: yup
    .string()
    .required(t('Please.set.permissions.for.the.member'))
    .oneOf(['VRO', 'FA', 'ADMIN'], t('Set.permissions')),
});
