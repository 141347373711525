import i18n from 'environment/plugins/i18n';

export const RANGE_BUTTONS = [
  {
    value: '24h',
    label: i18n.t('24.hours'),
  },
  {
    value: '72h',
    label: i18n.t('72.hours'),
  },
  {
    value: 'week',
    label: i18n.t('Week'),
  },
  {
    value: 'month',
    label: i18n.t('Month'),
  },
] as const;

export type RangeButtonsValues = (typeof RANGE_BUTTONS)[number]['value'];
