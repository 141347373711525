import HighchartsReact from 'highcharts-react-official';
import {useRef} from 'react';

import {
  barChartData,
  basicAreaData,
  columnChartData,
  donutChartRaceData,
  pieChartData,
} from 'environment/configs/mockData';
import styles from './Charts.module.scss';
import {
  BarChart,
  BasicArea,
  ColumnChart,
  DonutChart,
  PatternArea,
  PieChart,
  SpeedometerProgress,
  ViewOnlyGraph,
} from 'components/molecules';

function Charts() {
  const chartRef2 = useRef<HighchartsReact.RefObject>(null);
  const getData = () => {
    return Object.entries(donutChartRaceData)
      .slice(1)
      .map(([key, range]: any) => {
        return [key, Number(range['24hours'])];
      });
  };

  return (
    <div className={styles.appContainer}>
      <DonutChart
        data={getData()}
        colors={['#00ff48', '#065c00', '#04a030', '#07901e', '#BCE29E']}
        height={220}
        subtitle="Total activity"
      />
      <DonutChart
        data={getData()}
        rounded={true}
        colors={['#32FFFF', '#B8FE61', '#FF482C', '#9C4AEE', '#000dff']}
        height={180}
        subtitle="Total users"
      />
      <DonutChart
        data={getData()}
        rounded={true}
        colors={['#32FFFF', '#B8FE61', '#FF482C', '#9C4AEE', '#000dff']}
        height={104}
        size="small"
      />
      <PieChart
        data={pieChartData}
        colors={['#54BE2F', '#8EE071', '#2E6719']}
        name="72"
        size={208}
      />
      <div className={styles.highchartsContainer}>
        <BarChart
          data={barChartData}
          categories={[
            'Likes',
            'Comments',
            'Share',
            'Review',
            'View',
            'Story tag',
            'Forum tag',
            'Page visit',
          ]}
        />
      </div>
      <div className={styles.highchartsContainer}>
        <ColumnChart data={columnChartData} />
      </div>
      <div className={styles.highchartsContainer}>
        <PatternArea data={basicAreaData[0].data} />
      </div>
      <div className={styles.wireframesChart}>
        <BasicArea
          data={basicAreaData}
          colors={['#B8FE61']}
          gradient={[
            [0, 'rgba(255, 249, 97, 0.31)'],
            [1, 'rgba(52, 211, 153, 0)'],
          ]}
          title="Total Page Likes as of today: 2.466"
          chartRef={chartRef2}
          legend={false}
          grid={true}
          toggle={true}
        />
      </div>
      <div className={styles.viewOnlyGraphFlex}>
        {[1, 2, 3].map((ite) => {
          return (
            <div key={ite} className={styles.viewOnlyGraph}>
              <ViewOnlyGraph
                type="area"
                data={basicAreaData[0].data}
                colors={['#b8fe6166']}
                gradient={[
                  [0, '#fff9614f'],
                  [1, 'rgba(52, 211, 153, 0)'],
                ]}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.speedometer}>
        <SpeedometerProgress
          value={30}
          backgroundColor="#101010"
          amount={666}
        />
      </div>
    </div>
  );
}

export default Charts;
