import {toSortedTags} from './utils';

const FARM_PRODUCTS = [
  {
    id: 'organic-goodies',
    emoji: 'bg-organicgoodies',
  },
  {
    id: 'local-treasures',
    emoji: 'bg-localtreasures',
  },
  {
    id: 'farm-to-table',
    emoji: 'bg-farmtotable',
  },
  {
    id: 'fresh-harvest',
    emoji: 'bg-freshharvest',
  },
  {
    id: 'sustainable-eats',
    emoji: 'bg-sustainableeats',
  },
  {
    id: 'seasonal-wonders',
    emoji: 'bg-seasonalwonders',
  },
  {
    id: 'artisanal-creations',
    emoji: 'bg-artisanalcreations',
  },
  {
    id: 'homegrown-magic',
    emoji: 'bg-homegrownmagic',
  },
];

export default toSortedTags('farmProductsLabel', FARM_PRODUCTS);
