import {InputLabel, Stack, TextFieldProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Input from '../input/Input';
import styles from './InputWithTopLabel.module.scss';

interface IProps
  extends Omit<TextFieldProps, 'className' | 'error' | 'helperText'> {
  name: string;
  labelTitle: string;
}

export const InputProps = {
  disableUnderline: true,
  className: styles.input,
};

const InputWithTopLabel = ({name, labelTitle, ...rest}: IProps) => {
  const {t} = useTranslation();

  return (
    <Stack spacing={2.5} sx={{flexGrow: 1}}>
      <InputLabel htmlFor={name} variant="filled" className={styles.label}>
        {t(labelTitle)}
      </InputLabel>
      <Input name={name} variant="standard" InputProps={InputProps} {...rest} />
    </Stack>
  );
};

export default InputWithTopLabel;
