import {ADD_NEW_BUSINESS_SCHEMA} from 'environment/validation/resolvers';
import {useForm, UseFormReturn} from 'react-hook-form';
import {
  businessDetails,
  businessType,
  member,
  questions,
  tags,
} from './default-values';
import {mapDaysToSchedule} from './helpers';

const defaultValues = {
  businessType,
  businessDetails,
  schedule: mapDaysToSchedule(),
  tags,
  questions,
  member,
  receipts: [] as File[],
};

export type NewBusinessFormData = typeof defaultValues;

export const useNewBusinessForm = () => {
  return useForm({
    defaultValues,
    mode: 'all',
    resolver: ADD_NEW_BUSINESS_SCHEMA,
  }) as unknown as UseFormReturn<NewBusinessFormData>;
};
