import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {Popover} from '@mui/material';
import {useState} from 'react';
import styles from './EmojiPicker.module.scss';

interface IProps {
  children: React.ReactNode;
  onEmojiSelect: (e: {native: string}) => void;
  closeOnEmojiSelect?: boolean;
}

const EmojiPicker = ({
  children,
  onEmojiSelect,
  closeOnEmojiSelect = true,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const closePicker = () => setAnchorEl(null);

  const openPicker = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleEmojiSelect = (e: {native: string}) => {
    onEmojiSelect(e);

    if (closeOnEmojiSelect) {
      closePicker();
    }
  };

  return (
    <>
      <div onClick={openPicker}>{children}</div>

      <Popover
        classes={{
          paper: styles.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={closePicker}>
        <Picker
          data={data}
          theme="dark"
          icons="outline"
          navPosition="bottom"
          previewPosition="none"
          skinTonePosition="search"
          onEmojiSelect={handleEmojiSelect}
        />
      </Popover>
    </>
  );
};

export default EmojiPicker;
