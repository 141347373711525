import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from 'environment/translations/en.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  returnNull: false,
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
});

export default i18n;
