export const generateMonthsString = (months: number[]) => {
  if (months?.length === 1) {
    const previousMonth = months && months[0] - 1;
    months.unshift(previousMonth);
  }

  const monthsString = months && months.join('&months=');

  return monthsString;
};
