import i18n from 'environment/plugins/i18n';

export const monthsItem = [
  {value: '0', title: i18n.t('January')},
  {value: '1', title: i18n.t('February')},
  {value: '2', title: i18n.t('March')},
  {value: '3', title: i18n.t('April')},
  {value: '4', title: i18n.t('May')},
  {value: '5', title: i18n.t('June')},
  {value: '6', title: i18n.t('July')},
  {value: '7', title: i18n.t('August')},
  {value: '8', title: i18n.t('September')},
  {value: '9', title: i18n.t('October')},
  {value: '10', title: i18n.t('November')},
  {value: '11', title: i18n.t('December')},
];
