import {socket} from 'environment/api/socket';
import {EVENTS} from 'environment/constants/socket';
import AppRouter from 'environment/routes/routes';
import * as Highcharts from 'highcharts';
import useUnits from 'hooks/useUnits';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, RootState} from 'store';
import {Analytics} from 'types/business-unit-analytics';

//set global font-family for charts
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Poppins', sans-serif",
    },
  },
});

const App = () => {
  const dispatch = useDispatch<Dispatch>();

  const {currentUnit} = useUnits();
  const {period} = useSelector((state: RootState) => state.recentActivity);

  const onConnect = useCallback(() => {
    socket.emit(EVENTS.BUSINESS_UNIT_ANALYTICS.JOIN_RECENT_ACTIVITY, {
      period,
      businessUnitId: currentUnit.id,
    });

    socket.on(
      EVENTS.BUSINESS_UNIT_ANALYTICS.RECEIVE_RECENT_ACTIVITY,
      (data: Analytics) => dispatch.recentActivity.setAnalytics(data),
    );
  }, [currentUnit.id, dispatch.recentActivity, period]);

  useEffect(() => {
    if (currentUnit.id && period) {
      onConnect();
      socket.on('connect', onConnect);
    }

    return () => {
      if (currentUnit.id && period) {
        socket.emit(EVENTS.BUSINESS_UNIT_ANALYTICS.LEAVE_RECENT_ACTIVITY, {
          period,
          businessUnitId: currentUnit.id,
        });

        socket.off('connect', onConnect);
      }
    };
  }, [currentUnit.id, dispatch.recentActivity, onConnect, period]);

  return <AppRouter />;
};

export default App;
