import {Box, Button, Typography} from '@mui/material';
import {InputWithTopLabel} from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import {
  AddPaymentMethodFormData,
  useAddPaymentMethodForm,
} from 'hooks/forms/useAddPaymentMethodForm';
import {FormProvider, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './AddPaymentMethodModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const AddPaymentMethodModal = ({open, onClose}: IProps) => {
  const {t} = useTranslation();

  const form = useAddPaymentMethodForm();
  const {isValid} = form.formState;

  const onSubmit: SubmitHandler<AddPaymentMethodFormData> = (data) => {
    console.log(data);
    onClose();
  };

  return (
    <SettingsModal
      title={t('Add.payment.methods')}
      open={open}
      onClose={onClose}
      withCloseButton>
      <FormProvider {...form}>
        <form className={styles.wrapper} onSubmit={form.handleSubmit(onSubmit)}>
          <Box className={styles.container}>
            <InputWithTopLabel
              name="nameOnCard"
              labelTitle={t('Name.on.card')}
              autoComplete="cc-name"
            />
            <InputWithTopLabel
              name="cardNumber"
              labelTitle={t('Card.number')}
              autoComplete="cc-number"
            />
            <Box className={styles.row}>
              <InputWithTopLabel
                name="expiryDate"
                labelTitle={t('Expiry.code')}
                autoComplete="cc-exp"
              />
              <InputWithTopLabel
                name="securityCode"
                labelTitle={t('Security.code')}
                autoComplete="cc-csc"
              />
            </Box>
          </Box>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={!isValid}
            className={styles.submitButton}>
            <Typography
              color={Colors['default-gray-900']}
              variant="text-base-semibold">
              {t('Add.card')}
            </Typography>
          </Button>
        </form>
      </FormProvider>
    </SettingsModal>
  );
};

export default AddPaymentMethodModal;
