import {
  Box,
  Divider,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import {QUESTIONS} from 'environment/constants/questions';
import {FormQuestions} from 'hooks/forms/default-values';
import {type FC} from 'react';
import {
  Controller,
  useFormContext,
  useWatch,
  type FieldError,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import IconMark from '../icon/IconMark';
import styles from './QuestionTextarea.module.scss';

interface IProps {
  name: string;
}

const MAX_CHARACTERS = 80;

const Wrapper: FC<IProps> = ({name}: IProps) => {
  const {getFieldState} = useFormContext();
  const {error} = getFieldState(name);

  const err = error as Partial<Record<'title' | 'content', FieldError>>;

  return (
    <>
      <Box className={styles.wrapper}>
        <Box className={styles.container}>
          <QuestionSelect name={name} />

          <Divider
            orientation="horizontal"
            sx={{marginY: '10px', background: Colors['default-gray-600']}}
          />

          <QuestionTextarea name={name} />
        </Box>
      </Box>

      {err?.title?.message && (
        <Typography color={Colors['profile-red']} variant="text-sm-lighter">
          {err.title.message}
        </Typography>
      )}
      {err?.content?.message && (
        <Typography color={Colors['profile-red']} variant="text-sm-lighter">
          {err.content.message}
        </Typography>
      )}
    </>
  );
};

const QuestionTextarea: FC<IProps> = ({name}) => {
  const {t} = useTranslation();

  const {control} = useFormContext();

  return (
    <Controller
      name={`${name}.content`}
      control={control}
      render={({field: {value, onChange, ...field}}) => (
        <>
          <TextareaAutosize
            minRows={2}
            placeholder={t('Add.your.reply!')}
            className={styles.textarea}
            value={value}
            onChange={({target: {value}}) =>
              value.length <= MAX_CHARACTERS ? onChange(value) : null
            }
            {...field}
          />
          <Typography
            className={styles.characters}
            variant="text-sm-light"
            color={Colors['default-gray-300']}>
            {`${value.length}/${MAX_CHARACTERS} characters`}
          </Typography>
        </>
      )}
    />
  );
};

const QuestionSelect: FC<IProps> = ({name}) => {
  const {t} = useTranslation();

  const {control} = useFormContext();
  const selectedQuestions = useWatch({
    control,
    name: 'questions',
  }) as FormQuestions;

  return (
    <Controller
      name={`${name}.title`}
      control={control}
      render={({field}) => (
        <Box className={styles.selectContainer}>
          {!field.value && (
            <Typography sx={{position: 'absolute'}} variant="text-sm-semibold">
              {t('Choose.question')}
            </Typography>
          )}
          <Select
            {...field}
            renderValue={(selected) =>
              t(`mantrasQuestions.${selected.split(':').pop()}`)
            }
            className={styles.select}
            disableUnderline
            fullWidth
            IconComponent={(props) => {
              const open = props.className.includes('MuiSelect-iconOpen');

              return (
                <IconButton
                  sx={{padding: 0}}
                  className={`${props.className}${
                    open ? ` ${styles.gradient}` : ''
                  }`}>
                  <IconMark
                    name="arrow-down"
                    size="28px"
                    color={Colors['default-gray-200']}
                  />
                </IconButton>
              );
            }}
            MenuProps={{
              slotProps: {
                paper: {className: styles.dropdown},
              },
            }}>
            {QUESTIONS.flatMap(({category, questions, categoryId}) => [
              <ListSubheader
                key={categoryId}
                disableSticky
                sx={{background: 'transparent'}}>
                <Typography variant="text-sm-bold" color={Colors['HEXB8FE61']}>
                  {t(`mantrasCategories.${category}`)}
                </Typography>
              </ListSubheader>,
              questions.map((name) => {
                const categoryWithName = `${categoryId}:${name}`;

                return (
                  <MenuItem
                    key={name}
                    value={categoryWithName}
                    disabled={selectedQuestions.some(
                      ({title}) => title === categoryWithName,
                    )}>
                    <Typography variant="text-sm-light">
                      {t(`mantrasQuestions.${name}`)}
                    </Typography>
                  </MenuItem>
                );
              }),
            ])}
          </Select>
        </Box>
      )}
    />
  );
};

export default Wrapper;
