import {Box, Typography} from '@mui/material';
import {Date, LikesDislikes, Profile, RollScore} from 'components/atoms';
import i18n from 'environment/plugins/i18n';
import {roundNumberToDecimal} from 'environment/utils/roundNumberToDecimal';
import CommentBlock from './commentBlock/CommentBlock';

export const columns = [
  {
    id: 'text',
    label: i18n.t('All.Reviews'),
    sortable: false,
    alignCenter: false,
    renderCell(row: any) {
      const text = row['text'];
      const {media, user, score, textLiked, textDisliked, id, reply} = row;

      return (
        <CommentBlock
          text={text}
          mediaData={media}
          user={user}
          rollScore={score}
          textLiked={textLiked}
          textDisLiked={textDisliked}
          reviewId={id}
          reply={reply}
        />
      );
    },
  },
  {
    id: 'user',
    label: i18n.t('Users'),
    sortable: true,
    alignCenter: false,
    renderCell(row: any) {
      const value = row[this.id];
      return (
        <Box>
          <Profile hideUsername value={value} clickable />
        </Box>
      );
    },
  },
  {
    id: 'date',
    label: i18n.t('Date'),
    sortable: true,
    alignCenter: true,
    renderCell(row: any) {
      const value = row['createdAt'];
      return <Date date={value} />;
    },
  },
  {
    id: 'views',
    label: i18n.t('Views'),
    sortable: true,
    alignCenter: true,
    renderCell(row: any) {
      const value = row['engagement'].watches.count;
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'comments',
    label: i18n.t('Comments'),
    sortable: true,
    alignCenter: true,
    renderCell(row: any) {
      const value = row['engagement'].comments.count;
      return (
        <Typography whiteSpace="nowrap" variant="text-xs">
          {roundNumberToDecimal(value)}
        </Typography>
      );
    },
  },
  {
    id: 'likesDislikes',
    label: i18n.t('Likes'),
    sortable: true,
    alignCenter: true,
    renderCell(row: any) {
      const likes = row['engagement'].likes.count;
      const dislikes = row['engagement'].dislikes.count;
      return <LikesDislikes likes={likes} dislikes={dislikes} />;
    },
  },
  {
    id: 'score',
    label: i18n.t('Roll.score'),
    sortable: true,
    alignCenter: true,
    renderCell(row: any) {
      const value = row[this.id];
      return <RollScore score={value} />;
    },
  },
] as const;
