import {toSortedTags} from './utils';

const TREATS = [
  {
    id: 'donuts',
    emoji: 'bg-donuts',
  },
  {
    id: 'dumplings',
    emoji: 'bg-dumplings',
  },
  {
    id: 'tacos',
    emoji: 'bg-tacos',
  },
  {
    id: 'sushi',
    emoji: 'bg-sushi',
  },
  {
    id: 'pizza',
    emoji: 'bg-pizza',
  },
  {
    id: 'burgers',
    emoji: 'bg-burgers',
  },
  {
    id: 'steaks',
    emoji: 'bg-steaks',
  },
  {
    id: 'charcuterie-boards',
    emoji: 'bg-charcuterieboards',
  },
  {
    id: 'dessert-sampler',
    emoji: 'bg-dessertsampler',
  },
  {
    id: 'cakes',
    emoji: 'bg-cakes',
  },
  {
    id: 'cheesecake',
    emoji: 'bg-cheesecake',
  },
  {
    id: 'layered-cakes',
    emoji: 'bg-layeredcakes',
  },
  {
    id: 'cupcakes',
    emoji: 'bg-cupcakes',
  },
  {
    id: 'specialty-cakes',
    emoji: 'bg-specialtycakes',
  },
  {
    id: 'cake-by-the-slice',
    emoji: 'bg-cakebytheslice',
  },
  {
    id: 'ice-cream',
    emoji: 'bg-icecream',
  },
  {
    id: 'fries',
    emoji: 'bg-fries',
  },
  {
    id: 'pizza-slices',
    emoji: 'bg-pizzaslices',
  },
  {
    id: 'croissant',
    emoji: 'bg-croissant',
  },
  {
    id: 'bagels',
    emoji: 'bg-bagel',
  },
  {
    id: 'pastries',
    emoji: 'bg-pastries',
  },
  {
    id: 'fresh-bread',
    emoji: 'bg-freshbread',
  },
];

export default toSortedTags('treatsLabels', TREATS);
