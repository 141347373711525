interface InputObject {
  [key: string]: number | undefined;
}

export function processObjectByAge(inputObject: InputObject): {
  [key: string]: number;
} {
  const resultObject: {[key: string]: number} = {};

  for (let i = 18; i <= 45; i++) {
    const key = String(i);
    const originalValue = inputObject[key] || 0;

    resultObject[key] = originalValue;
  }

  return resultObject;
}
