import React from 'react';
import {Box} from '@mui/material';
import styles from './Slide.module.scss';

interface IProps {
  mediaSrc?: string | null;
  mediaType?: string | null;
  mediaThumbnail?: string | null;
}

const Slide = ({mediaSrc, mediaThumbnail}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      {mediaThumbnail ? (
        <img className={styles.media} src={mediaThumbnail} alt="waterfall" />
      ) : (
        mediaSrc && (
          <video
            className={`${styles.media} ${styles.video}`}
            src={mediaSrc}
            loop
            muted
            style={{borderRadius: '10px'}}
          />
        )
      )}
    </Box>
  );
};

export default Slide;
