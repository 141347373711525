import {Box, Button, Typography} from '@mui/material';
import {FilledCheckbox, Input, ServerErrorMessage} from 'components/atoms';
import {type FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './SignUpMainForm.module.scss';

interface IProps extends IAgreementsLabelProps {
  onSubmit: () => void;
}

const SignUpMainForm: FC<IProps> = ({
  onSubmit,
  onTermsAndConditionsClick,
  onPrivacyPolicyClick,
}) => {
  const {t} = useTranslation();

  const {
    formState: {isValid, errors},
  } = useFormContext();

  return (
    <Box className={styles.container} onSubmit={onSubmit}>
      <Box className={styles.fields}>
        <Input
          name="email"
          label={t('fields.email')}
          placeholder={t('fields.email')}
          autoComplete="email"
        />
        <Input
          name="password"
          type="password"
          label={t('fields.password')}
          placeholder={t('fields.password')}
          autoComplete="new-password"
        />
        <Input
          name="confirmPassword"
          type="password"
          label={t('fields.confirmPassword')}
          placeholder={t('fields.confirmPassword')}
          autoComplete="new-password"
        />
        <FilledCheckbox
          name="agreements"
          label={
            <AgreementsLabel
              onTermsAndConditionsClick={onTermsAndConditionsClick}
              onPrivacyPolicyClick={onPrivacyPolicyClick}
            />
          }
        />
      </Box>
      {errors.root?.serverError?.message ? (
        <ServerErrorMessage message={errors.root.serverError.message} />
      ) : null}
      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        disabled={!isValid}>
        <Typography
          variant="text-base-semibold"
          color={Colors['default-gray-900']}>
          {t('Continue')}
        </Typography>
      </Button>
    </Box>
  );
};

interface IAgreementsLabelProps {
  onTermsAndConditionsClick: () => void;
  onPrivacyPolicyClick: () => void;
}

const AgreementsLabel: FC<IAgreementsLabelProps> = ({
  onTermsAndConditionsClick,
  onPrivacyPolicyClick,
}) => {
  const {t} = useTranslation();

  return (
    <Box lineHeight="20px">
      <Typography variant="text-sm-light" color={Colors['default-gray-100']}>
        {t('By.proceeding.you.agree.to.our') + ' '}
      </Typography>
      <Typography
        variant="text-sm-light"
        color={Colors['HEXB8FE61']}
        onClick={(e) => {
          e.preventDefault();
          onTermsAndConditionsClick();
        }}>
        {t('Terms.and.Conditions') + ' '}
      </Typography>
      <Typography variant="text-sm-light" color={Colors['default-gray-100']}>
        {t('and') + ' '}
      </Typography>
      <Typography
        variant="text-sm-light"
        color={Colors['HEXB8FE61']}
        onClick={(e) => {
          e.preventDefault();
          onPrivacyPolicyClick();
        }}>
        {t('Privacy.Policy')}
      </Typography>
    </Box>
  );
};

export default SignUpMainForm;
