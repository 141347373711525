import {
  AppBar,
  Box,
  IconButton,
  List,
  Toolbar,
  Typography,
  ListItemButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import styles from './Header.module.scss';
import {Logo} from 'components/atoms';
import {NAVIGATION_MENU} from 'environment/constants/navigationMenu';
import {HeaderDropdown} from 'components/molecules';
import {ROUTES} from 'environment/constants/routes';
import {IBusinessUnit} from 'environment/types/business';
import {IFolder} from 'store/models/folders';

interface IProps {
  handleDrawerToggle: () => void;
  businessUnits: IBusinessUnit[] | null;
  currentUnit: IBusinessUnit;
  setCurrentBusinessUnit: (unit: IBusinessUnit) => void;
  allFolders: IFolder[];
  createNewFolder: (folderName: string) => void;
  editFolderName: (folderId: string, folderName: string) => void;
  moveUnitToFolder: (businessUnitId: string, folderId: string) => void;
}
const Header = ({
  handleDrawerToggle,
  businessUnits,
  currentUnit,
  setCurrentBusinessUnit,
  allFolders,
  createNewFolder,
  editFolderName,
  moveUnitToFolder,
}: IProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const location = '/' + useLocation().pathname.split('/').at(1);

  const isHome = location === ROUTES.home;

  const navigateToHome = () => {
    navigate(ROUTES.dashboard.home);
  };

  const navigationList = Object.entries(NAVIGATION_MENU).map(([name, path]) => (
    <ListItemButton
      disabled={location === path}
      className={`${styles.listItem} ${location === path ? styles.active : ''}`}
      key={name}>
      <Typography component={Link} variant="text-base-light" to={path}>
        {t(name)}
      </Typography>
    </ListItemButton>
  ));

  return (
    <AppBar className={styles.appBar} position="fixed">
      <Toolbar className={styles.toolBar}>
        <Box className={styles.flex}>
          <IconButton
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: 'none'}}}>
            <MenuIcon />
          </IconButton>
          <Logo onClick={navigateToHome} isClickable={!isHome} />
          <HeaderDropdown
            businessUnits={businessUnits}
            currentUnit={currentUnit}
            setCurrentBusinessUnit={setCurrentBusinessUnit}
            allFolders={allFolders}
            editFolderName={editFolderName}
            createNewFolder={createNewFolder}
            moveUnitToFolder={moveUnitToFolder}
          />
        </Box>
        <List className={styles.list}>{navigationList}</List>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
