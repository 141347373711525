import {init, RematchDispatch, RematchRootState} from '@rematch/core';
import loadingPlugin, {ExtraModelsFromLoading} from '@rematch/loading';
import persistPlugin from '@rematch/persist';
import {models, RootModel} from './models';
import {persistConfig} from './persist.config';

type FullModel = ExtraModelsFromLoading<RootModel>;

export const RESET_STORE = 'RESET_STORE';

export const store = init<RootModel, FullModel>({
  models,
  plugins: [persistPlugin(persistConfig), loadingPlugin()],
  redux: {
    rootReducers: {
      [RESET_STORE]: () => undefined,
    },
    devtoolOptions: {
      actionSanitizer: (action) => action,
    },
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
