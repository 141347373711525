import {SIGN_UP_SCHEMA} from 'environment/validation/resolvers';
import {useForm} from 'react-hook-form';

const defaultValues = {
  email: '',
  password: '',
  confirmPassword: '',
  agreements: false,
};

export type SignUpFormData = typeof defaultValues;

export const useSignUpForm = () => {
  return useForm({
    defaultValues,
    mode: 'onChange',
    resolver: SIGN_UP_SCHEMA,
  });
};
