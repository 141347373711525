import {Box, Typography} from '@mui/material';
import {FC, PropsWithChildren} from 'react';
import Colors from 'styles/colors.scss';
import styles from './Agreements.module.scss';

interface IHeaderProps {
  title: string;
  text: string;
  uppercase?: boolean;
}

export const Header: FC<IHeaderProps> = ({title, text, uppercase}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
      <Typography
        variant="app-title"
        color={Colors['tw-green-300']}
        textTransform={uppercase ? 'uppercase' : undefined}>
        {title}
      </Typography>
      <Typography variant="text-base-light" color={Colors['default-gray-200']}>
        {text}
      </Typography>
    </Box>
  );
};

export const LinkTypography: FC<PropsWithChildren> = ({children}) => (
  <Typography variant="text-base-semibold" color={Colors.HEXB8FE61}>
    {children}
  </Typography>
);

export const Container: FC<PropsWithChildren> = ({children}) => {
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {children}
    </Box>
  );
};

export const LowerLatinOrderedList: FC<PropsWithChildren> = ({children}) => {
  return (
    <Box component="ol" className={styles.lowerLatinParenthesisList}>
      {children}
    </Box>
  );
};

export const LowerRomanOrderedList: FC<
  PropsWithChildren<{gap?: boolean; inner?: boolean}>
> = ({children, gap = false, inner = false}) => {
  return (
    <Box
      {...(gap && {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      })}
      {...(inner && {paddingLeft: '24px'})}
      component="ol"
      className={styles.lowerRomanList}>
      {children}
    </Box>
  );
};

export const DashedList: FC<PropsWithChildren> = ({children}) => {
  return (
    <Box component="ul" className={styles.dashedList}>
      {children}
    </Box>
  );
};

export const BulletList: FC<PropsWithChildren> = ({children}) => {
  return (
    <Box component="ul" className={styles.bulletList}>
      {children}
    </Box>
  );
};

export const Italic: FC<PropsWithChildren> = ({children}) => {
  return <span style={{fontStyle: 'italic'}}>{children}</span>;
};
