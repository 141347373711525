import {useState} from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {Box, Button, Typography} from '@mui/material';

import {configStyles} from './config.styles';
import styles from './BasicArea.module.scss';
import {RangeButtons, ToggleLabels} from 'environment/types';
import {Toggle} from 'components/atoms';

interface IProps {
  data: any; //TODO add type
  title: string | React.ReactNode;
  subtitle?: string;
  colors: string[];
  rangeButtons?: RangeButtons[];
  toggle?: boolean;
  toggleLables?: ToggleLabels;
  toggleChecked?: boolean;
  chartRef?: React.MutableRefObject<HighchartsReact.RefObject | null> | null;
  legend?: boolean;
  grid?: boolean;
  gradient?: [number, string][];
  categories?: (string | number)[];
  name?: string | undefined;
  onChangeToggle?: () => void;
  onClickBtn?: (value: string) => void;
  isEmpty?: boolean;
}

const BasicArea = ({
  data,
  title,
  subtitle = '',
  colors,
  chartRef = null,
  rangeButtons = [],
  toggle = false,
  toggleChecked = false,
  toggleLables = {},
  legend = true,
  grid = false,
  gradient = [],
  categories = [],
  name,
  onChangeToggle = () => {},
  onClickBtn = () => {},
  isEmpty = false,
}: IProps) => {
  const [active, setActive] = useState<string>(rangeButtons[0]?.value);
  const options = {
    colors: !isEmpty ? colors : colors.map((color) => `rgba(0, 0, 0, 0)`),
    chart: {
      type: 'areaspline',
      ...configStyles({}).chart,
    },
    title: {
      text: '',
    },

    xAxis: {
      //   type: 'datetime',
      categories: categories.length ? categories : '',
      ...configStyles({grid}).xAxis,
    },
    yAxis: configStyles({grid}).yAxis,

    legend: legend ? configStyles({}).legend : {enabled: false}, //  dots that related to graphic
    tooltip: isEmpty
      ? {enabled: false} // Disable tooltip when isEmpty is true
      : {
          headerFormat: '<b>{point.key}</b><br/>',
          pointFormat: '{series.name} <b>{point.y}</b>',
          ...configStyles({}).tooltip,
        },
    credits: {
      enabled: false, // remove highchart.com link
    },
    plotOptions: {
      series: {
        lineWidth: 2, // lineWidth on graphic
        name: name,
      },
      areaspline: {
        fillColor: isEmpty
          ? null
          : !!gradient.length
          ? {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 0,
              },
              stops: gradient,
            }
          : null,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
        },
      },
    },
    series: [{data: data}],
  };

  const handleClick = (value: string) => {
    setActive(value);
    onClickBtn(value);
  };

  const renderTitleAndSubtitle = () => {
    return typeof title === 'string' ? (
      <div>
        <Typography component="h2" variant="text-lg">
          {title}
        </Typography>
        <Typography
          className={styles.subtitle}
          component="p"
          variant="text-sm-light">
          {subtitle}
        </Typography>
      </div>
    ) : (
      <div>
        {title}
        <Typography
          className={styles.subtitle}
          component="p"
          variant="text-sm-light">
          {subtitle}
        </Typography>
      </div>
    );
  };

  return (
    <div className={styles.chartContainer}>
      <Box className={styles.header}>
        {renderTitleAndSubtitle()}
        <div className={styles.buttonsChart}>
          {!!rangeButtons.length &&
            rangeButtons.map(({value, label}) => (
              <Button
                key={value}
                variant="outlined"
                onClick={() => {
                  handleClick(value);
                }}
                className={`${styles.button} ${
                  active === value ? styles.active : ''
                }`}>
                {label}
              </Button>
            ))}
          {toggle && (
            <Toggle
              label={toggleLables}
              checked={toggleChecked}
              onChange={onChangeToggle}
            />
          )}
        </div>
      </Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
        containerProps={{style: {minHeight: '213px', height: '100%'}}}
      />
    </div>
  );
};

export default BasicArea;
