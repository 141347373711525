import {Box} from '@mui/material';
import {getDefaultAvatarByRating} from 'environment/utils/getDefaultAvatarByRating';
import {useMediaById} from 'hooks/useMediaById';
import {FC, memo} from 'react';

const WIDTH = 32;
const HEIGHT = 32;

interface Props {
  userRating: number;
  mediaId: string | null;
}

const ConnectedUserAvatar: FC<Props> = ({mediaId, userRating}) => {
  const {isLoading, imageSrc} = useMediaById(mediaId);

  if (isLoading) {
    return <Box width={WIDTH} height={HEIGHT} />;
  }

  return imageSrc ? (
    <Box
      component="img"
      src={imageSrc}
      width={WIDTH}
      height={HEIGHT}
      borderRadius={50}
    />
  ) : (
    <Box
      component="img"
      src={getDefaultAvatarByRating(userRating)}
      width={WIDTH}
      height={HEIGHT}
    />
  );
};

export default memo(ConnectedUserAvatar);
