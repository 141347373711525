import {toSortedTags} from './utils';

const SPECIALS = [
  {
    id: 'bbq',
    emoji: 'bg-spicelovers',
  },
  {
    id: 'buffet',
    emoji: 'bg-buffet',
  },
  {
    id: 'buffet-brunch',
    emoji: 'bg-shampan',
  },
  {
    id: 'char-siu',
    emoji: 'bg-char_siu',
  },
  {
    id: 'dim-sum',
    emoji: 'bg-dumplings',
  },
  {
    id: 'dumplings',
    emoji: 'bg-dumplings',
  },
  {
    id: 'fried-rice',
    emoji: 'bg-rice',
  },
  {
    id: 'fish-balls',
    emoji: 'bg-fish_ball',
  },
  {
    id: 'hot-pot',
    emoji: 'bg-hot_pot',
  },
  {
    id: 'noodles',
    emoji: 'bg-noodles',
  },
  {
    id: 'roast-goose',
    emoji: 'bg-roast_goose',
  },
  {
    id: 'wonton',
    emoji: 'bg-noodles',
  },
];

export default toSortedTags('specialsLabel', SPECIALS);
