import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import type {RootModel} from '.';
import {IReviewReply} from 'types';
import {getAllReviewReplies} from 'environment/api/services/reviewReply';
import {replyOnReview} from 'environment/api/services/reviewReply';

interface IReviewReplyState {
  allReviewReplies: IReviewReply[];
  error: null | string;
}
type ReviewReplyPayload = {
  reviewId: string;
  reply: string;
  images: any;
};

export type TReviewReplyRequestBody = {
  mediaIds?: string[];
  text: string;
};

const defaultReviewRepliesState: IReviewReplyState = {
  allReviewReplies: [],
  error: null,
};

export const reviewReply = createModel<RootModel>()({
  state: defaultReviewRepliesState,
  reducers: {
    updateAllReplies: (state, payload) => {
      return {
        ...state,
        allReviewReplies: payload,
      };
    },
    clearReviewReplies: () => defaultReviewRepliesState,
    setError: (state, payload: string) => ({
      ...state,
      error: payload,
    }),
  },
  effects: {
    async getAllReviewReplies(payload) {
      try {
        const {reviewId, take, cursorId} = payload;
        const {data} = await getAllReviewReplies(reviewId, take, cursorId);
        this.updateAllReplies(data);
      } catch (error) {
        if (error instanceof AxiosError) {
          this.setError(error.message);
        }
        const err = error as AxiosError;
        console.log(err);
      }
    },
    async replyOnReview(payload: ReviewReplyPayload) {
      try {
        const {reviewId, reply, images} = payload;
        const requestBody = {
          text: reply.trim(),
          //TODO: processed uploaded images urls, mediaIds: [id]
        };
        await replyOnReview(reviewId, requestBody);
        Promise.resolve();
      } catch (error) {
        if (error instanceof AxiosError) {
          this.setError(error.message);
        }
        this.setError('something went wrong');
        console.log(error);
        Promise.reject();
      }
    },
  },
});
