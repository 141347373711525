import {Box, FormControl, Typography} from '@mui/material';
import {EmojiIcon, IconMark} from 'components/atoms';
import {FC, PropsWithChildren} from 'react';
import Colors from 'styles/colors.scss';
import styles from './TagDropdownLabel.module.scss';

interface IProps {
  emoji: string | string[];
  text: string;
  withGradient?: boolean;
  withChevron?: boolean;
  size?: 'small' | 'large';
  isBlured?: boolean;
}

const TagDropdownLabel: FC<PropsWithChildren<IProps>> = ({
  emoji,
  text,
  withGradient = false,
  withChevron = false,
  size = 'large',
  children,
  isBlured = false,
}) => {
  const maxWidth = size === 'large' ? 270 : 165;
  const padding = size === 'large' ? '12px 16px' : '7px 16px';
  const emojiSize = size === 'large' ? 24 : 20;

  return (
    <FormControl
      fullWidth
      className={styles.formControl}
      sx={{
        maxWidth: `${maxWidth}px`,
        '& .MuiInputBase-root': {'& .MuiSelect-select': {padding}},
      }}>
      <Box
        className={`${styles.label}${
          withGradient ? ` ${styles.gradient}` : ''
        } ${isBlured ? styles.isBlured : ''}`}>
        <EmojiIcon name={emoji} size={emojiSize} />
        <Typography variant="text-base-light" className={styles.labelText}>
          {text}
        </Typography>
        {withChevron && (
          <IconMark
            size="16px"
            name="arrow-left"
            className={styles.chevronDown}
            color={Colors['default-gray-200']}
          />
        )}
      </Box>

      {children}
    </FormControl>
  );
};

export default TagDropdownLabel;
