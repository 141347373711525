import {toSortedTags} from './utils';

const WINE = [
  {
    id: 'red-wines',
    emoji: 'bg-redwines',
  },
  {
    id: 'white-wines',
    emoji: 'bg-whitewines',
  },
  {
    id: 'rose-wines',
    emoji: 'bg-rosewines',
  },
  {
    id: 'sparkling-wines',
    emoji: 'bg-sparklingwines',
  },
  {
    id: 'dessert-wines',
    emoji: 'bg-dessertwines',
  },
  {
    id: 'wine-flights',
    emoji: ['bg-redwine', 'bg-bottlewithpoppingcork'],
  },
  {
    id: 'local-wines',
    emoji: 'bg-localwines',
  },
  {
    id: 'sommelier',
    emoji: 'bg-sommelier',
  },
  {
    id: 'wine-by-the-glass',
    emoji: 'bg-winebytheglass',
  },
  {
    id: 'wine-pairings',
    emoji: 'bg-winepairings',
  },
  {
    id: 'wine-cellar',
    emoji: 'bg-winecellar',
  },
];

export default toSortedTags('wineLabel', WINE);
