import countries from 'environment/constants/countries.json';
import {AutocompleteOption} from 'environment/types';
import {type SignUpBusinessFormData} from 'hooks/forms';

type CountriesData = {
  [key: string]: string;
};

type InputItem = {
  name: keyof SignUpBusinessFormData['businessDetails'];
  labelTitle: string;
  autoComplete?: string;
  options?: AutocompleteOption[];
};

const countriesData: CountriesData = countries;

const countryOptions: AutocompleteOption[] = [];

for (const abbreviation in countriesData) {
  const name = countriesData[abbreviation];
  countryOptions.push({
    name,
    abbreviation,
  });
}

// labelTitle is the i18n property

const businessDetailsInputs: Record<
  keyof SignUpBusinessFormData['businessDetails'],
  InputItem
> = {
  country: {
    name: 'country',
    labelTitle: 'fields.country',
    autoComplete: 'country-name',
    options: countryOptions,
  },
  firstName: {
    name: 'firstName',
    labelTitle: 'fields.firstName',
    autoComplete: 'given-name',
  },
  lastName: {
    name: 'lastName',
    labelTitle: 'fields.lastName',
    autoComplete: 'family-name',
  },
  companyName: {
    name: 'companyName',
    labelTitle: 'fields.companyName',
    autoComplete: 'organization',
  },
  street: {
    name: 'street',
    labelTitle: 'fields.street',
  },
  number: {
    name: 'number',
    labelTitle: 'fields.apt',
  },
  websiteUrl: {
    name: 'websiteUrl',
    labelTitle: 'fields.website',
    autoComplete: 'url',
  },
  city: {
    name: 'city',
    labelTitle: 'fields.city',
    autoComplete: 'address-level2',
  },
  state: {
    name: 'state',
    labelTitle: 'fields.state',
    autoComplete: 'address-level1',
  },
  zipcode: {
    name: 'zipcode',
    labelTitle: 'fields.zipcode',
    autoComplete: 'postal-code',
  },
  phone: {
    name: 'phone',
    labelTitle: 'fields.phone',
    autoComplete: 'tel',
  },
};

export {businessDetailsInputs, type InputItem};
