import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';

import {configStyles} from './config.styles';
import styles from './PatternArea.module.scss';
import Colors from 'styles/colors.scss';

patternFill(Highcharts);

interface IProps {
  data: any;
}

const PatternArea = ({data}: IProps) => {
  const options = {
    colors: [
      {
        linearGradient: {
          x1: 1,
          y1: 0,
          x2: 0,
          y2: 0,
        },
        stops: [
          [0, Colors['landing-green1']],
          [1, Colors['landing-green2']],
        ],
      },
    ],
    chart: {
      type: 'area',
      ...configStyles.chart,
    },
    title: {
      text: '',
    },
    xAxis: configStyles.xAxis,
    yAxis: configStyles.yAxis,
    legend: configStyles.legend, //  dots that related to graphic
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.y} {point.change}</b>',
      useHTML: true,
      ...configStyles.tooltip,
    },
    credits: {
      enabled: false, // remove highchart.com link
    },
    plotOptions: {
      series: {
        lineWidth: 2, // lineWidth on graphic
      },
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
        },
        fillColor: {
          pattern: {
            path: {
              d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
              strokeWidth: 1.5,
            },
            width: 10,
            height: 10,
            color: Colors.HEX1b2a07,
            opacity: 0.8,
            patternTransform: 'scale(1.4) rotate(45)',
          },
        },
      },
    },
    series: [
      {
        data: data.map((item: any) => ({
          y: item.count,
          change: item.change,
        })),
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{className: styles.chartContainer}}
    />
  );
};

export default PatternArea;
