import {toSortedTags} from './utils';

const FAMILY_OWNED = [
  {
    id: 'family-owned',
    emoji: 'bg-familyowned',
  },
  {
    id: 'homestyle-cooking',
    emoji: 'bg-homestylecooking',
  },
  {
    id: 'grandmas-recipes',
    emoji: 'bg-grandmasrecipes',
  },
  {
    id: 'warm-hospitality',
    emoji: 'bg-warmhospitality',
  },
  {
    id: 'personal-service',
    emoji: 'bg-personalservice',
  },
  {
    id: 'family-atmosphere',
    emoji: 'bg-familyatmosphere',
  },
  {
    id: 'homemade',
    emoji: 'bg-homemade',
  },
  {
    id: 'heirloom-cooking',
    emoji: 'bg-heirloomcooking',
  },
  {
    id: 'time-honored',
    emoji: 'bg-timehonored',
  },
  {
    id: 'comfort-food',
    emoji: 'bg-comfortfood',
  },
  {
    id: 'traditional',
    emoji: 'bg-traditional',
  },
];

export default toSortedTags('familyOwnedLabel', FAMILY_OWNED);
