import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, RootState} from 'store/index';

const useWaterfalls = () => {
  const dispatch = useDispatch<Dispatch>();

  const {waterfallsPaginated, waterfallsMostRecent, waterfallsFeatured} =
    useSelector((state: RootState) => state.waterfalls);

  const getWaterfallPaginatedData = (
    businessUnitId: string,
    take: number,
    cursorId?: number,
  ) => {
    dispatch.waterfalls.getWaterfallPaginated({
      businessUnitId,
      take,
      cursorId,
    });
  };

  const getWaterfallMostRecentData = (businessUnitId: string, type: string) => {
    dispatch.waterfalls.getWaterfallMostRecent({
      businessUnitId,
      type,
    });
  };

  const getWaterfallFeaturedData = (businessUnitId: string, type: string) => {
    dispatch.waterfalls.getWaterfallFeatured({
      businessUnitId,
      type,
    });
  };

  const updateWaterfallReply = (waterfallId: string, reply: null | string) => {
    dispatch.waterfalls.updateWaterfallReply({
      waterfallId: waterfallId,
      reply: reply,
    });
  };

  return {
    waterfallsPaginated,
    waterfallsFeatured,
    waterfallsMostRecent,
    getWaterfallPaginatedData,
    getWaterfallMostRecentData,
    getWaterfallFeaturedData,
    updateWaterfallReply,
  };
};

export default useWaterfalls;
