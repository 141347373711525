import {io} from 'socket.io-client';

const baseURL = process.env.REACT_APP_API_URL!;

export const socket = io(baseURL, {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});
