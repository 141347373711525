import {toSortedTags} from './utils';

const COFFEE = [
  {
    id: 'artisan-coffee',
    emoji: 'bg-artisancoffee',
  },
  {
    id: 'espresso-creations',
    emoji: 'bg-espressocreations',
  },
  {
    id: 'coffee-specialties',
    emoji: 'bg-coffeespecialties',
  },
  {
    id: 'coffee-blends',
    emoji: 'bg-coffeeblends',
  },
  {
    id: 'coffeehouse',
    emoji: 'bg-coffeehouse',
  },
  {
    id: 'coffee-aromas',
    emoji: 'bg-coffeearomas',
  },
  {
    id: 'freshly-brewed',
    emoji: 'bg-freshlybrewed',
  },
  {
    id: 'coffee-aficionado',
    emoji: 'bg-coffeeaficionado',
  },
  {
    id: 'specialty-coffees',
    emoji: 'bg-specialtycoffees',
  },
];

export default toSortedTags('coffeeLabel', COFFEE);
