import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ROUTES} from 'environment/constants/routes';
import {DashboardCardWrapper} from 'components/atoms';
import {IEngagementsQuantityForDay} from 'store/models/dashboard';
import {PatternArea} from 'components/molecules';

interface IProps {
  engagementsQuantity: IEngagementsQuantityForDay[];
}

const EngagementCard = ({engagementsQuantity}: IProps) => {
  const {t} = useTranslation();

  return (
    <DashboardCardWrapper
      title={t('Engagement')}
      navigateTo={ROUTES.dashboard.engagement}>
      {/* todo replace with a new graph */}
      <PatternArea data={engagementsQuantity} />
    </DashboardCardWrapper>
  );
};

export default memo(EngagementCard);
