import {Box, Typography, Button} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './PaymentsBalance.module.scss';

interface IProps {
  balance: string;
  addMoney: () => void;
}

const PaymentsBalance = ({balance, addMoney}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Typography variant="text-base-semibold" className={styles.title}>
          {t('Your.balance')}
        </Typography>
        <Typography className={styles.balance}>$ {balance}</Typography>
        <Button
          type="submit"
          className={styles.button}
          variant="contained"
          size="large"
          onClick={addMoney}>
          {t('Add.money')}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentsBalance;
