import {ComingSoon} from 'components/molecules';
import {LayoutWithHeaderTemplate} from 'components/templates';
import {ROUTES} from 'environment/constants/routes';
import {
  Boosts,
  Charts,
  ConsumerDemographics,
  Dashboard,
  Engagement,
  Expenditures,
  FeaturedWaterfalls,
  Payment,
  RecentActivity,
  Settings,
} from 'pages';
import {Navigate, Route} from 'react-router-dom';

export const protectedRoutes = (
  <Route element={<LayoutWithHeaderTemplate />}>
    <Route
      path={ROUTES.home}
      element={<Navigate to={ROUTES.dashboard.home} />}
    />
    <Route
      path={ROUTES.dashboard.home}
      element={<Dashboard key="dashboard" />}
    />
    <Route path={ROUTES.dashboard.recentAcivity} element={<RecentActivity />} />
    <Route
      path={ROUTES.dashboard.expenditures}
      element={
        <ComingSoon>
          <Expenditures />
        </ComingSoon>
      }
    />
    <Route
      path={ROUTES.dashboard.boosts}
      element={
        <ComingSoon>
          <Boosts />
        </ComingSoon>
      }
    />
    <Route
      path={ROUTES.dashboard.consumerDemographics}
      element={<ConsumerDemographics />}
    />
    <Route path={ROUTES.dashboard.engagement} element={<Engagement />} />

    <Route path={ROUTES.charts} element={<Charts />} />
    <Route path={ROUTES.payment} element={<Payment />} />
    <Route
      path={ROUTES.dashboard.featuredWaterfalls}
      element={<FeaturedWaterfalls />}
    />
    <Route path={ROUTES.settings} element={<Settings />} />
  </Route>
);
