import {LoaderOverlay} from 'components/atoms';
import {SignUpMembersTemplate} from 'components/templates';
import axios from 'axios';
import {
  signUpBusinessComplete,
  signUpBusinessMembers,
} from 'environment/api/services/auth';
import {SignUpBusinessFormData} from 'hooks/forms';
import {castSignUpMembersForBackend} from 'hooks/forms/helpers';
import {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'environment/constants/routes';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'store';

const SignUpMembers = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const {control, setError, trigger, clearErrors} =
    useFormContext<SignUpBusinessFormData>();

  const members = useWatch({control, name: 'members'});

  const isMembersNotEmpty = members.some(
    (member) => member.email || member.fullName,
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (isMembersNotEmpty && !(await trigger('members'))) {
        return;
      }

      setIsLoading(true);

      await signUpBusinessMembers({
        members: isMembersNotEmpty ? castSignUpMembersForBackend(members) : [],
      });

      const {data} = await signUpBusinessComplete();

      localStorage.setItem('access_token', data.id_token);
      dispatch.units.getBusinessUnits();
      navigate(ROUTES.dashboard.home);

      clearErrors('members');
      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpMembersTemplate
        onSubmit={handleSubmit}
        isMembersNotEmpty={isMembersNotEmpty}
      />
    </>
  );
};

export default SignUpMembers;
