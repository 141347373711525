import {t} from 'i18next';

export const categories = {
  likes: t('Likes'),
  comments: t('Comments'),
  share: t('Share'),
  review: t('Review'),
  view: t('View'),
  storyTag: t('Story.tag'),
  forumTag: t('Forum.tag'),
  pageVisit: t('Page.visit'),
};

export const mockData = {
  likes: 90000,
  comments: 0,
  share: 0,
  review: 0,
  view: 0,
  storyTag: 0,
  forumTag: 0,
  pageVisit: 0,
};

export const mockData2 = {
  likes: 0,
  comments: 0,
  share: 0,
  review: 0,
  view: 0,
  storyTag: 0,
  forumTag: 0,
  pageVisit: 0,
};
