import {Box, Button, Typography} from '@mui/material';
import axios from 'axios';
import {
  GoBackLink,
  Input,
  LoaderOverlay,
  ServerErrorMessage,
} from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import {settingsResetPassword} from 'environment/api/services/settings';
import {PASSWORD_RECOVERY_SCHEMA} from 'environment/validation/resolvers';
import {useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './ForgotPasswordModals.module.scss';

const defaultValues = {
  newPassword: '',
  confirmNewPassword: '',
};

interface IProps {
  open: boolean;
  onClose: () => void;
}

const PasswordRecoveryModal = ({open, onClose}: IProps) => {
  const {t} = useTranslation();

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: PASSWORD_RECOVERY_SCHEMA,
  });

  const isValid = form.formState.isValid;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({
    newPassword,
  }) => {
    try {
      setIsLoading(true);

      const token = localStorage.getItem('reset_token');
      if (!token) {
        return;
      }

      await settingsResetPassword({password: newPassword}, token);
      localStorage.removeItem('reset_token');
      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <SettingsModal
      leftSection={<GoBackLink size="large" onClick={onClose} />}
      title={t('Password.recovery')}
      open={open}
      onClose={handleClose}
      withCloseButton>
      {isLoading && <LoaderOverlay />}
      <FormProvider {...form}>
        <form className={styles.wrapper} onSubmit={form.handleSubmit(onSubmit)}>
          <Box className={styles.containerRecovery}>
            <Input
              name="newPassword"
              type="password"
              label={t('fields.newPassword')}
              placeholder={t('fields.newPassword')}
              autoComplete="new-password"
            />
            <Input
              name="confirmNewPassword"
              type="password"
              label={t('fields.confirmPassword')}
              placeholder={t('fields.confirmPassword')}
              autoComplete="new-password"
            />
          </Box>

          {form.formState.errors.root?.serverError?.message ? (
            <ServerErrorMessage
              message={form.formState.errors.root.serverError.message}
            />
          ) : null}

          <Button
            type="submit"
            size="large"
            variant="contained"
            fullWidth
            disabled={!isValid}>
            <Typography
              color={Colors['default-gray-900']}
              variant="text-base-semibold">
              {t('Update')}
            </Typography>
          </Button>
        </form>
      </FormProvider>
    </SettingsModal>
  );
};

export default PasswordRecoveryModal;
