import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store/index';
import {Dispatch} from 'store/index';
import {IBusinessUnit} from 'environment/types/business';
import {castScheduleForBackend, mapDaysToSchedule} from './forms/helpers';

const useUnits = () => {
  const dispatch = useDispatch<Dispatch>();

  const {businessUnits, currentBusinessUnitId} = useSelector(
    (state: RootState) => state.units,
  );

  // Although TS doesn't say that businessUnits[currentBusinessUnitId]
  // can return undefined, it actually could be so
  // ––> check tsconfig.json with "noUncheckedIndexedAccess": true,
  //
  // `|| getEmptyBusinessUnit()` is here to make currentUnit always
  // have a value, otherwise there'd be many errors throughout the app
  const currentUnit =
    businessUnits[currentBusinessUnitId] || getEmptyBusinessUnit();

  const setCurrentBusinessUnit = (payload: IBusinessUnit) => {
    return dispatch.units.setCurrentBusinessUnit(payload.id);
  };

  return {
    businessUnits: Object.values(businessUnits),
    currentUnit,
    setCurrentBusinessUnit,
  };
};

function getEmptyBusinessUnit(): IBusinessUnit {
  return {
    rating: 0,
    businessUnitPickedLabels: null,
    questions: [],
    category: '',
    labels: [],
    mantras: [
      {
        title: '',
        text: '',
        position: 0,
      },
    ],
    contactNumber: '',
    // @ts-ignore
    operationHours: [...castScheduleForBackend(mapDaysToSchedule())],
    bookmarked: false,
    createdAt: '',
    updatedAt: '',
    locationId: '',
    location: {
      id: '',
      country: '',
      street: '',
      number: '',
      city: '',
      state: '',
      zipcode: '',
      latitude: 0,
      longitude: 0,
      createdAt: '',
      updatedAt: '',
    },
    id: '',
    business: {title: '', websiteUrl: null},
    autoFeaturing: false,
    businessId: '',
    companyName: '',
    websiteUrl: '',
  };
}

export default useUnits;
