import {Box, Button, Typography} from '@mui/material';
import {ServerErrorMessage} from 'components/atoms';
import {PersonalizeQuestionsForm} from 'components/organisms';
import {type SignUpBusinessFormData} from 'hooks/forms';
import {type FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './SignUpPersonalizeQuestions.module.scss';

interface IProps {
  onSubmit: () => void;
  onBackButtonOverride?: () => void;
  withBackButton?: boolean;
  withUseAsDropdown?: boolean;
}

const SignUpPersonalizeQuestionsTemplate: FC<IProps> = ({
  onSubmit,
  onBackButtonOverride,
  withBackButton,
  withUseAsDropdown,
}) => {
  const {t} = useTranslation();

  const {
    formState: {errors},
  } = useFormContext<SignUpBusinessFormData>();

  return (
    <Box className={styles.container}>
      <PersonalizeQuestionsForm
        onBackButtonOverride={onBackButtonOverride}
        withBackButton={withBackButton}
        withUseAsDropdown={withUseAsDropdown}
      />
      {errors.root?.serverError?.message ? (
        <ServerErrorMessage message={errors.root.serverError.message} />
      ) : null}
      <Button
        sx={{marginTop: '8px'}}
        variant="contained"
        fullWidth
        onClick={onSubmit}>
        <Typography
          variant="text-base-semibold"
          color={Colors['default-gray-900']}>
          {t('Next')}
        </Typography>
      </Button>
    </Box>
  );
};

export default SignUpPersonalizeQuestionsTemplate;
