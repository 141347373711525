import {Box, Typography} from '@mui/material';

import styles from './Badge.module.scss';

interface IProps {
  value: number | string;
}

const Badge = ({value}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <Typography variant="text-xs" className={styles.text}>
        {value}
      </Typography>
    </Box>
  );
};

export default Badge;
