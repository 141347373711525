import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import styles from './BarChart.module.scss';
import Colors from 'styles/colors.scss';

const borderRadius = require('highcharts-border-radius');

borderRadius(Highcharts);

interface IProps {
  data: any;
  categories: string[];
  isEmpty?: boolean;
}

const BarChart = ({data, categories, isEmpty = false}: IProps) => {
  const options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
      spacingBottom: 0,
      spacingTop: 0,
      spacingRight: 2,
      spacingLeft: 2,
    },
    title: {
      text: null,
    },
    xAxis: {
      labels: {
        style: {
          color: Colors['default-gray-100'],
          fontSize: '11px',
        },
      },
      categories: categories,
      gridLineWidth: 0,
      lineWidth: 0,
    },
    yAxis: {
      labels: {
        style: {
          color: Colors['default-gray-100'],
          fontSize: '12px',
        },
        formatter: (context: Highcharts.AxisLabelsFormatterContextObject) => {
          return +context.value >= 1000
            ? `${+context.value / 1000}k`
            : context.value;
        },
      },
      lineWidth: 1,
      gridLineDashStyle: 'LongDash',
      lineColor: Colors['default-gray-700'],
      gridLineColor: Colors['default-gray-700'],
      title: {
        enabled: false,
      },
    },
    tooltip: {enabled: false},
    legend: {enabled: false},
    plotOptions: {
      bar: {
        className: styles.bar,
        shadow: {
          color: Colors.HEXb8fe61b2,
          width: 5,
          offsetX: 0,
          offsetY: 0,
        },
        borderRadiusTopLeft: '50%',
        borderRadiusBottomLeft: '50%',
        borderRadiusTopRight: '50%',
        borderRadiusBottomRight: '50%',
        borderRadius: '50%',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0.3,
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: isEmpty
            ? []
            : [
                [0, Colors['landing-green1']],
                [1, Colors['landing-green2']],
              ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: data,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{className: styles.chartContainer}}
    />
  );
};

export default BarChart;
