import {Models} from '@rematch/core';
import {units} from './units';
import {consumer_demographics} from './consumer-demographics';
import {engagement} from './engagement';
import {reviewReply} from './reviewReplies';
import {waterfalls} from './waterfalls';
import {dashboard} from './dashboard';
import {folders} from './folders';
import {business} from './business';
import {recentActivity} from './recentActivity';

export interface RootModel extends Models<RootModel> {
  units: typeof units;
  consumer_demographics: typeof consumer_demographics;
  engagement: typeof engagement;
  reviewReply: typeof reviewReply;
  waterfalls: typeof waterfalls;
  dashboard: typeof dashboard;
  folders: typeof folders;
  business: typeof business;
  recentActivity: typeof recentActivity;
}

export const models: RootModel = {
  units,
  consumer_demographics,
  engagement,
  reviewReply,
  waterfalls,
  dashboard,
  folders,
  business,
  recentActivity,
};
