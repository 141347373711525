import {toSortedTags} from './utils';

const SPICY_FOODS = [
  {
    id: 'spicy-delights',
    emoji: 'bg-spicydelights',
  },
  {
    id: 'fiery-flavors',
    emoji: 'bg-fieryflavors',
  },
  {
    id: 'bold-and-spicy',
    emoji: 'bg-boldandspicy',
  },
  {
    id: 'zesty',
    emoji: 'bg-zesty',
  },
  {
    id: 'tongue-tingling',
    emoji: 'bg-tonguetingling',
  },
  {
    id: 'hot-and-tasty',
    emoji: 'bg-hotandtasty',
  },
  {
    id: 'spice-lovers',
    emoji: 'bg-spicelovers',
  },
];

export default toSortedTags('spicyFoodsLabel', SPICY_FOODS);
