import {BoostsTemplate} from 'components/templates';

import {
  currentRestourant,
  nearbyRestourant,
  speedometerData,
} from 'environment/configs/mockData';

const Boosts = () => {
  return (
    <BoostsTemplate
      currentRestourant={currentRestourant}
      nearbyRestourant={nearbyRestourant}
      speedometerData={speedometerData}
    />
  );
};

export default Boosts;
