import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'store/index';
import {RootState} from 'store/index';

const useEngagement = () => {
  const dispatch = useDispatch<Dispatch>();

  const {review, like, comment, share, forumTag, waterfallTag, view} =
    useSelector((state: RootState) => state.engagement.charts);

  const {allReviews} = useSelector((state: RootState) => state.engagement);

  const {pageVisit} = useSelector((state: RootState) => state.engagement);

  const getEngagementData = (
    businessUnitId: string,
    type: string,
    months: string,
  ) => {
    switch (type) {
      case 'review':
        dispatch.engagement.getEngagementReviewForDay({businessUnitId});
        dispatch.engagement.getEngagementReviewForMonth({
          businessUnitId,
          months,
        });
        break;
      case 'like':
        dispatch.engagement.getEngagementLikeForDay({businessUnitId});
        dispatch.engagement.getEngagementLikeForMonth({businessUnitId, months});
        break;
      case 'comment':
        dispatch.engagement.getEngagementCommentForDay({businessUnitId});
        dispatch.engagement.getEngagementCommentForMonth({
          businessUnitId,
          months,
        });
        break;
      case 'share':
        dispatch.engagement.getEngagementShareForDay({businessUnitId});
        dispatch.engagement.getEngagementShareForMonth({
          businessUnitId,
          months,
        });
        break;
      case 'view':
        dispatch.engagement.getEngagementViewForDay({businessUnitId});
        dispatch.engagement.getEngagementViewForMonth({businessUnitId, months});
        break;
      case 'waterfallTag':
        dispatch.engagement.getEngagementWaterfallTagForDay({businessUnitId});
        dispatch.engagement.getEngagementWaterfallTagForMonth({
          businessUnitId,
          months,
        });
        break;
      case 'forumTag':
        dispatch.engagement.getEngagementForumTagForDay({businessUnitId});
        dispatch.engagement.getEngagementForumTagForMonth({
          businessUnitId,
          months,
        });
        break;
      default:
        break;
    }
  };

  const getEngagementPageVisitData = (
    businessUnitId: string,
    type: string,
    startDate?: string,
    endDate?: string,
  ) => {
    switch (type) {
      case 'pageVisitByData':
        dispatch.engagement.getEngagementPageVisitByDate({
          businessUnitId,
          startDate,
          endDate,
        });
        break;
      case 'pageVisitByAge':
        dispatch.engagement.getEngagementPageVisitByAge({businessUnitId});
        break;
      case 'pageVisitUserStatus':
        dispatch.engagement.getEngagementPageVisitUserStatus({
          businessUnitId,
        });
        break;
      default:
        break;
    }
  };

  const getAllReviews = (
    businessUnitId: string,
    take: number,
    scoreSort?: 'asc' | 'desc',
    scoreFilter?: number,
    cursorId?: string,
  ) => {
    dispatch.engagement.getAllEngagementReviews({
      businessUnitId,
      take,
      scoreSort,
      scoreFilter,
      cursorId,
    });
  };

  return {
    allReviews,
    review,
    like,
    comment,
    share,
    forumTag,
    waterfallTag,
    view,
    pageVisit,
    getEngagementData,
    getEngagementPageVisitData,
    getAllReviews,
  };
};

export default useEngagement;
