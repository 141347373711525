import {Box} from '@mui/material';
import {InfoLabel, InputWithTopLabel} from 'components/atoms';
import {
  InputItem,
  businessDetailsInputs,
} from 'environment/constants/businessDetails';
import {AutocompleteOption} from 'environment/types';
import {SignUpBusinessFormData} from 'hooks/forms';
import {useCallback, type FC} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import FormAutocomplete from './FormAutocomplete';
import styles from './SignUpBusinessDetailsForm.module.scss';

interface IProps {
  withLabel?: boolean;
  hideFirstLastName?: boolean;
  hideWebSite?: boolean;
}

const SignUpBusinessDetailsForm: FC<IProps> = ({
  withLabel = true,
  hideFirstLastName = false,
  hideWebSite = false,
}) => {
  const {t} = useTranslation();

  const {control, trigger, getFieldState} =
    useFormContext<SignUpBusinessFormData>();

  const businessType = useWatch({control, name: 'businessType.type'});

  const renderInput = useCallback((inputItem: InputItem) => {
    const {name, labelTitle, autoComplete} = inputItem;

    return (
      <InputWithTopLabel
        name={`businessDetails.${name}`}
        labelTitle={labelTitle}
        autoComplete={autoComplete}
        type="text"
      />
    );
  }, []);

  const {isTouched} = getFieldState('businessDetails.zipcode');

  const handleAfterSuccessfulContryChange = () => {
    if (isTouched) {
      trigger('businessDetails.zipcode');
    }
  };

  return (
    <>
      <Box className={styles.container}>
        <FormAutocomplete
          name={`businessDetails.${businessDetailsInputs.country.name}`}
          labelTitle={businessDetailsInputs.country.labelTitle}
          inputAutoComplete={businessDetailsInputs.country.autoComplete}
          options={
            businessDetailsInputs.country.options as AutocompleteOption[]
          }
          afterSuccessfulChange={handleAfterSuccessfulContryChange}
        />
        {!hideFirstLastName && (
          <Box className={styles.rowItems}>
            {renderInput(businessDetailsInputs.firstName)}
            {renderInput(businessDetailsInputs.lastName)}
          </Box>
        )}
        {renderInput(businessDetailsInputs.companyName)}
        {!hideWebSite && renderInput(businessDetailsInputs.websiteUrl)}
        <Box className={styles.rowItems}>
          {renderInput(businessDetailsInputs.street)}
          {renderInput(businessDetailsInputs.number)}
        </Box>
        <Box className={styles.rowItems}>
          {renderInput(businessDetailsInputs.city)}
          {renderInput(businessDetailsInputs.state)}
        </Box>
        {renderInput(businessDetailsInputs.zipcode)}
        {renderInput(businessDetailsInputs.phone)}
      </Box>
      {withLabel && businessType && (
        <InfoLabel text={t(getLabel(businessType))} />
      )}
    </>
  );
};

function getLabel(
  businessType: SignUpBusinessFormData['businessType']['type'],
) {
  if (businessType === 'food-truck') {
    return 'put.your.most.frequent.location';
  } else if (businessType === 'one-location') {
    return 'You.can.always.add.more.locations';
  } else if (businessType === 'multiple-locations') {
    return 'Dont.worry.you.can.always.add.more.locations';
  } else {
    return '';
  }
}

export default SignUpBusinessDetailsForm;
