export function sortObjectKeys<K extends string, V>(
  obj: Record<K, V>,
  order: K[],
): Record<K, V> {
  const sortedKeys = (Object.keys(obj) as K[]).sort((a, b) => {
    return order.indexOf(a) - order.indexOf(b);
  });

  const sortedObj = sortedKeys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {} as Record<K, V>);

  return sortedObj;
}
