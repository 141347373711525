import {Box, Divider, Typography} from '@mui/material';
import {IconMark, SettingsSectionButton} from 'components/atoms';
import SettingsSection from 'components/molecules/settingsSection/SettingsSection';
import {changeEmailVerify} from 'environment/api/services/settings';
import {Schedule, SettingsModals} from 'environment/types';
import {useEffect, useState, type FC, type PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import Colors from 'styles/colors.scss';
import AddBusiness from '../../modals/addBusiness/AddBusiness';
import BusinessInfo from './BusinessInfo';
import GeneralSection from './GeneralSection';
import NotificationsSection from './NotificationsSection';
import ScheduleInfo from './ScheduleInfo';
import styles from './YourLocations.module.scss';
import {NewBusinessFormData} from 'hooks/forms';

interface IProps {
  id: string;
  name: string;
  email: string;
  location: string;
  schedule: Schedule;
  businessDetails: NewBusinessFormData['businessDetails'];
  refetchBusiness: () => void;
}

const YourLocations: FC<IProps> = ({
  id,
  name,
  email,
  location,
  schedule,
  businessDetails,
  refetchBusiness,
}) => {
  const {t} = useTranslation();

  const [isGeneralSectionExpanded, setGeneralSectionExpanded] =
    useState<boolean>(false);
  const [isNotificationsSectionExpanded, setNotificationsSectionExpanded] =
    useState<boolean>(false);

  const [addBusinessModalOpen, setAddBusinessModalOpen] =
    useState<boolean>(false);

  // these three states are responsible for email/password modals flow
  const [modal, setModal] = useState<SettingsModals | null>(null);
  const [prevModal, setPrevModal] = useState<SettingsModals | null>(null);
  const closeModal = () => setModal(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    searchParams.delete('token');

    const action = searchParams.get('action');
    searchParams.delete('action');

    if (!token || !action) {
      return;
    }

    setSearchParams('');
    setGeneralSectionExpanded(true);

    if (action === 'password_recovery') {
      localStorage.setItem('reset_token', token);
      setModal('password-recovery');
    }

    if (action === 'change_email') {
      changeEmailVerify(token).then(refetchBusiness).catch(console.error);
    }
  }, [searchParams, setSearchParams, navigate, refetchBusiness]);

  return (
    <>
      <SettingsSection>
        <Box className={styles.header}>
          <Typography variant="text-xl">{t('Your.locations')}</Typography>
          <SettingsSectionButton
            text={t('Add.new.business')}
            onClick={() => setAddBusinessModalOpen(true)}
          />
        </Box>
        <Divider orientation="horizontal" sx={{marginY: '16px'}} />
        <BusinessInfo
          id={id}
          name={name}
          location={location}
          businessDetails={businessDetails}
        />
        <ScheduleInfo schedule={schedule} />
        <Divider
          orientation="horizontal"
          sx={{marginBottom: '20px', background: Colors['default-gray-600']}}
        />
        <Box className={styles.accordions}>
          <SectionAccordion
            title={t('General')}
            opened={isGeneralSectionExpanded}
            toggleOpened={() => setGeneralSectionExpanded((v) => !v)}>
            <GeneralSection
              email={email}
              modal={modal}
              prevModal={prevModal}
              setModal={setModal}
              setPrevModal={setPrevModal}
              closeModal={closeModal}
            />
          </SectionAccordion>
          <SectionAccordion
            title={t('Notifications')}
            opened={isNotificationsSectionExpanded}
            toggleOpened={() => setNotificationsSectionExpanded((v) => !v)}>
            <NotificationsSection />
          </SectionAccordion>
        </Box>
      </SettingsSection>

      <AddBusiness
        open={addBusinessModalOpen}
        toggleOpen={() => setAddBusinessModalOpen((v) => !v)}
      />
    </>
  );
};

interface ISectionAccordionProps {
  title: string;
  opened: boolean;
  toggleOpened: () => void;
}

const SectionAccordion: FC<PropsWithChildren<ISectionAccordionProps>> = ({
  title,
  opened,
  toggleOpened,
  children,
}) => {
  return (
    <Box>
      <Box
        onClick={toggleOpened}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Typography variant="text-xl">{title}</Typography>
        <IconMark name="arrow-down" size="32px" color={Colors['white']} />
      </Box>
      {opened && children}
    </Box>
  );
};

export default YourLocations;
