import {createModel} from '@rematch/core';
import {ANALYTICS_CATEGORIES} from 'environment/constants/analytics';
import {sortObjectKeys} from 'environment/utils/sortObjectKeys';
import {
  Analytics,
  AnalyticsCategory,
  Period,
} from 'types/business-unit-analytics';
import type {RootModel} from '.';

type State = {
  analytics: Analytics;
  period: Period;
};

const initialState: State = {
  analytics: generateDefaultAnalytics(),
  period: '24h',
};

export const recentActivity = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAnalytics: (state, analytics: Analytics) => {
      const sortedAnalytics = sortObjectKeys(analytics, order);
      return {...state, analytics: sortedAnalytics};
    },

    setPeriod: (state, period: Period) => {
      return {...state, period};
    },
  },
});

function generateDefaultAnalytics(): Analytics {
  const result = {} as Analytics;

  ANALYTICS_CATEGORIES.forEach((type) => {
    result[type] = {count: 0, change: 0};
  });

  return result;
}

const order: AnalyticsCategory[] = [
  'reviews',
  'like',
  'comment',
  'share',
  'watch',
  'story-tag',
  'forum-tag',
  'page-visit',
];
