import {Box, Typography} from '@mui/material';
import {GradientIcon} from 'components/molecules';
import {roundNumberToDecimal} from 'environment/utils/roundNumberToDecimal';
import {FC} from 'react';
import Colors from 'styles/colors.scss';
import IconMark from '../icon/IconMark';
import styles from './EngagementItem.module.scss';

type Engagement = 'like' | 'dislike' | 'comment' | 'view' | 'share';

interface Props {
  name: Engagement;
  amount: number;
}

const renderIcon = (name: Engagement) => {
  switch (name) {
    case 'like': {
      return <GradientIcon name="like" size="24px" />;
    }
    case 'dislike': {
      return <IconMark name="dislike" size="24px" color={Colors.white} />;
    }
    case 'comment': {
      return <GradientIcon name="message-text" size="24px" />;
    }
    case 'view': {
      return <IconMark name="show" size="24px" color={Colors.white} />;
    }
    case 'share': {
      return <GradientIcon name="send-2" size="24px" />;
    }
  }
};

const EngagementItem: FC<Props> = ({name, amount}) => {
  return (
    <Box className={styles.wrapper}>
      {renderIcon(name)}
      <Typography variant="text-sm-light">
        {roundNumberToDecimal(amount)}
      </Typography>
    </Box>
  );
};

export default EngagementItem;
