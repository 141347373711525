import {IWaterfall} from 'types/waterfall';
import {
  getMediaByPublicationId,
  getMediaWaterfallThumbnailRequest,
} from 'environment/api/services/media';
import {getPersonalUserInfoById} from 'environment/api/services/user';
import {getMediaById} from 'environment/api/services/media';
import {getUserById} from 'environment/api/services/user';
import {getWaterfallReplyList} from 'environment/api/services/waterfalls';

export const processWaterfallData = async (
  waterfall: IWaterfall,
): Promise<IWaterfall> => {
  try {
    // Process user's avatar
    if (waterfall.user) {
      const user = await getUserById(waterfall.user.id);

      const avatarId = user.data.avatar?.mediaId;

      if (avatarId) {
        const {data} = await getMediaById(avatarId);

        if (data.body && !Array.isArray(data.body)) {
          const avatarUrl = data.body.url;
          waterfall.user.avatar = avatarUrl;
        }
      }
    }

    if (waterfall.user) {
      const user = await getUserById(waterfall.user.id);
      waterfall.user.engagersCount = user.data.engagersCount;
      waterfall.user.engagingsCount = user.data.engagersCount;
    }

    // Process waterfall reply
    if (waterfall.id) {
      const {data} = await getWaterfallReplyList(waterfall.id);

      if (data && data.length > 0) {
        waterfall.reply = data[0].text;
      }
    }

    // Process user's personal info
    if (waterfall.user) {
      const {data} = await getPersonalUserInfoById(waterfall.user.id);

      if (data) {
        waterfall.user.about = data.about;
      }
    }

    // Process media items
    if (waterfall.media) {
      const mediaItem = waterfall.media;

      const {data} = await getMediaByPublicationId({
        publication: 'waterfall',
        publicationId: waterfall.id,
      });

      if (Array.isArray(data?.body)) {
        const body = data.body[0];

        if (body?.url) {
          mediaItem.url = body.url;
        }
      }
    }

    if (waterfall.media) {
      const mediaItem = waterfall.media;

      if (mediaItem.isThumbnailUploaded) {
        const {data} = await getMediaWaterfallThumbnailRequest(mediaItem.id);

        if (data?.body?.url) {
          mediaItem.thumbnailUrl = data.body.url;
        }
      }
    }
  } catch (error) {
    console.error('Error processing waterfall data:', error);
  }

  return waterfall;
};
