import {Box, Button, Typography} from '@mui/material';
import axios from 'axios';
import {
  GoBackLink,
  GradientOutlinedButton,
  InfoLabel,
  LoaderOverlay,
  LoaderOverlay2,
  ServerErrorMessage,
  Stepper,
} from 'components/atoms';
import {SettingsModal} from 'components/molecules';
import {
  AddMembersForm,
  BusinessTypeForm,
  SignUpBusinessDetailsForm,
  SignUpScheduleForm,
} from 'components/organisms';
import {
  SignUpPersonalizeFifteenTagsTemplate,
  SignUpPersonalizeQuestionsTemplate,
  SignUpPersonalizeThreeTagsTemplate,
} from 'components/templates';
import {useNewBusinessForm} from 'hooks/forms';
import {useCallback, useMemo, useState, type FC} from 'react';
import {FormProvider, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, RootState} from 'store';
import Colors from 'styles/colors.scss';
import styles from './AddBusiness.module.scss';
import {validateBusinessLocation} from 'environment/api/services/auth';

interface IProps {
  open: boolean;
  toggleOpen: () => void;
}

const AddBusinessHeader: FC<{activeStep: number; onClick?: () => void}> = ({
  activeStep,
  onClick,
}) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.header}>
      {activeStep === 0 ? (
        <Typography variant="text-2xl">{t('Add.new.business')}</Typography>
      ) : (
        <GoBackLink onClick={onClick} />
      )}
      <Stepper activeStep={activeStep} steps={7} />
    </Box>
  );
};

const AddBusiness: FC<IProps> = ({open, toggleOpen}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const isLoading = useSelector(
    (state: RootState) => state.loading.effects.units.createBusinessUnit,
  );

  const [isLocationValidating, setIsLocationValidating] = useState(false);

  const [activeStep, setActiveStep] = useState<number>(0);

  const {trigger, handleSubmit, reset, setError, formState, ...form} =
    useNewBusinessForm();

  const [
    businessType,
    businessDetails,
    schedule,
    questions,
    tags,
    member,
    receipts,
  ] = useWatch({
    control: form.control,
    name: [
      'businessType',
      'businessDetails',
      'schedule',
      'questions',
      'tags',
      'member',
      'receipts',
    ],
  });

  const handleAddBusinessClose = useCallback(() => {
    toggleOpen();
    setActiveStep(0);
    reset();
  }, [toggleOpen, reset]);

  const onBusinessTypeSubmit = () => {
    setActiveStep(1);
  };

  const onBusinessDetailsSubmit = useCallback(async () => {
    const isValid = await trigger('businessDetails');
    if (!isValid) {
      return;
    }

    try {
      setIsLocationValidating(true);

      await validateBusinessLocation({
        city: businessDetails.city,
        country: businessDetails.country,
        street: businessDetails.street,
        number: businessDetails.number.trim() || undefined,
        state: businessDetails.state.trim() || undefined,
        zipcode: businessDetails.zipcode.trim() || undefined,
      });

      setActiveStep(2);
      form.clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLocationValidating(false);
    }
  }, [
    businessDetails.city,
    businessDetails.country,
    businessDetails.number,
    businessDetails.state,
    businessDetails.street,
    businessDetails.zipcode,
    form,
    setError,
    trigger,
  ]);

  const onBusinessScheduleSubmit = useCallback(async () => {
    const isValid = await trigger('schedule');
    if (!isValid) {
      return;
    }

    setActiveStep(3);
  }, [trigger]);

  const onThreeTagsSubmit = () => {
    setActiveStep(4);
  };

  const onFifteenTagsSubmit = () => {
    setActiveStep(5);
  };

  const onQuestionsSubmit = useCallback(async () => {
    const isValid = await trigger('questions');
    if (!isValid) {
      return;
    }

    setActiveStep(6);
  }, [trigger]);

  const isMemberProvided = member.email || member.fullName || member.permission;

  const onMemberSubmit = useCallback(async () => {
    try {
      if (isMemberProvided && !(await trigger('member'))) {
        return;
      }

      await dispatch.units.createBusinessUnit({
        businessType,
        businessDetails,
        schedule,
        questions,
        tags,
        member,
        receipts,
      });

      handleAddBusinessClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    }
  }, [
    businessDetails,
    businessType,
    dispatch.units,
    handleAddBusinessClose,
    isMemberProvided,
    member,
    questions,
    receipts,
    schedule,
    setError,
    tags,
    trigger,
  ]);

  const modals = useMemo(
    () => [
      <Box className={styles.businessTypeContainer}>
        <BusinessTypeForm onSubmit={onBusinessTypeSubmit} hideThirdCategory />
      </Box>,
      <Box className={styles.businessDetailsContainer}>
        <Box className={styles.businessDetailsWithHeader}>
          <Typography variant="text-2xl">
            {t('Add.business.details')}
          </Typography>
          <Box display="flex" flexDirection="column" gap="24px" width="540px">
            <SignUpBusinessDetailsForm hideFirstLastName />

            {formState.errors.root?.serverError?.message ? (
              <ServerErrorMessage
                message={formState.errors.root.serverError.message}
              />
            ) : null}
          </Box>
        </Box>
        <Button fullWidth variant="contained" onClick={onBusinessDetailsSubmit}>
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}>
            {t('Next')}
          </Typography>
        </Button>
      </Box>,
      <Box className={styles.scheduleContainer}>
        <Box className={styles.scheduleHeader}>
          <Typography variant="text-2xl-light">
            {t('What.times.are.you.open?')}
          </Typography>
          <SignUpScheduleForm />
        </Box>
        <InfoLabel
          text={t(
            'Dont.worry.you.will.always.be.able.to.update.your.schedule.later',
          )}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={onBusinessScheduleSubmit}>
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}>
            {t('Next')}
          </Typography>
        </Button>
      </Box>,
      <SignUpPersonalizeThreeTagsTemplate
        onSubmit={onThreeTagsSubmit}
        withBackButton={false}
        withUseAsDropdown
      />,
      <SignUpPersonalizeFifteenTagsTemplate
        onSubmit={onFifteenTagsSubmit}
        withLabel={false}
        withBackButton={false}
        withUseAsDropdown
      />,
      <SignUpPersonalizeQuestionsTemplate
        onSubmit={onQuestionsSubmit}
        withBackButton={false}
        withUseAsDropdown
      />,
      <Box className={styles.memberContainer}>
        <Typography variant="text-2xl">{t('Add.members')}</Typography>
        <AddMembersForm />
        {isMemberProvided ? (
          <Button variant="contained" fullWidth onClick={onMemberSubmit}>
            <Typography
              variant="text-base-semibold"
              color={Colors['default-gray-900']}>
              {t('Create.Business')}
            </Typography>
          </Button>
        ) : (
          <GradientOutlinedButton onClick={onMemberSubmit}>
            {t('Skip.and.Create.Business')}
          </GradientOutlinedButton>
        )}
      </Box>,
    ],
    [
      t,
      formState.errors.root?.serverError.message,
      onBusinessDetailsSubmit,
      onBusinessScheduleSubmit,
      onQuestionsSubmit,
      isMemberProvided,
      onMemberSubmit,
    ],
  );

  const [isSubmitModalOpen, setSubmitModalOpen] = useState<boolean>(false);

  const handleCloseSubmitted = () => {
    setSubmitModalOpen(false);
    handleAddBusinessClose();
  };

  const handleGoBackClick = () => {
    setActiveStep((s) => s - 1);
    form.clearErrors('root.serverError');
  };

  return (
    <>
      <SettingsModal open={open} onClose={() => setSubmitModalOpen(true)}>
        {isLoading && <LoaderOverlay />}
        <FormProvider
          trigger={trigger}
          handleSubmit={handleSubmit}
          reset={reset}
          setError={setError}
          formState={formState}
          {...form}>
          <LoaderOverlay2 loading={isLocationValidating}>
            <Box>
              <AddBusinessHeader
                activeStep={activeStep}
                onClick={handleGoBackClick}
              />
              {modals[activeStep]}
            </Box>
          </LoaderOverlay2>
        </FormProvider>
      </SettingsModal>

      <SettingsModal
        open={isSubmitModalOpen}
        onClose={() => setSubmitModalOpen(false)}>
        <div>
          <Typography variant="text-lg">
            {t('Are.you.sure.you.want.to.close.the.form?')}
          </Typography>
          <div className={styles.submitModalButtons}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setSubmitModalOpen(false)}>
              <Typography color={Colors['bg-color']} variant="text-sm">
                {t('Cancel')}
              </Typography>
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCloseSubmitted}>
              <Typography color={Colors['bg-color']} variant="text-sm">
                {t('Close')}
              </Typography>
            </Button>
          </div>
        </div>
      </SettingsModal>
    </>
  );
};

export default AddBusiness;
