import {Box} from '@mui/material';

import styles from './ChartSet.module.scss';
import ChartRow from 'components/atoms/chartRow/ChartRow';
import {items} from './constant';
import {Endorsements} from '../chartCard/ChartCard';

interface IPops {
  itemsData: Endorsements;
}

const ChartSet = ({itemsData}: IPops) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.column}>
        {items.slice(0, 3).map((item, index) => (
          <ChartRow
            key={`${item.title}-${index}`}
            title={item.title}
            color={item.color}
            count={itemsData[item.value as keyof Endorsements]}
          />
        ))}
      </Box>

      <Box className={styles.column}>
        {items.slice(3, 6).map((item, index) => (
          <ChartRow
            key={`${item.title}-${index}`}
            title={item.title}
            color={item.color}
            count={itemsData[item.value as keyof Endorsements]}
          />
        ))}
      </Box>

      <Box className={styles.column}>
        {items.slice(6).map((item, index) => (
          <ChartRow
            key={`${item.title}-${index}`}
            title={item.title}
            color={item.color}
            count={itemsData[item.value as keyof Endorsements]}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ChartSet;
