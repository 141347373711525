import {Box} from '@mui/material';
import IconMark from 'components/atoms/icon/IconMark';
import {FC} from 'react';
import Colors from 'styles/colors.scss';
import styles from './FeaturedWaterfallView.module.scss';
import WaterfallDropdown from './WaterfallDropdown';

interface Props {
  waterfallSrc?: string | null;
  waterfallType?: string | null;
  handleOpenCarousel: () => void;
  handleDeleteWaterfall: () => void;
  handleReportWaterfall: () => void;
}

const FeaturedWaterfallView: FC<Props> = ({
  waterfallSrc,
  waterfallType,
  handleOpenCarousel,
  handleDeleteWaterfall,
  handleReportWaterfall,
}) => {
  return (
    <div className={styles.waterfall}>
      {waterfallType === 'video'
        ? waterfallSrc && (
            <video
              className={styles.media}
              src={waterfallSrc}
              loop
              muted
              onClick={handleOpenCarousel}
            />
          )
        : waterfallSrc && (
            <img
              className={styles.media}
              src={waterfallSrc}
              alt="waterfall"
              draggable={false}
              onClick={handleOpenCarousel}
            />
          )}

      <Box className={styles.iconsBlock}>
        {waterfallType === 'video' ? (
          <IconMark name="camera1" color={Colors['white']} size="38px" />
        ) : (
          <Box />
        )}
      </Box>

      <WaterfallDropdown
        handleReportWaterfall={handleReportWaterfall}
        handleDeleteWaterfall={handleDeleteWaterfall}
        style={{top: '16px', right: '16px'}}
      />
    </div>
  );
};

export default FeaturedWaterfallView;
