import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'store/index';
import {RootState} from 'store/index';

const useConsumerDemographics = () => {
  const dispatch = useDispatch<Dispatch>();

  const {counters, graph} = useSelector(
    (state: RootState) => state.consumer_demographics,
  );

  const getConsumerDemographicsCounters = (
    businessUnitId: string,
    startDate: string,
    endDate: string,
  ) => {
    dispatch.consumer_demographics.getConsumerDemographicsCounters({
      businessUnitId,
      startDate,
      endDate,
    });
  };

  const getConsumerDemographicsGraph = (
    businessUnitId: string,
    startDate: string,
    endDate: string,
  ) => {
    dispatch.consumer_demographics.getConsumerDemographicsGraphData({
      businessUnitId,
      startDate,
      endDate,
    });
  };

  return {
    counters,
    graph,
    getConsumerDemographicsCounters,
    getConsumerDemographicsGraph,
  };
};

export default useConsumerDemographics;
