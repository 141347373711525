import {SignUpLayout} from 'components/templates';
import {ROUTES} from 'environment/constants/routes';
import {
  SignUpBusinessDetails,
  SignUpBusinessType,
  SignUpMain,
  SignUpMembers,
  SignUpOtp,
  SignUpPersonalizeFifteenTags,
  SignUpPersonalizeQuestions,
  SignUpPersonalizeThreeTags,
  SignUpSchedule,
} from 'pages';
import {Route} from 'react-router-dom';
import {BusinessProvider, SignUpMainProvider} from './providers';
import {AuthRoutes} from './routes';

export const signupRoutes = (
  <Route path={ROUTES.signup.home} element={<SignUpLayout />}>
    <Route element={<AuthRoutes />}>
      <Route element={<SignUpMainProvider />}>
        <Route index element={<SignUpMain />} />
        <Route path={ROUTES.signup.otp} element={<SignUpOtp />} />
      </Route>
    </Route>
    <Route element={<BusinessProvider />}>
      <Route path={ROUTES.signup.type} element={<SignUpBusinessType />} />
      <Route path={ROUTES.signup.details} element={<SignUpBusinessDetails />} />
      <Route path={ROUTES.signup.schedule} element={<SignUpSchedule />} />
      <Route
        path={ROUTES.signup.threeTags}
        element={<SignUpPersonalizeThreeTags />}
      />
      <Route
        path={ROUTES.signup.fifteenTags}
        element={<SignUpPersonalizeFifteenTags />}
      />
      <Route
        path={ROUTES.signup.mantras}
        element={<SignUpPersonalizeQuestions />}
      />
      <Route path={ROUTES.signup.members} element={<SignUpMembers />} />
    </Route>
  </Route>
);
