import {Pagination as MUIPagination} from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';

import styles from './Pagination.module.scss';
import {ReactComponent as ArrowAndPrev} from 'assets/icons/arrowAndPrev.svg';
import {ReactComponent as ArrowAndNext} from 'assets/icons/arrowAndNext.svg';

interface IProps {
  page: number;
  rowsPerPage: number;
  count: number;
  onChange: (_event: React.ChangeEvent<unknown>, newPage: number) => void;
}

const Pagination = ({page, count, rowsPerPage, onChange}: IProps) => {
  return (
    <MUIPagination
      count={Math.ceil(count / rowsPerPage)}
      page={page}
      onChange={onChange}
      classes={{text: styles.text}}
      renderItem={(item) => (
        <PaginationItem
          slots={{previous: ArrowAndPrev, next: ArrowAndNext}}
          {...item}
        />
      )}
    />
  );
};

export default Pagination;
