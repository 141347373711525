import {createTheme} from '@mui/material';

import Colors from 'styles/colors.scss';
import {MuiButton} from './MuiButton';
import {MuiDivider} from './MuiDivider';
import {MuiSelect} from './MuiSelect';
import {palette} from './palette';
import {typography} from './typography';

export const theme = createTheme({
  typography: typography,
  palette: palette,
  components: {
    ...MuiButton,
    ...MuiSelect,
    ...MuiDivider,
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: Colors.white,
        },
      },
    },
  },
});
