import axios from '../axios';
import {CONSUMER_DEMOGRAPHICS_URL} from 'environment/constants/url';

export const getConsumerDemographicsGraphRequest = (
  businessUnitId: string,
  startDate: string,
  endDate: string,
) => {
  return axios.get(
    CONSUMER_DEMOGRAPHICS_URL(businessUnitId, startDate, endDate).GRAPH,
  );
};

export const getConsumerDemographicsCountersRequest = (
  businessUnitId: string,
  startDate: string,
  endDate: string,
) => {
  return axios.get(
    CONSUMER_DEMOGRAPHICS_URL(businessUnitId, startDate, endDate).COUNTERS,
  );
};
