import React from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {PieChart, VisitorDemographics} from 'components/molecules';
import {items} from './constants';
import {IUserStatus, IDataByAge} from 'store/models/engagement';

import {pieChartData} from '../../../environment/configs/mockData';
import emptyCircle from 'assets/icons/greenEmptyCircle.svg';

import styles from './DemographicsBlock.module.scss';

interface IProps {
  dataByAge: IDataByAge[];
  userStatus: IUserStatus;
  title: string;
  isUnavailable: boolean;
}

const DemographicsBlock = ({
  dataByAge,
  userStatus,
  title,
  isUnavailable,
}: IProps) => {
  const {t} = useTranslation();

  const userStatusArray = Object.entries(userStatus).map(([name, y]) => ({
    name,
    y,
  }));

  const getData = () => {
    return items.map((item) => {
      const translatedTitle = t(item.title);
      const matchingData = userStatusArray.find(
        (data) => data.name === item.value,
      );

      return {
        name: translatedTitle,
        y: matchingData ? matchingData.y : 0,
      };
    });
  };

  const isData = getData();

  const shouldRenderEmptyChart = (data: {name: string; y: number}[]) => {
    const allZeros = data.every((item) => item.y === 0);

    return isUnavailable && allZeros;
  };

  const renderEmptyChart = shouldRenderEmptyChart(isData);

  return (
    <Box className={styles.demographicsBlock}>
      <VisitorDemographics
        data={dataByAge}
        title={title}
        isUnavailable={isUnavailable}
      />
      <Box className={styles.pieChartWrapper}>
        {renderEmptyChart ? (
          <Box className={styles.emptyChart}>
            <Box component="img" src={emptyCircle} alt="empty circle" />
            <Typography
              className={styles.zeroNumber}
              variant="text-base-semibold">
              0%
            </Typography>
          </Box>
        ) : (
          <PieChart
            data={!isUnavailable ? pieChartData : getData()}
            colors={items.map((item) => item.color)}
            name="72"
            size={208}
          />
        )}

        <Box className={styles.pieChartInfoBlock}>
          {userStatusArray.map((value, index) => {
            return (
              <Box key={value.name}>
                <Box
                  sx={{backgroundColor: items[index].color}}
                  className={styles.circle}
                />
                <Typography variant="text-xs">
                  {t(items[index].title)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default DemographicsBlock;
