import avatarDefaultYellow from 'assets/icons/lemon-yellow-default.svg';
import avatarBlue from 'assets/icons/lemon-blue.svg';
import avatarOrange from 'assets/icons/lemon-orange.svg';
import avatarRed from 'assets/icons/lemon-red.svg';
import avatarPurple from 'assets/icons/lemon-purple.svg';

export const getDefaultAvatarByRating = (rating: number) => {
  switch (true) {
    case rating >= 0 && rating <= 2.4:
      return avatarDefaultYellow;
    case rating > 2.4 && rating <= 4.8:
      return avatarBlue;
    case rating > 4.8 && rating <= 7.2:
      return avatarOrange;
    case rating > 7.2 && rating <= 9.6:
      return avatarRed;
    case rating > 9.6 && rating <= 12:
      return avatarPurple;
    default:
      return avatarDefaultYellow;
  }
};
