import Colors from 'styles/colors.scss';
import {AnalyticsCategory} from 'types/business-unit-analytics';

export const ANALYTICS_CATEGORIES = [
  'comment',
  'forum-tag',
  'like',
  'page-visit',
  'reviews',
  'share',
  'story-tag',
  'watch',
] as const;

export const ANALYTICS_COLORS: Record<AnalyticsCategory, string> = {
  reviews: Colors['default-green-900'],
  like: Colors['default-green-800'],
  comment: Colors['default-green-700'],
  share: Colors['default-green-600'],
  watch: Colors['default-green-500'],
  'story-tag': Colors['default-green-400'],
  'forum-tag': Colors['default-green-300'],
  'page-visit': Colors['default-green-200'],
};

export const DONUT_COLORS = Object.values(ANALYTICS_COLORS);
