import {REPORTS} from 'environment/constants/url';
import axios from '../axios';

export const reportPublication = (
  subjectPublication: 'review' | 'waterfall' | 'comment',
  subjectPublicationId: string,
) => {
  return axios.post(REPORTS.PUBLICATION, {
    subjectPublication,
    subjectPublicationId,
  });
};
