import moment from 'moment';

import {IDayDataItem} from 'store/models/engagement';

export function countDataInHoursRange(
  data: IDayDataItem[],
): [string, number][] {
  const hourCount: Record<string, number> = {};

  data.forEach((item) => {
    const createdAt = moment.utc(item.createdAt);
    const formattedHour = createdAt.format('ha');

    if (hourCount[formattedHour]) {
      hourCount[formattedHour]++;
    } else {
      hourCount[formattedHour] = 1;
    }
  });

  const result: [string, number][] = [];

  const now = moment();
  const currentHour = now.hour();

  for (let i = 24; i >= 0; i--) {
    const formattedHour = moment().subtract(i, 'hours').format('ha');
    const count = hourCount[formattedHour] || 0;
    result.push([formattedHour, count]);
  }

  return result;
}
