import {ClearOutlined} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';
import styles from './ReceiptImage.module.scss';
import {useEffect} from 'react';

interface IProps {
  file: File;
  onClearButtonClick: () => void;
  size?: number;
}

const ReceiptImage = ({file, onClearButtonClick, size = 100}: IProps) => {
  const src = URL.createObjectURL(file);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(src);
    };
  }, [src]);

  return (
    <Box className={styles.container}>
      <img
        width={size}
        height={size}
        src={src}
        alt={file.name}
        className={styles.image}
        draggable={false}
      />
      <IconButton className={styles.iconButton} onClick={onClearButtonClick}>
        <ClearOutlined className={styles.clearIcon} />
      </IconButton>
    </Box>
  );
};

export default ReceiptImage;
