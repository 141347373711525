import {Box, Button, Divider, Typography} from '@mui/material';
import {IconMark} from 'components/atoms';
import {SettingsModals} from 'environment/types';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import {
  ChangeEmailConfirmPasswordModal,
  ChangeEmailModal,
  ChangePasswordModal,
  PasswordRecoveryEmailModal,
  PasswordRecoveryModal,
} from '../../modals';
import styles from './GeneralSection.module.scss';

interface IProps {
  email: string;
  modal: SettingsModals | null;
  setModal: (modal: SettingsModals | null) => void;
  prevModal: SettingsModals | null;
  setPrevModal: (modal: SettingsModals | null) => void;
  closeModal: () => void;
}

const GeneralSection: FC<IProps> = ({
  email,
  modal,
  setModal,
  prevModal,
  setPrevModal,
  closeModal,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <ChangeEmailConfirmPasswordModal
        open={modal === 'change-email-verify'}
        onForgot={() => {
          setPrevModal('change-email-verify');
          setModal('password-recovery-verify');
        }}
        onClose={closeModal}
        onContinue={() => setModal('change-email')}
      />

      <ChangeEmailModal
        open={modal === 'change-email'}
        onBack={() => setModal('change-email-verify')}
        onClose={closeModal}
      />

      <ChangePasswordModal
        open={modal === 'change-password'}
        onForgot={() => {
          setPrevModal('change-password');
          setModal('password-recovery-verify');
        }}
        onClose={closeModal}
      />

      <PasswordRecoveryEmailModal
        open={modal === 'password-recovery-verify'}
        onBack={() => setModal(prevModal)}
        onClose={closeModal}
      />

      <PasswordRecoveryModal
        open={modal === 'password-recovery'}
        onClose={closeModal}
      />

      <Divider
        orientation="horizontal"
        sx={{marginTop: '24px', marginBottom: '16px'}}
      />

      <Box className={styles.container}>
        <Box className={styles.leftSection}>
          <Typography variant="text-base-light">
            {t('fields.password')}
          </Typography>
          <Typography
            variant="text-base-light"
            color={Colors['default-gray-200']}>
            **** ****
          </Typography>
        </Box>
        <Button
          variant="text"
          className={styles.textButton}
          onClick={() => setModal('change-password')}>
          <Typography variant="text-sm-light">
            {t('Change.password')}
          </Typography>
        </Button>
      </Box>

      <Divider orientation="horizontal" sx={{marginY: '16px'}} />

      <Box className={styles.container}>
        <Box className={styles.leftSection}>
          <Box className={styles.emailVerified}>
            <Typography variant="text-base-light">
              {t('fields.email')}
            </Typography>
            <IconMark name="tick" color={Colors['HEXB8FE61']} size="20px" />
          </Box>
          <Typography
            variant="text-base-light"
            color={Colors['default-gray-200']}>
            {email}
          </Typography>
        </Box>
        <Button
          variant="text"
          className={styles.textButton}
          onClick={() => setModal('change-email-verify')}>
          <Typography variant="text-sm-light">{t('Change.email')}</Typography>
        </Button>
      </Box>

      <Divider />
    </>
  );
};

export default GeneralSection;
