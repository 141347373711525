import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'store/index';
import {RootState} from 'store/index';

const useReviewReply = () => {
  const dispatch = useDispatch<Dispatch>();

  const {allReviewReplies} = useSelector(
    (state: RootState) => state.reviewReply,
  );

  const getAllReviewReplies = (
    reviewId: string,
    take: number,
    cursorId?: string,
  ) => {
    dispatch.reviewReply.getAllReviewReplies({
      reviewId,
      take,
      cursorId,
    });
  };

  const replyOnReview = (reviewId: string, reply: string, images?: any) => {
    dispatch.reviewReply.replyOnReview({
      reviewId,
      reply,
      images,
    });
  };

  return {
    allReviewReplies,
    getAllReviewReplies,
    replyOnReview,
  };
};

export default useReviewReply;
