import {createModel} from '@rematch/core';
import type {RootModel} from '.';
import {
  getDashboardTotalActivityRequest,
  getDashboardEngagementsQuantityRequest,
  getDashboardRecentActivityRequest,
  getDashboardConsumerDemographicsRequest,
  getDashboardAllRecentActivityRequest,
  getDahboardComparedToWaterfallsRequest,
} from './../../environment/api/services/dashboard';

export interface ITotalActivity {
  engagements: {
    change: number;
    total: number;
    last7days: {
      date: string;
      count: number;
    }[];
  };
  engagers: {
    change: number;
    total: number;
    last7days: {
      date: string;
      count: number;
    }[];
  };
}

export interface IEngagementsQuantityForDay {
  date: string;
  count: number;
  change: null | number;
}

export interface IRecentActivityItem {
  id: string;
  userId: string;
  mediaId: string | null;
  lastName: string;
  firstName: string;
  rating: number;
  createdAt: string;
  engagementType:
    | 'review'
    | 'like'
    | 'comment'
    | 'share'
    | 'watch'
    | 'pageVisit'
    | 'forumTag'
    | 'storyTag';
}

export interface IRecentActivity {
  totalCount: number;

  recentActivity: IRecentActivityItem[];
  hasNextPage: boolean;
}

export interface IConsumerAgeGroup {
  group: {
    min: number;
    max: number;
  };
  quantity: number;
}

export interface IConsumerDemographics {
  ageGroups: IConsumerAgeGroup[];
}

export interface IComparedToWaterfalls {
  totalItems: number;
  featuredPercentage: number;
  nonFeaturedPercentage: number;
  percentageChangeToLastWeek: number | null;
  totalEngagemntScore: number;
  featuredEngagementScorePercentage: number;
  nonFeaturedEngagementScorePercentage: number;
  percentageEngagementScoreChangeToLastWeek: number | null;
}

interface IDashboardState {
  totalActivity: ITotalActivity;
  engagementsQuantity: IEngagementsQuantityForDay[];
  recentActivity: IRecentActivity;
  consumerDemographics: IConsumerDemographics;
  comparedToWaterfalls: IComparedToWaterfalls;
}

const initialState: IDashboardState = {
  totalActivity: {
    engagements: {
      total: 0,
      change: 0,
      last7days: [],
    },
    engagers: {
      total: 0,
      change: 0,
      last7days: [],
    },
  },
  engagementsQuantity: [],
  recentActivity: {
    totalCount: 0,

    recentActivity: [],
    hasNextPage: true,
  },
  consumerDemographics: {
    ageGroups: [
      {
        group: {
          min: 0,
          max: 0,
        },
        quantity: 0,
      },
    ],
  },
  comparedToWaterfalls: {
    totalItems: 0,
    featuredPercentage: 0,
    nonFeaturedPercentage: 0,
    percentageChangeToLastWeek: null,
    totalEngagemntScore: 0,
    featuredEngagementScorePercentage: 0,
    nonFeaturedEngagementScorePercentage: 0,
    percentageEngagementScoreChangeToLastWeek: null,
  },
};

export const dashboard = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updatedTotalActivity: (state, payload) => {
      return {
        ...state,
        totalActivity: payload,
      };
    },
    updateEngagementsQuantity: (state, payload) => {
      return {
        ...state,
        engagementsQuantity: payload,
      };
    },

    setRecentActivityTotalCount: (state, payload) => {
      return {
        ...state,
        recentActivity: {
          ...state.recentActivity,
          totalCount: payload,
        },
      };
    },

    setRecentActivity: (state, payload: IRecentActivityItem[]) => {
      return {
        ...state,
        recentActivity: {
          ...state.recentActivity,
          recentActivity: [...state.recentActivity.recentActivity, ...payload],
        },
      };
    },

    setRecentActivityHasNextPage: (state, hasNextPage: boolean) => {
      return {...state, recentActivity: {...state.recentActivity, hasNextPage}};
    },

    updateConsumerDemographics: (state, payload) => {
      return {
        ...state,
        consumerDemographics: payload,
      };
    },

    updateComparedToWaterfalls: (state, payload) => {
      return {
        ...state,
        comparedToWaterfalls: payload,
      };
    },

    resetState: () => ({...initialState}),
  },
  effects: (dispatch) => ({
    getDashboardTotalActivity: async (businessUnitId: string) => {
      try {
        const {data} = await getDashboardTotalActivityRequest(businessUnitId);
        dispatch.dashboard.updatedTotalActivity(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardEngagementsQuantity: async (businessUnitId: string) => {
      try {
        const {data} = await getDashboardEngagementsQuantityRequest(
          businessUnitId,
        );

        dispatch.dashboard.updateEngagementsQuantity(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getAllRecentActivity: async (businessUnitId: string) => {
      try {
        const {data} = await getDashboardAllRecentActivityRequest(
          businessUnitId,
        );

        dispatch.dashboard.setRecentActivityTotalCount(data.totalCount);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardRecentActivity: async (businessUnitId: string) => {
      try {
        dispatch.dashboard.setRecentActivityHasNextPage(true);

        const {
          data: {recentActivity},
        } = await getDashboardRecentActivityRequest(businessUnitId);

        dispatch.dashboard.setRecentActivity(recentActivity);
        return Promise.resolve(recentActivity);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getMoreDashboardRecentActivity: async (_: void, state) => {
      try {
        const businessUnitId = state.units.currentBusinessUnitId;

        const lastCreatedAt =
          state.dashboard.recentActivity.recentActivity.at(-1)?.createdAt;

        if (!lastCreatedAt || !businessUnitId) {
          return;
        }

        const {
          data: {recentActivity},
        } = await getDashboardRecentActivityRequest(
          businessUnitId,
          lastCreatedAt,
        );

        if (!recentActivity.length) {
          dispatch.dashboard.setRecentActivityHasNextPage(false);
        } else {
          dispatch.dashboard.setRecentActivity(recentActivity);
        }

        // todo: Can't neither resolve nor just return the value due to
        // Typescript's circular referencing error of Rematch state.
        // –> Need further investigation

        // return Promise.resolve(recentActivity)
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardConsumerDemographics: async (businessUnitId: string) => {
      try {
        const {data} = await getDashboardConsumerDemographicsRequest(
          businessUnitId,
        );

        dispatch.dashboard.updateConsumerDemographics(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardComparedToWaterfalls: async (businessUnitId: string) => {
      try {
        const {data} = await getDahboardComparedToWaterfallsRequest(
          businessUnitId,
        );

        dispatch.dashboard.updateComparedToWaterfalls(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
});
