import styles from './BasicChartWithBenchmark.module.scss';
import BasicArea from 'components/molecules/charts/basic-area/BasicArea';
import Benchmark from 'components/atoms/benchmark/Benchmark';
import {RangeButtons} from 'environment/types';
import {Box} from '@mui/material';

interface IProps {
  data: any;
  title: string | React.ReactNode;
  subtitle?: string;
  colors: string[];
  gradient?: [number, string][];
  categories?: string[];
  rangeButtons?: RangeButtons[];
  name?: string | undefined;
  onClickBtn?: (value: string) => void;
  isPremium?: boolean;
  isEmpty?: boolean;
}

const BasicChartWithBenchmark = ({
  data,
  title,
  subtitle = '',
  colors,
  gradient = [],
  categories = [],
  rangeButtons = [],
  name,
  onClickBtn = () => {},
  isPremium = false,
  isEmpty,
}: IProps) => {
  return (
    <div className={styles.wrapper}>
      <Box className={styles.chartWrapper}>
        <BasicArea
          data={data}
          categories={categories}
          colors={colors}
          title={title}
          subtitle={subtitle}
          gradient={gradient}
          rangeButtons={rangeButtons}
          onClickBtn={onClickBtn}
          name={name}
          isEmpty={isEmpty}
        />
      </Box>
      {/* <Benchmark data={data} isPremium={isPremium} colors={colors} /> */}
    </div>
  );
};

export default BasicChartWithBenchmark;
