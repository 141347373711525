import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import QRcodeBusiness from 'assets/icons/QRcodeBusiness.svg';
import {ReactComponent as LogoAndText} from 'assets/icons/logoAndText.svg';
import {Input} from 'components/atoms';
import styles from './ReferBusiness.module.scss';
import Colors from 'styles/colors.scss';
import IconMark from 'components/atoms/icon/IconMark';
import SettingsModal from '../settingsModal/SettingsModal';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ReferBusiness = ({open, onClose, onSubmit}: IProps) => {
  const {t} = useTranslation();

  return (
    <SettingsModal
      open={open}
      onClose={onClose}
      title={t('Refer.a.business')}
      withCloseButton
      comingSoon>
      <Box className={styles.imageWrapper}>
        <LogoAndText />
        <Box className={styles.QRcodeAndTextBlock}>
          <BusinessText />
          <Box className={styles.QRcode} component="img" src={QRcodeBusiness} />
        </Box>
        <Typography fontWeight={500} variant="text-xs">
          {t('Scan.QR.code.and.join.me.at.NLNM')}
        </Typography>
      </Box>
      <form autoComplete="off" onSubmit={onSubmit}>
        <Input
          name="email"
          type="email"
          label={t('fields.email')}
          placeholder={t('fields.email')}
        />
        <Box className={styles.footer}>
          <Box className={styles.linkBlock}>
            <IconMark
              name="link-mark"
              color={Colors['HEXB8FE61']}
              size="24px"
            />
            <Typography
              variant="text-base-semibold"
              onClick={() => {
                navigator.clipboard.writeText('some text'); // TODO add link on business
              }}>
              {t('Copy.link')}
            </Typography>
          </Box>
          <Button
            type="submit"
            className={styles.submitBtn}
            variant="contained"
            size="large">
            {t('Send.invite')}
          </Button>
        </Box>
      </form>
    </SettingsModal>
  );
};

const BusinessText = () => {
  const {t} = useTranslation();

  return (
    <Box className={styles.textBlock}>
      <Typography fontWeight={500} variant="text-2xl">
        {t('Verified')}{' '}
      </Typography>
      <Typography
        className={styles.highlightedText}
        fontWeight={500}
        variant="text-2xl">
        {t('Reviews')}
        <Box className={styles.whiteComma}>,</Box>
      </Typography>
      <Typography fontWeight={500} variant="text-2xl">
        {t('Customer')}{' '}
      </Typography>
      <Typography
        className={styles.highlightedText}
        fontWeight={500}
        variant="text-2xl">
        {t('Analytics')}
        <Box className={styles.whiteComma}>,</Box>
      </Typography>
      <Typography
        className={styles.highlightedText}
        fontWeight={500}
        variant="text-2xl">
        {t('Content.Creation')}{' '}
      </Typography>
      <Typography fontWeight={500} variant="text-2xl">
        {t('done.for.you')}
      </Typography>
    </Box>
  );
};

export default ReferBusiness;
