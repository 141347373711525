import moment from 'moment';
import {IMonthData} from 'store/models/engagement';

const monthArrayLengths: {[key: string]: number} = {
  '0': 31,
  '1': 28,
  '2': 31,
  '3': 30,
  '4': 31,
  '5': 30,
  '6': 31,
  '7': 31,
  '8': 30,
  '9': 31,
  '10': 30,
  '11': 31,
};

export const countDataInMonthsRange = (
  monthData: IMonthData,
): Array<{[key: string]: number[]}> => {
  const results: Array<{[key: string]: number[]}> = [];

  for (const month in monthData) {
    const monthLength = monthArrayLengths[month] || 30;
    const counts: number[] = Array(monthLength).fill(0);

    monthData[month].forEach((item) => {
      const dayOfMonth = moment(item.createdAt).date();
      counts[dayOfMonth - 1]++;
    });

    const resultObject: {[key: string]: number[]} = {};
    resultObject[month] = counts;
    results.push(resultObject);
  }

  return results;
};
