import {LoaderOverlay} from 'components/atoms';
import {SignUpBusinessTypeTemplate} from 'components/templates';
import axios from 'axios';
import {signUpBusinessType} from 'environment/api/services/auth';
import {useSignUpRoutes} from 'hooks/signUpNavigation';
import {type SignUpBusinessFormData} from 'hooks/forms';
import {castBusinessTypeForBackend} from 'hooks/forms/helpers';
import {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

const SignUpBusinessType = () => {
  const {control, setError, clearErrors} =
    useFormContext<SignUpBusinessFormData>();

  const businessType = useWatch({control, name: 'businessType'});

  const signUpRoutes = useSignUpRoutes();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (businessType.type === '') {
      return;
    }

    try {
      setIsLoading(true);

      await signUpBusinessType({
        type: castBusinessTypeForBackend(businessType),
      });

      navigate(signUpRoutes.details);
      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpBusinessTypeTemplate onSubmit={onSubmit} />
    </>
  );
};

export default SignUpBusinessType;
