import {TableBody, TableCell, TableRow} from '@mui/material';
import {TableHeader, TableRoot} from 'components/atoms/tablePrimitives';
import {TablePagination} from 'components/molecules';
import {Order} from 'environment/types';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './RecentActivityReviewsTable.module.scss';
import {columns} from './columns';
import {IReviewProcessed} from 'types';

interface IProps {
  tableData: IReviewProcessed[];
}

const RecentActivityReviewsTable = ({tableData}: IProps) => {
  const {t} = useTranslation();

  type Property = (typeof columns)[number]['id'];

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<Property>('views');
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleRequestSort = (property: Property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    const sortedRows = [...tableData].sort((a, b) => {
      const aValue = (a as any)[orderBy] ?? '';
      const bValue = (b as any)[orderBy] ?? '';

      if (aValue < bValue) return -1;
      else if (aValue > bValue) return 1;
      else return 0;
    });

    if (order === 'desc') return sortedRows.reverse();

    return sortedRows;
  }, [tableData, order, orderBy]);

  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleChageRowsPerPage = (value: string) => {
    setRowsPerPage(parseInt(value));
    setPage(1);
  };

  return (
    <>
      <TableRoot
        title={t('Reviews')}
        pagination={
          <TablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            count={tableData.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChageRowsPerPage}
          />
        }>
        <TableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {tableData &&
            visibleRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <TableCell
                    key={column.id}
                    className={styles.cell}
                    align={columnIndex > 0 ? 'center' : 'left'}
                    padding="none">
                    {column.renderCell(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </TableRoot>
    </>
  );
};

export default RecentActivityReviewsTable;
