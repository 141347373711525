import {
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import {GradientIcon} from 'components/molecules';
import {Order} from 'environment/types';
import styles from './TableHeader.module.scss';

interface Column {
  id: string;
  label: React.ReactNode;
  sortable: boolean;
  alignCenter: boolean;
  sx?: TableCellProps['sx'];
}

interface IProps {
  columns: readonly Column[];
  onRequestSort: (property: any) => void;
  order: Order;
  orderBy: string | number | symbol;
}

const IconComponent = () => {
  return <GradientIcon name="opposite-arrows" size="16px" />;
};

function TableHeader({columns, onRequestSort, order, orderBy}: IProps) {
  return (
    <TableHead>
      <TableRow>
        {columns.map(({id, label, sortable, alignCenter, sx}) => (
          <TableCell
            key={id}
            sx={sx}
            className={styles.cell}
            align={alignCenter ? 'center' : 'left'}
            sortDirection={orderBy === id ? order : false}>
            {sortable ? (
              <TableSortLabel
                className={styles.sortLabel}
                IconComponent={IconComponent}
                onClick={() => onRequestSort(id)}>
                <Typography whiteSpace="nowrap" variant="text-xs">
                  {label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="text-xs">{label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
