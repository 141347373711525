import {IconMark} from 'components/atoms';
import {FC} from 'react';
import Gradient from 'styles/gradients.scss';
import styles from './GradientIcon.module.scss';

interface Props {
  name: string;
  size: string;
  gradient?: string;
}

const GradientIcon: FC<Props> = ({name, size, gradient = Gradient.default}) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: gradient,
      }}>
      <IconMark name={name} size={size} />
    </div>
  );
};

export default GradientIcon;
