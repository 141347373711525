// label is the i18n property

export const businessTypes = [
  {
    id: 'food-truck',
    label: 'Food.Truck',
    icon: 'truck',
    checkboxes: [
      {name: 'stationary', label: 'I.am.stationary'},
      {name: 'move-around', label: 'I.move.around'},
    ],
  },
  {
    id: 'one-location',
    label: 'One.Location',
    icon: 'home-simple-door',
    checkboxes: null,
  },
  {
    id: 'multiple-locations',
    label: 'Franchise/Multiple.locations',
    icon: 'neighbourhood',
    checkboxes: [
      {name: 'fixed-locations', label: 'We.have.fixed.locations'},
      {name: 'food-trucks', label: 'Food.Trucks'},
    ],
  },
];
