import {TableBody, TableCell, TableRow} from '@mui/material';
import {TableHeader, TableRoot} from 'components/atoms/tablePrimitives';
import {Order} from 'environment/types';
import {useMemo, useState} from 'react';
import styles from './DemographicsTable.module.scss';
import {columns} from './columns';
import {CounterData} from 'store/models/consumer-demographics';

interface IProps {
  tableData: CounterData[];
}

function DemographicsTable({tableData}: IProps) {
  type Property = keyof (typeof tableData)[number];

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<Property>('group');

  const handleRequestSort = (property: Property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    const sortedRows = [...tableData].sort((a, b) => {
      if (b[orderBy] < a[orderBy]) return -1;
      else if (b[orderBy] > a[orderBy]) return 1;
      else return 0;
    });

    if (order === 'desc') return sortedRows.reverse();

    return sortedRows;
  }, [tableData, order, orderBy]);

  return (
    <TableRoot>
      <TableHeader
        columns={columns}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {visibleRows.map((row, index) => (
          <TableRow key={`${row.group.min} + ${index}`}>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                className={styles.cell}
                align={column.alignCenter ? 'center' : 'left'}
                padding="none">
                {column.renderCell(row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableRoot>
  );
}

export default DemographicsTable;
