import {Box, Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import styles from './ErrorOrSuccess.module.scss';
import {ROUTES} from 'environment/constants/routes';

interface IProps {
  title: string;
  secondButtonLabel: string;
  image: string;
  onClickSecondButton: () => void;
}

const ErrorOrSuccess = ({
  title,
  secondButtonLabel,
  image,
  onClickSecondButton,
}: IProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(ROUTES.dashboard.home);
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.contentBlock}>
        <Box component="img" src={image} />
        <Typography component="h1" variant="app-title">
          {title}
        </Typography>
        <Box className={styles.buttonsBlock}>
          <Button onClick={navigateToHome} size="small" variant="outlined">
            <Typography variant="text-sm-semibold">
              {t('Go.to.home')}
            </Typography>
          </Button>
          <Button
            onClick={onClickSecondButton}
            size="small"
            variant="contained">
            <Typography variant="text-sm-semibold">
              {secondButtonLabel}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorOrSuccess;
