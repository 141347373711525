import {Box, Button, Typography} from '@mui/material';
import {GoBackLink} from 'components/atoms';
import {TagDropdown, UpgradeToPremium} from 'components/molecules';
import {UseSameAsDropdown} from 'components/organisms';
import {MAIN_DROPDOWNS} from 'environment/constants/tags';
import {useSignUpRoutes} from 'hooks/signUpNavigation';
import {type SignUpBusinessFormData} from 'hooks/forms';
import {type FC} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './SignUpPersonalizeThreeTags.module.scss';

interface IProps {
  onSubmit: () => void;
  withBackButton?: boolean;
  withUseAsDropdown?: boolean;
}

const SignUpPersonalizeThreeTagsTemplate: FC<IProps> = ({
  onSubmit,
  withBackButton = true,
  withUseAsDropdown,
}) => {
  const {t} = useTranslation();

  const signUpRoutes = useSignUpRoutes();

  const {control, clearErrors} = useFormContext<SignUpBusinessFormData>();
  const [cuisine, vibeAndService, ambiance] = useWatch({
    control,
    name: [
      'tags.cuisineLabel',
      'tags.vibeAndServiceLabel',
      'tags.ambianceLabel',
    ],
  });

  return (
    <Box className={styles.headerAndSelects}>
      <Box className={styles.header}>
        {withBackButton && (
          <Box alignSelf="flex-start">
            <GoBackLink
              navigateTo={signUpRoutes.schedule}
              onClick={() => clearErrors('root.serverError')}
            />
          </Box>
        )}
        <Typography variant="small-app-title">
          {t('Personalize.your.business')}
        </Typography>
        <Typography variant="text-lg">
          {t('Select.one.tag.from.each.category')}
        </Typography>
      </Box>

      <Box className={styles.selectsWithButton}>
        <Box className={styles.dropdowns}>
          {MAIN_DROPDOWNS.map(({name, emoji, items}) => (
            <TagDropdown
              key={name}
              category={name}
              name={`tags.${name}`}
              label={t(`tagsCategories.${name}`)}
              emoji={emoji}
              items={items}
            />
          ))}
        </Box>

        {withUseAsDropdown && (
          <UseSameAsDropdown field="three-tags" label={t('Use.same.tags.as')} />
        )}

        <Button
          variant="contained"
          fullWidth
          onClick={onSubmit}
          disabled={!cuisine || !vibeAndService || !ambiance}>
          <Typography
            variant="text-base-semibold"
            color={Colors['default-gray-900']}>
            {t('Next')}
          </Typography>
        </Button>
      </Box>

      <UpgradeToPremium title={t('Unlock.more.tags.for.your.business!')} />
    </Box>
  );
};

export default SignUpPersonalizeThreeTagsTemplate;
