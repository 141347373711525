import {toSortedTags} from './utils';

const FRESH_MEAT = [
  {
    id: 'butchers-selection',
    emoji: 'bg-butchersselection',
  },
  {
    id: 'premium-cuts',
    emoji: 'bg-premiumcuts',
  },
  {
    id: 'quality-meat',
    emoji: 'bg-qualitymeat',
  },
  {
    id: 'juicy-steaks',
    emoji: 'bg-juicysteaks',
  },
  {
    id: 'meat-delicacies',
    emoji: 'bg-meatdelicacies',
  },
  {
    id: 'butchers-counter',
    emoji: 'bg-butcherscounter',
  },
  {
    id: 'farm-fresh-meat',
    emoji: 'bg-farmfreshmeat',
  },
  {
    id: 'tender-meat',
    emoji: 'bg-tendermeat',
  },
  {
    id: 'burgers',
    emoji: 'bg-burgers',
  },
  {
    id: 'chicken-nuggets',
    emoji: 'bg-chickennuggets',
  },
  {
    id: 'meat-sandwiches',
    emoji: 'bg-meatsandwiches',
  },
  {
    id: 'meat-wraps',
    emoji: 'bg-meatwraps',
  },
  {
    id: 'burritos',
    emoji: 'bg-burritos',
  },
  {
    id: 'roast-chicken',
    emoji: 'bg-roastchicken',
  },
  {
    id: 'fried-chicken',
    emoji: 'bg-friedchicken',
  },
];

export default toSortedTags('freshMeatLabel', FRESH_MEAT);
