import {Box, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

import styles from './DashboardCardWrapper.module.scss';

interface IProps {
  navigateTo: string;
  title: string;
  children: React.ReactNode;
  isRecentActivity?: boolean;
}

const DashboardCardWrapper = ({
  navigateTo,
  title,
  children,
  isRecentActivity = false,
}: IProps) => {
  return (
    <Box
      component={Link}
      to={navigateTo}
      className={`${styles.wrapper} ${
        isRecentActivity ? styles.recentActivityBlock : ''
      }`}>
      <Box className={styles.header}>
        <Typography variant="text-lg">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default DashboardCardWrapper;
