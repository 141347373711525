import {Box} from '@mui/material';
import {Logo} from 'components/atoms';
import {type ReactNode} from 'react';
import styles from './AuthContainer.module.scss';

interface IProps {
  children: ReactNode;
}

const AuthContainer = ({children}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Logo />
        {children}
      </Box>
    </Box>
  );
};

export default AuthContainer;
