import {PasswordRecoveryTemplate} from 'components/templates';
import axios from 'axios';
import {loginResetPasswordDeeplink} from 'environment/api/services/auth';
import {PASSWORD_RECOVERY_EMAIL_SCHEMA} from 'environment/validation/resolvers';
import {useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';

const defaultValues = {
  email: '',
};

const PasswordRecovery = () => {
  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: PASSWORD_RECOVERY_EMAIL_SCHEMA,
  });

  const [formSent, setFormSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({email}) => {
    try {
      setIsLoading(true);
      await loginResetPasswordDeeplink({email: email.trim()});
      setFormSent(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <PasswordRecoveryTemplate formSent={formSent} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default PasswordRecovery;
