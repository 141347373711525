import {toSortedTags} from './utils';

const VIBES_AND_SERVICES = [
  {
    id: 'zen-vibes',
    emoji: 'bg-zenvibes',
  },
  {
    id: 'artistic-flair',
    emoji: 'bg-artisticflair',
  },
  {
    id: 'trendy-aesthetics',
    emoji: 'bg-trendyaesthetics',
  },
  {
    id: 'stylish-touch',
    emoji: 'bg-stylishtouch',
  },
  {
    id: 'culinary-charm',
    emoji: 'bg-culinarycharm',
  },
  {
    id: 'timeless-classic',
    emoji: 'bg-timelessclassic',
  },
  {
    id: 'eclectic-mix',
    emoji: 'bg-eclecticmix',
  },
  {
    id: 'minimalist-chic',
    emoji: 'bg-minimalistchic',
  },
  {
    id: 'ornate-glam',
    emoji: 'bg-ornateglam',
  },
  {
    id: 'cozy-comfort',
    emoji: 'bg-cozycomfort',
  },
  {
    id: 'romantic',
    emoji: 'bg-romantic',
  },
  {
    id: 'picturesque',
    emoji: 'bg-picturesque',
  },
  {
    id: 'grand',
    emoji: 'bg-grand',
  },
  {
    id: 'elegant',
    emoji: 'bg-elegant',
  },
  {
    id: 'charming',
    emoji: 'bg-charming',
  },
  {
    id: 'celebrations',
    emoji: 'bg-celebrations',
  },
  {
    id: 'sophisticated',
    emoji: 'bg-sophisticatedsoirees',
  },
  {
    id: 'intimate',
    emoji: 'bg-intimatevibe',
  },
  {
    id: 'celebratory-fest',
    emoji: 'bg-celebratoryfest',
  },
  {
    id: 'unforgettable',
    emoji: 'bg-unforgettable',
  },
  {
    id: 'attentive-staff',
    emoji: 'bg-attentivestaff',
  },
  {
    id: 'friendly',
    emoji: 'bg-friendly',
  },
  {
    id: 'welcoming',
    emoji: 'bg-welcoming',
  },
  {
    id: 'hospitable',
    emoji: 'bg-hospitable',
  },
  {
    id: 'impeccable',
    emoji: 'bg-impeccable',
  },
  {
    id: 'personalized',
    emoji: 'bg-personalized',
  },
  {
    id: 'professional-staff',
    emoji: 'bg-professionalstaff',
  },
  {
    id: 'warm',
    emoji: 'bg-warm',
  },
  {
    id: 'courteous',
    emoji: 'bg-courteous',
  },
  {
    id: 'groove-zone',
    emoji: 'bg-groovezone',
  },
  {
    id: 'live-dance-acts',
    emoji: 'bg-livedanceacts',
  },
  {
    id: 'extravaganza',
    emoji: 'bg-extravaganza',
  },
  {
    id: 'dance-music',
    emoji: 'bg-dancemusic',
  },
  {
    id: 'dance-parties',
    emoji: 'bg-danceparties',
  },
  {
    id: 'cozy-place',
    emoji: 'bg-cozyplace',
  },
  {
    id: 'snug',
    emoji: 'bg-snug',
  },
  {
    id: 'cuddly',
    emoji: 'bg-cuddly',
  },
  {
    id: 'homely',
    emoji: 'bg-homely',
  },
  {
    id: 'unwind-haven',
    emoji: 'bg-unwindhaven',
  },
  {
    id: 'quaint',
    emoji: 'bg-quaint',
  },
  {
    id: 'eco-friendly',
    emoji: 'bg-ecofriendlyvibe',
  },
  {
    id: 'outdoors-vibe',
    emoji: 'bg-outdoorsvibe',
  },
  {
    id: 'verdant-beauty',
    emoji: 'bg-verdantbeauty',
  },
  {
    id: 'panoramic-views',
    emoji: 'bg-panoramicviews',
  },
  {
    id: 'wilderness',
    emoji: 'bg-wilderness',
  },
  {
    id: 'back-to-nature',
    name: 'Back.to.Nature',
    emoji: 'bg-backtonature',
  },
  {
    id: 'serene',
    emoji: 'bg-serenevibe',
  },
  {
    id: 'places-to-work',
    emoji: 'bg-placestowork',
  },
];

export default toSortedTags('vibeAndServiceLabel', VIBES_AND_SERVICES);
