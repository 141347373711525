import {useMemo} from 'react';
import {Box} from '@mui/material';

import {ReactComponent as Cube1} from 'assets/icons/cube1.svg';
import {ReactComponent as Cube2} from 'assets/icons/cube2.svg';
import {ReactComponent as Cube3} from 'assets/icons/cube3.svg';
import {ReactComponent as Cube4} from 'assets/icons/cube4.svg';
import {ReactComponent as Cube5} from 'assets/icons/cube5.svg';
import {ReactComponent as Cube6} from 'assets/icons/cube6.svg';
import styles from './RollScore.module.scss';
import {getScoresFromNumber} from 'environment/utils/getScoresFromNumber';

interface IProps {
  score: number;
  size?: 'small' | 'medium';
}

const RollScore = ({score, size = 'medium'}: IProps) => {
  const {first, second} = useMemo(() => getScoresFromNumber(score), [score]);

  const renderCube = (number: number) => {
    switch (number) {
      case 1:
        return <Cube1 />;
      case 2:
        return <Cube2 />;
      case 3:
        return <Cube3 />;
      case 4:
        return <Cube4 />;
      case 5:
        return <Cube5 />;
      case 6:
        return <Cube6 />;
    }
  };

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', gap: '4px'}}>
      <Box className={styles[size]}>{renderCube(first)}</Box>
      <Box className={styles[size]}>{renderCube(second)}</Box>
    </Box>
  );
};

export default RollScore;
