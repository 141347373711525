import {MobileStepper, MobileStepperProps} from '@mui/material';
import styles from './Stepper.module.scss';

interface IProps
  extends Omit<MobileStepperProps, 'backButton' | 'nextButton' | 'className'> {}

const Stepper = (props: IProps) => {
  return (
    <MobileStepper
      className={styles.stepper}
      backButton={null}
      nextButton={null}
      {...props}
    />
  );
};

export default Stepper;
