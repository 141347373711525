import {Box, Typography} from '@mui/material';
import {GoBackLink, Toggle} from 'components/atoms';
import {useTranslation} from 'react-i18next';

import styles from './PageHeader.module.scss';

interface IProps {
  title: string;
  backButtonNavigateTo?: string;
  rightSection?: React.ReactNode;
  isToggle?: boolean;
  onChangeAutoWaterfall?: (autoFeaturing: boolean) => void;
  autoFeaturing?: boolean;
}

const PageHeader = ({
  title,
  backButtonNavigateTo,
  rightSection,
  isToggle,
  onChangeAutoWaterfall,
  autoFeaturing = false,
}: IProps) => {
  const {t} = useTranslation();

  const handleAutoFeaturing = () => {
    if (onChangeAutoWaterfall) {
      onChangeAutoWaterfall(!autoFeaturing);
    }
  };

  return (
    <Box className={styles.wrapper}>
      {backButtonNavigateTo && (
        <GoBackLink size="large" navigateTo={backButtonNavigateTo} />
      )}

      {isToggle ? (
        <Box className={styles.container}>
          <Box sx={{display: 'flex', gap: '32px'}}>
            <Typography variant="app-title">{title}</Typography>
            <Box className={styles.toogleBox}>
              <Toggle checked={autoFeaturing} onChange={handleAutoFeaturing} />
              <Typography variant="text-base-light">{t('Auto')}</Typography>
            </Box>
          </Box>
          {rightSection}
        </Box>
      ) : (
        <Box className={styles.container}>
          <Typography variant="app-title">{title}</Typography>
          {rightSection}
        </Box>
      )}
    </Box>
  );
};

export default PageHeader;
