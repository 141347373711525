import {Box, Button, Typography} from '@mui/material';
import {IconMark} from 'components/atoms';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './UpgradeToPremuim.module.scss';

interface Props {
  title: string;
}

const UpgradeToPremium: FC<Props> = ({title}) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <Box display="flex" alignItems="center" gap="16px">
        <IconMark name="lock" size="24px" color={Colors.white} />
        <Typography variant="text-base-light">{title}</Typography>
      </Box>

      <Button variant="contained">
        <Typography color={Colors['default-gray-900']} variant="text-sm">
          {t('Upgrade.to.premium')}
        </Typography>
      </Button>
    </Box>
  );
};

export default UpgradeToPremium;
