import {Box} from '@mui/material';
import {Activity, ExpendituresCharts} from 'components/organisms';
import {useTranslation} from 'react-i18next';

interface IProps {
  chartData: any; //TODO add type
  areasData: any; //TODO add type
}

const ExpendituresTemplate = ({chartData, areasData}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box sx={{pt: '32px'}}>
      {/* <Activity
        data={chartData}
        title={t('Expenditures')}
        activityTotal={undefined}
      /> */}
      <ExpendituresCharts chartData={chartData} areasData={areasData} />
    </Box>
  );
};

export default ExpendituresTemplate;
