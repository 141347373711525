import {Box} from '@mui/material';
import {BoostsActivity, ExpandedMap} from 'components/organisms';
import styles from './Boosts.module.scss';

interface IProps {
  currentRestourant: any; //TODO add type
  nearbyRestourant: any; //TODO add type
  speedometerData: any; //TODO add type
}

const BoostsTemplate = ({
  currentRestourant,
  nearbyRestourant,
  speedometerData,
}: IProps) => {
  return (
    <Box className={styles.wrapper}>
      <BoostsActivity speedometerData={speedometerData} />
      <ExpandedMap
        currentRestourant={currentRestourant}
        nearbyRestourant={nearbyRestourant}
      />
    </Box>
  );
};

export default BoostsTemplate;
