import {IWaterfall, IWaterfallProcessed} from 'types/waterfall';
import {processWaterfallData} from './processWaterfallData';

export const processAllWaterfall = async (
  waterfalls: IWaterfall[],
): Promise<IWaterfallProcessed[]> => {
  const processingPromises = waterfalls.map(async (waterfall) => {
    return await processWaterfallData(waterfall);
  });

  const settledResults = await Promise.allSettled(processingPromises);

  return settledResults
    .filter((result) => result.status === 'fulfilled')
    .map(
      (result) => (result as PromiseFulfilledResult<IWaterfallProcessed>).value,
    );
};
