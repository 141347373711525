import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import styles from './ColumnChart.module.scss';
import Colors from 'styles/colors.scss';

interface IProps {
  data: any;
  isEmpty?: boolean;
}

const ColumnChart = ({data, isEmpty = false}: IProps) => {
  const seriesData = Object.entries(data).map(([age, value]) => ({
    name: age,
    data: [Number(value)],
  }));

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      width: 450,
    },
    title: {
      text: null,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      labels: {
        style: {
          color: Colors['default-gray-200'],
          fontSize: '12px',
        },
        formatter: (context: Highcharts.AxisLabelsFormatterContextObject) => {
          return +context.value >= 1000
            ? `${+context.value / 1000}k`
            : context.value;
        },
      },
      lineWidth: 0,
      gridLineColor: Colors['default-gray-700'],
      title: {
        enabled: false,
      },
    },
    tooltip: isEmpty
      ? {
          enabled: false,
        }
      : {
          headerFormat: '',
          pointFormat: '<b>{point.y}</b>',
          useHTML: true,
          borderWidth: 1,
          borderColor: ' #ffffff33',
          backgroundColor: '#616161',
          padding: 10,
          borderRadius: 8,
          style: {
            color: '#FFF',
            fontSize: '12px',
          },
        },
    legend: {enabled: false},
    plotOptions: {
      column: {
        borderRadiusTopLeft: '50%',
        borderRadiusBottomLeft: '50%',
        borderRadiusTopRight: '50%',
        borderRadiusBottomRight: '50%',
        borderRadius: '50%',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0.05,
        pointWidth: 9.27,
        color: isEmpty
          ? 'transparent'
          : {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 0,
              },
              stops: [
                [0, 'rgba(52, 211, 153, 1)'],

                [1, 'rgba(255, 249, 97, 1)'],
              ],
            },
      },
    },
    credits: {
      enabled: false,
    },
    series: seriesData,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{className: styles.chartContainer}}
    />
  );
};

export default ColumnChart;
