import {Box, Typography} from '@mui/material';
import {FormButton, Input, Link, ServerErrorMessage} from 'components/atoms';
import {ROUTES} from 'environment/constants/routes';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const {t} = useTranslation();

  const {
    formState: {errors},
  } = useFormContext();

  return (
    <>
      <Typography variant="app-title">{t('Welcome.back')}</Typography>

      <Box className={styles.fields}>
        <Input
          name="email"
          type="email"
          label={t('Email')}
          placeholder={t('Email')}
          autoComplete="email"
        />

        <Input
          name="password"
          type="password"
          label={t('Password')}
          placeholder={t('Password')}
          autoComplete="password"
        />

        {errors.root?.serverError?.message ? (
          <ServerErrorMessage message={errors.root.serverError.message} />
        ) : null}

        <Link navigateTo={ROUTES.login.passwordRecovery} alignSelf="end">
          {t('Forgot.password')}
        </Link>
      </Box>

      <Box className={styles.bottomContent}>
        <FormButton fullWidth>{t('Log.in')}</FormButton>
        <Box className={styles.bottomText}>
          <Typography variant="text-sm-light">
            {t('Dont.have.an.account.yet')}
          </Typography>
          <Link navigateTo={ROUTES.signup.home}>{t('Sign.up')}</Link>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
