import {Box, IconButton, Typography} from '@mui/material';
import {Date, Profile} from 'components/atoms';
import IconMark from 'components/atoms/icon/IconMark';
import FeaturedWaterfallTablePreview from 'components/molecules/featuredWaterfalls/featuredWaterfallTablePreview/FeaturedWaterfallTablePreview';
import i18n from 'environment/plugins/i18n';
import {roundNumberToDecimal} from 'environment/utils/roundNumberToDecimal';
import Colors from 'styles/colors.scss';
import styles from './FeaturedWaterfallsTable.module.scss';
import {IWaterfall} from 'types/waterfall';
import QuickReplyInput from 'components/atoms/quickReplyInput/QuickReplyInput';
import {countWaterfallTime} from 'environment/utils/countWaterfallTime';
import {GradientIcon} from 'components/molecules';

type TableEntity = IWaterfall;

export const columns = (
  toggleWaterfall: (
    businessUnitId: string,
    waterfallId: string,
    featured: boolean,
  ) => void,
  sendQuickCommentToWaterfall: (
    waterfallId: string,
    commentText: string,
  ) => any,
  updateWaterfallReply: (waterfallId: string, reply: string | null) => void,
) =>
  [
    {
      id: 'story',
      label: i18n.t('Waterfalls'),
      sortable: false,
      alignCenter: false,
      sx: {width: '65px'},
      renderCell(row: TableEntity) {
        const createdAt = row.createdAt;
        const type = row.featured === true ? 'featured' : 'mostRecent';
        const mediaType = row?.media?.type === 'video' ? true : false;

        const {remainingTime, progress} = countWaterfallTime(createdAt, type);
        return (
          <FeaturedWaterfallTablePreview
            badgeText={remainingTime}
            progress={progress}
            mediaSrc={row?.media?.url}
            isVideo={mediaType}
            thumbnail={row?.media?.thumbnailUrl}
          />
        );
      },
    },
    {
      id: 'addStory',
      label: (
        <Box className={styles.addWaterfallHeader}>
          <Typography variant="text-xs">{i18n.t('Add')}</Typography>
          <Typography variant="text-xs">{i18n.t('waterfall')}</Typography>
        </Box>
      ),
      sortable: false,
      alignCenter: true,
      sx: {width: '90px'},
      renderCell(row: TableEntity) {
        const {
          id,
          featured,
          businessUnit: {id: unitId},
        } = row;

        const handleClick = () => toggleWaterfall(unitId, id, featured);

        return (
          <>
            {featured ? (
              <IconButton onClick={handleClick}>
                <GradientIcon name="tick" size="24px" />
              </IconButton>
            ) : (
              <IconButton onClick={handleClick}>
                <IconMark
                  name="circle-with-plus"
                  color={Colors['default-gray-400']}
                  size="24px"
                />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      id: 'user',
      label: i18n.t('Users'),
      sortable: true,
      alignCenter: true,
      renderCell(row: TableEntity) {
        const {
          firstName,
          lastName,
          rating,
          about,
          avatar,
          createdAt,
          engagersCount,
          engagingsCount,
        } = row.user;
        return (
          <Box className={styles.profileWrapper}>
            <Profile
              hideUsername
              value={{
                firstName,
                lastName,
                rating,
                about,
                avatar,
                createdAt,
                engagersCount,
                engagingsCount,
              }}
            />
          </Box>
        );
      },
    },
    {
      id: 'date',
      label: i18n.t('Date'),
      sortable: true,
      alignCenter: true,
      renderCell(row: TableEntity) {
        const value = row.createdAt;
        return <Date date={value} />;
      },
    },
    {
      id: 'views',
      label: i18n.t('Views'),
      sortable: true,
      alignCenter: true,
      renderCell(row: TableEntity) {
        const value = row.engagement.watches.count;
        return (
          <Typography variant="text-xs">
            {value === 0 ? '--' : roundNumberToDecimal(value)}
          </Typography>
        );
      },
    },
    {
      id: 'likes',
      label: (
        <Box>
          <Typography variant="text-xs">{i18n.t('Likes')}</Typography>
        </Box>
      ),
      sortable: true,
      alignCenter: true,
      renderCell(row: TableEntity) {
        const value = row.engagement.likes.count;
        return (
          <Typography variant="text-xs">
            {`${roundNumberToDecimal(value)} ${
              value === 1 ? i18n.t('like') : i18n.t('likes')
            }`}
          </Typography>
        );
      },
    },
    {
      id: 'comments',
      label: i18n.t('Comments'),
      sortable: true,
      alignCenter: true,
      renderCell(row: TableEntity) {
        const value = row.engagement.comments.count;
        return (
          <Typography variant="text-xs">
            {value === 0 ? '--' : roundNumberToDecimal(value)}
          </Typography>
        );
      },
    },
    {
      id: 'quickReply',
      label: i18n.t('Quick.reply'),
      sortable: true,
      alignCenter: true,
      renderCell(row: TableEntity) {
        const value = row.reply;
        return (
          <QuickReplyInput
            comment={value}
            sendComment={sendQuickCommentToWaterfall}
            waterfallId={row.id}
            updateWaterfallReply={updateWaterfallReply}
          />
        );
      },
    },
  ] as const;
