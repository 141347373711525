import {LoaderOverlay} from 'components/atoms';
import {SignUpBusinessDetailsTemplate} from 'components/templates';
import axios from 'axios';
import {signUpBusinessDetails} from 'environment/api/services/auth';
import {useSignUpRoutes} from 'hooks/signUpNavigation';
import {type SignUpBusinessFormData} from 'hooks/forms';
import {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

const SignUpBusinessDetails = () => {
  const {control, setError, trigger, clearErrors} =
    useFormContext<SignUpBusinessFormData>();

  const businessDetails = useWatch({
    control,
    name: 'businessDetails',
  });

  const signUpRoutes = useSignUpRoutes();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      const isValid = await trigger('businessDetails');
      if (!isValid) {
        return;
      }

      setIsLoading(true);

      await signUpBusinessDetails({
        // TODO find better approach: yup.trim() behaves weird
        firstName: businessDetails.firstName.trim(),
        lastName: businessDetails.lastName.trim(),
        companyName: businessDetails.companyName.trim(),
        websiteUrl: businessDetails.websiteUrl.trim() || null,
        city: businessDetails.city.trim(),
        country: businessDetails.country.trim(),
        phone: businessDetails.phone.trim(),
        street: businessDetails.street.trim(),
        state: businessDetails.state.trim() || undefined,
        number: businessDetails.number.trim() || undefined,
        zipcode: businessDetails.zipcode.trim() || undefined,
      });

      navigate(signUpRoutes.schedule);
      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpBusinessDetailsTemplate onSubmit={onSubmit} />
    </>
  );
};

export default SignUpBusinessDetails;
