import {createModel} from '@rematch/core';
import type {RootModel} from '.';
import {AxiosError} from 'axios';
import {
  getAllFoldersRequest,
  createNewFolderRequest,
  deleteFolderRequest,
  updateFolderNameRequest,
  moveUnitToFolderRequest,
} from 'environment/api/services/folders';
import {IBusinessUnit} from 'environment/types/business';

export interface IFolder {
  name: string;
  businessId: string;
  businessUnits: IBusinessUnit[];
  childFolders: string[];
  parentFolderId: string | null;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface IFolders {
  allFolders: IFolder[];
}

const initialState: IFolders = {
  allFolders: [],
};

export const folders = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAllFolders: (state, payload: IFolder[]) => {
      return {
        ...state,
        allFolders: payload,
      };
    },
  },
  effects: {
    async getAllFolders() {
      try {
        const {data} = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async postCreateNewFolder(payload) {
      try {
        const {folderName} = payload;
        await createNewFolderRequest(folderName);
        const {data} = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async updateFolderName(payload) {
      try {
        const {folderId, folderName} = payload;
        await updateFolderNameRequest(folderId, folderName);
        const {data} = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },

    async addUnitToFolder(payload) {
      try {
        const {businessUnitId, folderId} = payload;
        await moveUnitToFolderRequest(businessUnitId, folderId);
        const {data} = await getAllFoldersRequest();
        this.setAllFolders(data);
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
      }
    },
  },
});
