import {Box} from '@mui/material';
import {SignUpPersonalizeThreeTagsTemplate} from 'components/templates';
import {useSignUpRoutes} from 'hooks/signUpNavigation';
import {useNavigate} from 'react-router-dom';

const SignUpPersonalizeTags = () => {
  const navigate = useNavigate();

  const signUpRoutes = useSignUpRoutes();

  const onSubmit = () => {
    navigate(signUpRoutes.fifteenTags);
  };

  return (
    <Box sx={{width: '836px'}}>
      <SignUpPersonalizeThreeTagsTemplate onSubmit={onSubmit} />
    </Box>
  );
};

export default SignUpPersonalizeTags;
