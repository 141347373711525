import {Box, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './CardProfile.module.scss';
import {IUserProfile} from 'types';
import {getRatingColor} from 'environment/utils/getRatingColor';
import {getDefaultAvatarByRating} from 'environment/utils/getDefaultAvatarByRating';
import {calculateDaysElapsed} from 'environment/utils/countUserCreatedAt';

interface Value {
  firstName: string;
  lastName: string;
  profile?: IUserProfile;
  avatar?: string | null;
  username?: string;
  rating: number;
  about?: string | null;
  engagersCount?: number;
  engagingsCount?: number;
  createdAt: string;
}

interface IProps {
  value: Value;
}

const CardProfile = ({value}: IProps) => {
  const {
    avatar,
    firstName,
    lastName,
    username,
    rating,
    engagersCount,
    engagingsCount,
    createdAt,
    about,
  } = value;
  const imageUrl = avatar ? avatar : '';

  const {t} = useTranslation();

  const renderImage = useMemo(
    () =>
      avatar && avatar !== null ? (
        <Box component="img" src={imageUrl} className={`${styles.image}`} />
      ) : (
        <Box
          component="img"
          src={getDefaultAvatarByRating(rating)}
          width={25}
          height={25}
        />
      ),
    [avatar, imageUrl, rating],
  );

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Box display="flex" alignItems="center" gap="12px">
        {renderImage}
        <Typography color={Colors['default-gray-200']} variant="text-xs">
          ({calculateDaysElapsed(createdAt)}d)
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography color={getRatingColor(rating)} variant="text-sm-semibold">
          {`${firstName} ${lastName}`}
        </Typography>
        {username && (
          <Typography noWrap variant="text-xs">
            @{username}
          </Typography>
        )}
      </Box>
      <Typography color={Colors['default-gray-200']} variant="text-xs">
        {about ?? ''}
      </Typography>
      <Box display="flex" gap="16px">
        <Box display="flex" alignItems="center" gap="8px">
          <Typography variant="text-base-semibold">{engagersCount}</Typography>
          <Typography variant="text-sm-light">{t('Engaging')}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="8px">
          <Typography variant="text-base-semibold">{engagingsCount}</Typography>
          <Typography variant="text-sm-light">{t('Engagers')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CardProfile;
