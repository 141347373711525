import {toSortedTags} from './utils';

const FRESH_SEAFOOD = [
  {
    id: 'oceans-bounty',
    emoji: 'bg-oceansbounty',
  },
  {
    id: 'aquatic-feast',
    emoji: 'bg-aquaticfeast',
  },
  {
    id: 'water-delicacies',
    emoji: 'bg-waterdelicacies',
  },
  {
    id: 'off-the-hook',
    emoji: 'bg-offthehook',
  },
  {
    id: 'octopus',
    emoji: 'bg-octopus',
  },
  {
    id: 'lobster-luxury',
    emoji: 'bg-lobsterluxury',
  },
  {
    id: 'seafood-platters',
    emoji: 'bg-seafoodplatters',
  },
];

export default toSortedTags('freshSeafoodLabel', FRESH_SEAFOOD);
