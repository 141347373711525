import {TReviewReplyRequestBody} from 'store/models/reviewReplies';
import axios from '../axios';
import {REVIEW_REPLY_URL} from 'environment/constants/url';

export const replyOnReview = (
  reviewId: string,
  requestBody: TReviewReplyRequestBody,
) => {
  return axios.post(REVIEW_REPLY_URL(reviewId).REVIEW_REPLY, requestBody);
};

export const getAllReviewReplies = (
  reviewId: string,
  take: number,
  cursorId?: string,
) => {
  const cursorIdQuery = cursorId ? `&cursorId=${cursorId}&` : '';

  const url = `${
    REVIEW_REPLY_URL(reviewId).REVIEW_REPLIES_LIST
  }?${cursorIdQuery}take=${take}`;

  return axios.get(url);
};
