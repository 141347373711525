import {Box} from '@mui/material';
import ScheduleTimePicker from 'components/molecules/scheduleTimePicker/ScheduleTimePicker';
import {daysNames} from 'environment/constants/dayNames';
import {useTranslation} from 'react-i18next';
import styles from './SignUpScheduleForm.module.scss';

const SignUpScheduleForm = () => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.container}>
        {daysNames.map((dayName) => {
          return (
            <ScheduleTimePicker
              key={dayName}
              name={`schedule.${dayName}`}
              title={t(dayName)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default SignUpScheduleForm;
