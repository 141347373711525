import {ThemeOptions} from '@mui/material/styles/createTheme';

import Colors from 'styles/colors.scss';

export const MuiSelect: ThemeOptions['components'] = {
  MuiSelect: {
    defaultProps: {
      variant: 'standard',
    },
    styleOverrides: {
      standard: {
        backgroundColor: 'transparent',
        color: Colors['default-gray-100'],
      },
    },
  },
};
