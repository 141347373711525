import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './MapBoard.module.scss';
import PlaceCard from '../../atoms/placeCard/PlaceCard';

interface IProps {
  data: any; // TODO add type
  onCardClick: (index: number) => void;
  activeItem: number | null;
}

const MapBoard = ({data, onCardClick, activeItem}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <Typography variant="text-lg">{t('Your.area')}</Typography>

      <Box className={styles.wrapper}>
        {/* TODO add type */}
        {data.map((item: any, index: number) => {
          return (
            <PlaceCard
              id={index}
              name={item.name}
              data={item.data}
              graphData={item.graphData}
              key={`${item.name}-${index}`}
              onClick={() => onCardClick(index)}
              isActive={activeItem === index}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default MapBoard;
