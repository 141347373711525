import {Box} from '@mui/material';
import {Logo, Stepper} from 'components/atoms';
import {SignUpSuccess} from 'components/molecules';
import {createContext, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import styles from './SignUpLayout.module.scss';

type ContextValue = [boolean, (value: boolean) => void];
const initialState: ContextValue = [false, (value: boolean) => {}];

export const SuccessContext = createContext(initialState);

const SignUpLayout = () => {
  const {pathname} = useLocation();
  const activeStep = Number(pathname.split('/').pop()) - 1 || 0;

  const [success, setSuccess] = useState(false);

  return (
    <SuccessContext.Provider value={[success, setSuccess]}>
      <Box className={styles.wrapper}>
        <Box className={styles.container}>
          {success ? (
            <SignUpSuccess />
          ) : (
            <>
              <Box className={styles.header}>
                <Logo />
                <Stepper activeStep={activeStep} steps={9} />
              </Box>
              <Outlet />
            </>
          )}
        </Box>
      </Box>
    </SuccessContext.Provider>
  );
};

export default SignUpLayout;
