export const typography = {
  fontFamily: 'Poppins, sans-serif',
  'text-base': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  'text-base-light': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  'text-base-medium': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  'text-base-semibold': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  'text-base-bold': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  'small-app-title': {
    fontSize: '30px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  'app-title': {
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: 500,
  },
  'extra-large': {
    fontSize: '48px',
    lineHeight: '115%',
    fontWeight: 500,
  },
  'text-xs': {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },

  'text-sm': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  'text-sm-semibold': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  'text-sm-bold': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
  },
  'text-sm-light': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  'text-sm-lighter': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 300,
  },
  'text-sm-lighter-16': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 300,
  },
  'text-xl': {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  'text-xl-semibold': {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  'text-2xl': {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  'text-2xl-light': {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
  },
  'text-lg': {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  'text-lg-light': {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  'text-xs-weird': {
    fontSize: '11px',
    lineHeight: '12px',
    fontWeight: 400,
    letterSpacing: '0.16px',
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'text-base': React.CSSProperties;
    'text-base-semibold': React.CSSProperties;
    'text-base-bold': React.CSSProperties;
    'text-base-medium': React.CSSProperties;
    'small-app-title': React.CSSProperties;
    'app-title': React.CSSProperties;
    'text-xs': React.CSSProperties;
    'text-sm': React.CSSProperties;
    'text-sm-light': React.CSSProperties;
    'text-sm-lighter': React.CSSProperties;
    'text-sm-lighter-16': React.CSSProperties;
    'text-xl': React.CSSProperties;
    'text-xl-semibold': React.CSSProperties;
    'text-2xl': React.CSSProperties;
    'text-2xl-light': React.CSSProperties;
    'text-lg': React.CSSProperties;
    'text-lg-light': React.CSSProperties;
    'extra-large': React.CSSProperties;
    'text-base-light': React.CSSProperties;
    'text-sm-semibold': React.CSSProperties;
    'text-sm-bold': React.CSSProperties;
    'text-xs-weird': React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    'text-base'?: React.CSSProperties;
    'text-base-semibold'?: React.CSSProperties;
    'text-base-bold'?: React.CSSProperties;
    'text-base-medium'?: React.CSSProperties;
    'small-app-title'?: React.CSSProperties;
    'app-title'?: React.CSSProperties;
    'text-xs'?: React.CSSProperties;
    'text-sm'?: React.CSSProperties;
    'text-sm-light'?: React.CSSProperties;
    'text-sm-lighter'?: React.CSSProperties;
    'text-sm-lighter-16'?: React.CSSProperties;
    'text-xl'?: React.CSSProperties;
    'text-xl-semibold'?: React.CSSProperties;
    'text-2xl'?: React.CSSProperties;
    'text-2xl-light'?: React.CSSProperties;
    'text-lg'?: React.CSSProperties;
    'text-lg-light'?: React.CSSProperties;
    'extra-large'?: React.CSSProperties;
    'text-base-light'?: React.CSSProperties;
    'text-sm-semibold'?: React.CSSProperties;
    'text-sm-bold'?: React.CSSProperties;
    'text-xs-weird'?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'text-base': true;
    'text-base-semibold': true;
    'text-base-bold': true;
    'text-base-medium': true;
    'small-app-title': true;
    'app-title': true;
    'text-xs': true;
    'text-sm': true;
    'text-sm-light': true;
    'text-xl': true;
    'text-xl-semibold': true;
    'text-2xl': true;
    'text-lg': true;
    'text-lg-light': true;
    'extra-large': true;
    'text-base-light': true;
    'text-sm-semibold': true;
    'text-sm-bold': true;
    'text-2xl-light': true;
    'text-sm-lighter': true;
    'text-sm-lighter-16': true;
    'text-xs-weird': true;
  }
}
