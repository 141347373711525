import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Colors from 'styles/colors.scss';

interface IProps {
  data: any;
  colors: string[];
  name: string;
  size: number;
}

const PieChart = ({data, colors, name, size}: IProps) => {
  const options = {
    chart: {
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<b>{point.key}</b> ',
      pointFormat: '<b>{point.percentage:.1f}%</b>',
      borderWidth: 1,
      borderColor: ' rgba(255, 255, 255, 0.20)',
      backgroundColor: 'rgb(97, 97, 97)',
      padding: 10,
      borderRadius: 8,
      style: {
        color: Colors.white,
        fontSize: '12px',
      },
    },
    credits: {
      enabled: false, // remove highchart.com link
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        borderRadius: 0,
        size: 200,
        dataLabels: {
          enabled: true,
          distance: -33,
          format: '{point.percentage:.1f}%',
          style: {
            color: Colors['default-gray-900'],
            fontSize: '14px',
            textOutline: '0px',
            fontWeight: 400,
          },
        },
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
    },
    colors: colors,
    series: [
      {
        type: 'pie',
        name: name,
        data: data,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{style: {minHeight: size + 'px', width: size + 'px'}}}
    />
  );
};

export default PieChart;
