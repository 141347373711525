import {Box} from '@mui/material';
import {PageHeader, RangeButtons, SpeedometerCard} from 'components/molecules';
import {ROUTES} from 'environment/constants/routes';
import {useTranslation} from 'react-i18next';
import styles from './BoostsActivity.module.scss';

interface IProps {
  speedometerData: any; // TODO add type
}

const BoostsActivity = ({speedometerData}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <PageHeader
        title={t('Business.boosts.in.your.area')}
        backButtonNavigateTo={ROUTES.dashboard.home}
        rightSection={<RangeButtons period="24h" setPeriod={() => {}} />}
      />

      <Box className={styles.items}>
        {/* TODO add type */}
        {speedometerData.map((data: any, index: any) => (
          <SpeedometerCard
            key={index}
            header={data.header}
            amount={data.amount}
            value={data.value}
            title={data.title}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BoostsActivity;
