import {useState, useEffect} from 'react';
import FeaturedWaterfallsTemplate from 'components/templates/featuredWaterfalls/FeaturedWaterfalls';
import useWaterfalls from 'hooks/useWaterfalls';
import useUnits from 'hooks/useUnits';
import {
  sendQuickComment,
  deleteWaterfall,
  updateBusinessUnitFeaturedWaterfall,
} from 'environment/api/services/waterfalls';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'store';
import {useDebouncedCallback} from 'use-debounce';
import {reportPublication} from 'environment/api/services/report';

const FeaturedWaterfalls = () => {
  const dispatch = useDispatch<Dispatch>();

  const {currentUnit} = useUnits();
  const {
    waterfallsPaginated,
    waterfallsFeatured,
    waterfallsMostRecent,
    getWaterfallPaginatedData,
    getWaterfallMostRecentData,
    getWaterfallFeaturedData,
    updateWaterfallReply,
  } = useWaterfalls();

  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [autoFeaturing, setAutoFeaturing] = useState<boolean>(
    currentUnit.autoFeaturing,
  );

  const handleRowsPerPage = (rowPerPage: number) => {
    setRowsPerPage(rowPerPage);
  };
  const handlePaginationPage = (newPage: number) => {
    setPage(newPage);
  };

  const handleDeleteWaterfall = async (waterfallId: string) => {
    try {
      await deleteWaterfall(waterfallId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReportWaterfall = async (waterfallId: string) => {
    try {
      await reportPublication('waterfall', waterfallId);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeAutoFeaturedWaterfall = async (autoFeaturing: boolean) => {
    try {
      await updateBusinessUnitFeaturedWaterfall(currentUnit.id, autoFeaturing);
      setAutoFeaturing(autoFeaturing);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleWaterfallOnFeatured = useDebouncedCallback(
    (businessUnitId: string, waterfallId: string, featured: boolean) => {
      dispatch.waterfalls.toggleWaterfallOnFeatured({
        businessUnitId,
        waterfallId,
        featured,
      });
    },
    300,
  );

  useEffect(() => {
    getWaterfallPaginatedData(currentUnit.id, rowsPerPage);
    getWaterfallMostRecentData(currentUnit.id, '72h');
    getWaterfallFeaturedData(currentUnit.id, 'featured');
  }, [currentUnit.id, rowsPerPage, page, deleteWaterfall]);

  return (
    <FeaturedWaterfallsTemplate
      tableWaterfallData={waterfallsPaginated}
      mostRecentWaterfallData={waterfallsMostRecent}
      featuredWaterfallData={waterfallsFeatured}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={handleRowsPerPage}
      page={page}
      setPage={handlePaginationPage}
      handleDeleteWaterfall={handleDeleteWaterfall}
      handleReportWaterfall={handleReportWaterfall}
      onChangeAutoWaterfall={onChangeAutoFeaturedWaterfall}
      autoFeaturing={autoFeaturing}
      toggleWaterfall={toggleWaterfallOnFeatured}
      sendQuickCommentToWaterfall={sendQuickComment}
      updateWaterfallReply={updateWaterfallReply}
    />
  );
};

export default FeaturedWaterfalls;
