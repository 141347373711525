import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import styles from './ViewOnlyColumn.module.scss';
import Colors from 'styles/colors.scss';

interface IProps {
  data: any;
}

const ViewOnlyColumn = ({data}: IProps) => {
  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
    },
    title: {
      text: null,
      margin: 0,
    },
    xAxis: {
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
      endOnTick: false,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      endOnTick: false,
    },
    tooltip: {enabled: false},
    legend: {enabled: false},
    credits: {enabled: false},
    plotOptions: {
      column: {
        borderRadiusTopLeft: '50%',
        borderRadiusBottomLeft: '50%',
        borderRadiusTopRight: '50%',
        borderRadiusBottomRight: '50%',
        borderRadius: '50%',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        // pointWidth: 8,
        color: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 0,
          },
          stops: [
            [0, Colors['landing-green1']],
            [1, Colors['landing-green2']],
          ],
        },
      },
    },
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{className: styles.chartContainer}}
    />
  );
};

export default ViewOnlyColumn;
