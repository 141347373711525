import {ExpendituresTemplate} from 'components/templates';

import {donutChartRaceData, basicAreaData} from 'environment/configs/mockData';

const Expenditures = () => {
  return (
    <ExpendituresTemplate
      // TODO: update chart data from dashboard
      chartData={donutChartRaceData}
      areasData={basicAreaData}
    />
  );
};

export default Expenditures;
