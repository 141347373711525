import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './ChartRow.module.scss';

interface IPops {
  title: string;
  color?: string;
  count: number;
}

const ChartRow = ({title, color, count}: IPops) => {
  const {t} = useTranslation();

  return (
    <Box className={styles.container}>
      <Box className={styles.setBlock}>
        <Box className={styles.circle} style={{background: color}} />
        <Typography variant="text-sm">{t(title)}</Typography>
      </Box>
      <Typography variant="text-sm" className={styles.number}>
        {count}
      </Typography>
    </Box>
  );
};

export default ChartRow;
