import {useTranslation} from 'react-i18next';

import ErrorOrSuccess from 'components/templates/errorOrSucceess/ErrorOrSuccess';
import {errorImages} from './constatnts';
import {getRandomNumber} from 'environment/utils/getRandomNumber';

const Error = () => {
  const {t} = useTranslation();

  return (
    <ErrorOrSuccess
      title={t('Oops.Something.went.wrong,check.your.internet.connection')}
      secondButtonLabel={t('Refresh.page')}
      onClickSecondButton={() => window.location.reload()}
      image={errorImages[getRandomNumber(errorImages.length)]}
    />
  );
};

export default Error;
