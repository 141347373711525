import {Box, IconButton, Popover, Typography} from '@mui/material';
import {FilledCheckbox, IconMark} from 'components/atoms';
import {BusinessUnitItem} from 'components/molecules';
import {EXTRA_DROPDOWNS, MAIN_DROPDOWNS} from 'environment/constants/tags';
import {IBusinessUnit} from 'environment/types/business';
import {stringifyLocation} from 'environment/utils/stringifyLocation';
import {SignUpBusinessFormData} from 'hooks/forms';
import {questions} from 'hooks/forms/default-values';
import {
  castQuestionsForFrontend,
  castTagsForFrontend,
} from 'hooks/forms/helpers';
import {useMemo, useState, type FC, type MouseEvent} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import Colors from 'styles/colors.scss';
import styles from './UseSameAsDropdown.module.scss';
import {isFifteenTagsEqual, isQuestionsEqual, isThreeTagsEqual} from './utils';
import useUnits from 'hooks/useUnits';

interface IProps {
  label: string;
  field: 'three-tags' | 'fifteen-tags' | 'questions';
}

const UseSameAsDropdown: FC<IProps> = ({label, field}) => {
  const {currentUnit, businessUnits} = useUnits();

  const {control, setValue, resetField} =
    useFormContext<SignUpBusinessFormData>();

  const [currentTags, currentQuestions] = useWatch({
    control,
    name: ['tags', 'questions'],
  });

  const [selectedUnit, setSelectedUnit] = useState(currentUnit);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBusinessUnitClick = (unit: IBusinessUnit) => {
    setSelectedUnit(unit);

    if (field === 'questions') {
      setValue('questions', castQuestionsForFrontend(unit.questions));
    } else {
      const selectedUnitTags = castTagsForFrontend(
        unit.businessUnitPickedLabels,
      );

      if (field === 'three-tags') {
        for (const {name} of MAIN_DROPDOWNS) {
          setValue(`tags.${name}`, selectedUnitTags[name]);
        }
      } else if (field === 'fifteen-tags') {
        for (const {name} of EXTRA_DROPDOWNS) {
          setValue(`tags.${name}`, selectedUnitTags[name]);
        }
      }
    }

    handleClose();
  };

  const checked = useMemo(() => {
    // TODO: isn't it better to store casted values straight in redux after receiving data from the server,
    // so there'd be no need in doing this casting all the time?

    if (field === 'questions') {
      const selectedUnitQuestions = castQuestionsForFrontend(
        selectedUnit.questions,
      );

      return isQuestionsEqual(currentQuestions, selectedUnitQuestions);
    } else {
      const selectedUnitTags = castTagsForFrontend(
        selectedUnit.businessUnitPickedLabels,
      );

      if (field === 'three-tags') {
        return isThreeTagsEqual(currentTags, selectedUnitTags);
      } else if (field === 'fifteen-tags') {
        return isFifteenTagsEqual(currentTags, selectedUnitTags);
      }
    }
  }, [
    field,
    currentQuestions,
    currentTags,
    selectedUnit.questions,
    selectedUnit.businessUnitPickedLabels,
  ]);

  const handleCheckboxClick = () => {
    if (checked) {
      if (field === 'questions') {
        resetField('questions', {defaultValue: questions});
      } else if (field === 'three-tags') {
        for (const {name} of MAIN_DROPDOWNS) {
          resetField(`tags.${name}`, {defaultValue: ''});
        }
      } else if (field === 'fifteen-tags') {
        for (const {name} of EXTRA_DROPDOWNS) {
          resetField(`tags.${name}`, {defaultValue: ''});
        }
      }
    } else {
      handleBusinessUnitClick(selectedUnit);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap="4px">
        <Box className={styles.checkbox} onClick={handleCheckboxClick}>
          <FilledCheckbox checked={checked} />
          <Typography variant="text-base-light">{label}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="4px">
          <Typography variant="text-base-medium" color={Colors['HEXB8FE61']}>
            {`${selectedUnit.companyName}${
              selectedUnit.location
                ? ` (${stringifyLocation(selectedUnit.location)})`
                : ''
            }`}
          </Typography>
          <IconButton sx={{padding: 0}} onClick={handleOpen}>
            <IconMark
              name="arrow-down"
              color={Colors['default-gray-300']}
              size="24px"
            />
          </IconButton>
        </Box>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{paper: {className: styles.schedulePopover}}}>
        {businessUnits?.length
          ? businessUnits.map((businessUnit) => (
              <BusinessUnitItem
                key={businessUnit.id}
                name={businessUnit.companyName}
                location={stringifyLocation(businessUnit.location)}
                onClick={() => handleBusinessUnitClick(businessUnit)}
              />
            ))
          : null}
      </Popover>
    </>
  );
};

export default UseSameAsDropdown;
