import {LoaderOverlay} from 'components/atoms';
import {SignUpPersonalizeFifteenTagsTemplate} from 'components/templates';
import axios from 'axios';
import {signUpBusinessTags} from 'environment/api/services/auth';
import {useSignUpRoutes} from 'hooks/signUpNavigation';
import {type SignUpBusinessFormData} from 'hooks/forms';
import {castTagsForBackend} from 'hooks/forms/helpers';
import {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

const SignUpPersonalizeFifteenTags = () => {
  const signUpRoutes = useSignUpRoutes();

  const {control, setError, clearErrors} =
    useFormContext<SignUpBusinessFormData>();

  const tags = useWatch({control, name: 'tags'});

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await signUpBusinessTags({
        businessUnitPickedLabels: castTagsForBackend(tags),
      });

      navigate(signUpRoutes.mantras);
      clearErrors('root.serverError');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <SignUpPersonalizeFifteenTagsTemplate onSubmit={onSubmit} />
    </>
  );
};

export default SignUpPersonalizeFifteenTags;
