import Colors from 'styles/colors.scss';

interface IProps {
  grid?: boolean;
}

export const configStyles = ({grid = false}: IProps) => {
  const showGrid = ({x = false, y = false}) => {
    if (x) {
      return grid ? {gridLineWidth: 1} : {lineWidth: 0};
    }
    if (y) {
      return grid ? {gridLineWidth: 1} : {gridLineDashStyle: 'LongDash'};
    }
  };

  return {
    chart: {
      backgroundColor: 'transparent',
      spacingTop: 8,
      spacingBottom: 0,
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      itemMarginTop: 0,
      itemMarginBottom: 14,
      padding: 0,
      itemStyle: {
        fontSize: '12px',
        lineHeight: '16px',
        color: Colors.white,
        fontWeight: 400,
      },
      itemHoverStyle: {
        color: Colors['default-gray-200'],
      },
    },
    xAxis: {
      gridLineColor: Colors['default-gray-600'],
      ...showGrid({x: true}),
      labels: {
        style: {
          color: Colors['default-gray-200'],
          fontSize: '12px',
        },
      },
    },
    yAxis: {
      gridLineColor: Colors['default-gray-600'],
      ...showGrid({y: true}),
      labels: {
        style: {
          color: Colors['default-gray-200'],
          fontSize: '12px',
        },
      },
      title: {
        enabled: false,
      },
    },
    tooltip: {
      useHTML: true,
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0.20)',
      backgroundColor: 'rgb(97, 97, 97)',
      padding: 10,
      borderRadius: 8,
      style: {
        color: Colors.white,
        fontSize: '12px',
      },
    },
  };
};
