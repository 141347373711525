import {Box, Popover, Typography} from '@mui/material';
import {IconMark} from 'components/atoms';
import {getMediaById} from 'environment/api/services/media';
import {FC, MouseEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './BusinessUnitAvatar.module.scss';

interface Props {
  mediaId: string;
  onFileClick: () => void;
  onDeleteClick: () => void;
}

const BusinessUnitAvatar: FC<Props> = ({
  mediaId,
  onFileClick,
  onDeleteClick,
}) => {
  const {t} = useTranslation();

  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    getMediaById(mediaId)
      .then(({data: {body}}) => {
        if (Array.isArray(body)) {
          setImageSrc(body.at(-1)?.url ?? '');
        } else {
          setImageSrc(body?.url ?? '');
        }
      })
      .catch(console.error);
  }, [mediaId]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="inline-flex">
      <button
        style={{backgroundImage: `url(${imageSrc})`}}
        className={styles.avatarImage}
        onClick={handleClick}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{paper: styles.popoverPaper}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box className={styles.avatarPopover}>
          <Box
            display="flex"
            alignItems="center"
            gap="14px"
            component="button"
            onClick={onFileClick}>
            <IconMark name="folder-add" size="24px" color={Colors.HEXB8FE61} />
            <Typography variant="text-sm-lighter-16">{t('File')}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="14px"
            component="button"
            onClick={onDeleteClick}>
            <IconMark name="trash" size="24px" color={Colors.HEXB8FE61} />
            <Typography variant="text-sm-lighter-16">
              {t('Delete.photo')}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default BusinessUnitAvatar;
