import {Box, Button, Typography} from '@mui/material';
import {LoaderOverlay, ServerErrorMessage} from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import SignUpBusinessDetailsForm from 'components/organisms/signUpForms/SignUpBusinessDetails/SignUpBusinessDetailsForm';
import axios from 'axios';
import {
  updateBusinessUnit,
  updateBusinessUnitLocation,
} from 'environment/api/services/business-units';
import {UNIT_DETAILS_SCHEMA} from 'environment/validation/resolvers';
import {NewBusinessFormData} from 'hooks/forms';
import useUnits from 'hooks/useUnits';
import {useState, type FC} from 'react';
import {
  FormProvider,
  useForm,
  useFormState,
  type SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'store';
import Colors from 'styles/colors.scss';
import styles from './EditBusinessDetails.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  defaultValues: {
    businessDetails: NewBusinessFormData['businessDetails'];
  };
}

const EditBusinessDetails: FC<IProps> = ({open, onClose, defaultValues}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const {
    currentUnit: {id},
  } = useUnits();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {control, setError, handleSubmit, trigger, ...form} = useForm({
    values: defaultValues,
    defaultValues,
    mode: 'onChange',
    resolver: UNIT_DETAILS_SCHEMA,
    // TODO: sync with resolver, don't bypass type-checking
  }) as unknown as UseFormReturn<typeof defaultValues>;

  const {errors} = useFormState({control, name: 'businessDetails'});

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({
    businessDetails,
  }) => {
    try {
      const isValid = await trigger();
      if (!isValid) return;

      setIsLoading(true);

      const location = {
        country: businessDetails.country.trim(),
        city: businessDetails.city.trim(),
        zipcode: businessDetails.zipcode.trim() || undefined,
        street: businessDetails.street.trim(),
        state: businessDetails.state.trim() || undefined,
        number: businessDetails.number.trim() || undefined,
      };

      const locationPromise = updateBusinessUnitLocation(id, location);
      const businessInfoPromise = updateBusinessUnit(id, {
        companyName: businessDetails.companyName.trim(),
        contactNumber: businessDetails.phone.trim(),
        websiteUrl: businessDetails.websiteUrl.trim() || undefined,
      });

      const [updatedLocation, updatedBusinessInfo] = await Promise.all([
        locationPromise,
        businessInfoPromise,
      ]);

      dispatch.units.updateCurrentBusinessUnit({
        id,
        location: updatedLocation.data.location,
      });

      const {companyName, contactNumber, websiteUrl} = updatedBusinessInfo.data;
      dispatch.units.updateCurrentBusinessUnit({
        id,
        companyName,
        contactNumber,
        websiteUrl,
      });

      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <SettingsModal open={open} onClose={handleClose}>
      {isLoading && <LoaderOverlay />}
      <FormProvider
        control={control}
        setError={setError}
        trigger={trigger}
        handleSubmit={handleSubmit}
        {...form}>
        <Box className={styles.container}>
          <Typography variant="text-2xl" textAlign="center">
            {t('Edit.business.details')}
          </Typography>
          <SignUpBusinessDetailsForm withLabel={false} hideFirstLastName />

          {errors.root?.serverError?.message && (
            <ServerErrorMessage message={errors.root.serverError.message} />
          )}

          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            <Typography
              variant="text-base-semibold"
              color={Colors['default-gray-900']}>
              {t('Save.&.Update')}
            </Typography>
          </Button>
        </Box>
      </FormProvider>
    </SettingsModal>
  );
};

export default EditBusinessDetails;
