import {Box, Button, Divider, Typography} from '@mui/material';
import axios from 'axios';
import {Input, LoaderOverlay, ServerErrorMessage} from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import {changePassword} from 'environment/api/services/settings';
import {CHANGE_PASSWORD_SCHEMA} from 'environment/validation/resolvers';
import {FC, useState} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Colors from 'styles/colors.scss';
import styles from './ChangePasswordModal.module.scss';

const defaultValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

interface IProps {
  open: boolean;
  onForgot: () => void;
  onClose: () => void;
}

const ChangePasswordModal: FC<IProps> = ({open, onForgot, onClose}) => {
  const {t} = useTranslation();

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: CHANGE_PASSWORD_SCHEMA,
  });

  const isValid = form.formState.isValid;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({
    oldPassword,
    newPassword,
  }) => {
    try {
      setIsLoading(true);
      await changePassword({oldPassword, newPassword});
      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status =
          error.response?.data?.status ?? error.response?.data?.statusCode;

        if (status === 409) {
          form.setError('root.serverError', {
            type: error.code,
            message: error.response?.data?.message ?? error.message,
          });
        } else if (status === 403) {
          form.setError('root.serverError', {
            type: error.code,
            message: t('Wrong.password'),
          });
        }
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <SettingsModal
      title={t('Change.password')}
      open={open}
      onClose={handleClose}
      withCloseButton>
      {isLoading && <LoaderOverlay />}
      <FormProvider {...form}>
        <form className={styles.wrapper} onSubmit={form.handleSubmit(onSubmit)}>
          <Box className={styles.container}>
            <Box>
              <Input
                name="oldPassword"
                type="password"
                label={t('fields.oldPassword')}
                placeholder={t('fields.oldPassword')}
                autoComplete="current-password"
              />
              <Typography
                onClick={onForgot}
                className={styles.forgotPassword}
                color={Colors['HEXB8FE61']}
                component="button"
                variant="text-sm-light"
                type="button">
                {t('Forgot.password')}
              </Typography>
            </Box>

            <Divider
              orientation="horizontal"
              sx={{background: Colors['default-gray-400']}}
            />

            <Input
              name="newPassword"
              type="password"
              label={t('fields.newPassword')}
              placeholder={t('fields.newPassword')}
              autoComplete="new-password"
            />
            <Input
              name="confirmNewPassword"
              type="password"
              label={t('fields.confirmPassword')}
              placeholder={t('fields.confirmPassword')}
              autoComplete="new-password"
            />
          </Box>

          {form.formState.errors.root?.serverError?.message ? (
            <ServerErrorMessage
              message={form.formState.errors.root.serverError.message}
            />
          ) : null}

          <Button
            type="submit"
            variant="contained"
            disabled={!isValid}
            className={styles.submitButton}>
            <Typography
              color={Colors['default-gray-900']}
              variant="text-base-semibold">
              {t('Change.password')}
            </Typography>
          </Button>
        </form>
      </FormProvider>
    </SettingsModal>
  );
};

export default ChangePasswordModal;
