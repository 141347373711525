import {Box} from '@mui/material';
import {GoBackLink, LoaderOverlay} from 'components/atoms';
import Stepper from 'components/atoms/stepper/Stepper';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import {
  SignUpPersonalizeFifteenTagsTemplate,
  SignUpPersonalizeThreeTagsTemplate,
} from 'components/templates';
import axios from 'axios';
import {updateBusinessUnit} from 'environment/api/services/business-units';
import {CategoriesOfTags} from 'environment/types/api/shared';
import {castTagsForBackend} from 'hooks/forms/helpers';
import useUnits from 'hooks/useUnits';
import {useCallback, useMemo, useState, type FC} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'store';
import styles from './EditTags.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  defaultValues: {tags: Required<CategoriesOfTags>};
}

const EditTags: FC<IProps> = ({open, onClose, defaultValues}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<Dispatch>();

  const {
    currentUnit: {id},
  } = useUnits();

  const {setError, ...form} = useForm({
    defaultValues,
    values: defaultValues,
    mode: 'onChange',
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset(defaultValues);
    setActiveStep(0);
  }, [form, onClose, defaultValues]);

  const onThreeTagsSubmit = () => {
    setActiveStep(1);
  };

  const onFifteenTagsSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    async ({tags}) => {
      try {
        setIsLoading(true);

        const {data} = await updateBusinessUnit(id, {
          businessUnitPickedLabels: castTagsForBackend(tags),
        });

        dispatch.units.updateCurrentBusinessUnit({
          id,
          businessUnitPickedLabels: data.businessUnitPickedLabels,
        });

        handleClose();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError('root.serverError', {
            type: error.code,
            message: error.response?.data?.message ?? error.message,
          });
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose, setError, id, dispatch.units],
  );

  const steps = useMemo(
    () => [
      <SignUpPersonalizeThreeTagsTemplate
        onSubmit={onThreeTagsSubmit}
        withBackButton={false}
        withUseAsDropdown
      />,
      <SignUpPersonalizeFifteenTagsTemplate
        onSubmit={form.handleSubmit(onFifteenTagsSubmit)}
        withBackButton={false}
        withLabel={false}
        withUseAsDropdown
      />,
    ],
    [form, onFifteenTagsSubmit],
  );

  return (
    <SettingsModal open={open} onClose={handleClose}>
      {isLoading && <LoaderOverlay />}
      <FormProvider setError={setError} {...form}>
        <Box className={styles.header}>
          <GoBackLink
            onClick={
              activeStep === 0 ? onClose : () => setActiveStep((s) => s - 1)
            }
          />
          <Stepper activeStep={activeStep} steps={2} />
        </Box>
        {steps[activeStep]}
      </FormProvider>
    </SettingsModal>
  );
};

export default EditTags;
