import {LoaderOverlay} from 'components/atoms';
import {AuthContainer} from 'components/molecules';
import {LoginForm} from 'components/organisms';
import {FC} from 'react';

interface IProps {
  isLoading?: boolean;
}

const LoginTemplate: FC<IProps> = ({isLoading = false}) => {
  return (
    <AuthContainer>
      {isLoading && <LoaderOverlay />}
      <LoginForm />
    </AuthContainer>
  );
};

export default LoginTemplate;
