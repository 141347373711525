import {useState} from 'react';
import {Box} from '@mui/material';

import styles from './ExpandedMap.module.scss';
import {PlaceMarker, Zoom} from 'components/atoms';
import {MapBoard} from 'components/molecules';

import Map, {Marker} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_TEST_MAPBOX_ACCESS_TOKEN;

interface IProps {
  currentRestourant: any; //TODD add types
  nearbyRestourant: any; //TODD add types
}

const ExpandedMap = ({currentRestourant, nearbyRestourant}: IProps) => {
  const {latitude, longitude} = currentRestourant;

  const [zoomLevel, setZoomLevel] = useState(3.5);
  const [viewState, setViewState] = useState({
    longitude: longitude,
    latitude: latitude,
    zoom: zoomLevel,
  });

  const [activeItem, setActiveItem] = useState<number | null>(null);

  const handleCardClick = (index: number) => {
    setActiveItem(index);

    setViewState(() => ({
      longitude: nearbyRestourant[index].longitude,
      latitude: nearbyRestourant[index].latitude,
      zoom: 6.5,
    }));

    const selectedCard = document.getElementById(`placeCard_${index}`);
    if (selectedCard) {
      selectedCard.scrollIntoView({behavior: 'smooth'});
    }
  };

  const MAX_ZOOM = 15;
  const MIN_ZOOM = 1;

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.5, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.5, MIN_ZOOM));
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.map}>
        <Map
          mapboxAccessToken={MAPBOX_TOKEN}
          {...viewState}
          onMove={(evt) => setViewState(evt.viewState)}
          zoom={zoomLevel}
          mapStyle="mapbox://styles/mapbox/dark-v10">
          <Marker longitude={longitude} latitude={latitude} anchor="bottom">
            <PlaceMarker
              isOwner={true}
              imageSize={{
                width: '26px',
                height: '28px',
              }}
              imageCardSize={{
                padding: '11px 13px 13px',
                top: '8px',
              }}
              textStyles={{fontSize: '16px', lineHeight: '24px'}}
              backgroundStyles={{
                bottom: '-2px',
                left: '0px',
                height: '35px',
                width: '150px',
              }}
            />
          </Marker>
          {/* TODO add types */}
          {nearbyRestourant.map((item: any, index: number) => {
            return (
              <Marker
                onClick={() => handleCardClick(index)}
                key={index + 1}
                longitude={item.longitude}
                latitude={item.latitude}
                anchor="bottom">
                <PlaceMarker
                  isOwner={false}
                  data={item.data}
                  title={item.name}
                  imageSize={{width: '40px', height: '40px'}}
                  textStyles={{fontSize: '14px', lineHeight: '20px'}}
                />
              </Marker>
            );
          })}
        </Map>
      </Box>

      <Box className={styles.zoomButtons}>
        <Zoom icon="+" onClick={handleZoomIn} />
        <Zoom icon="-" onClick={handleZoomOut} />
      </Box>

      <Box className={styles.boardWrapper}>
        <MapBoard
          data={nearbyRestourant}
          onCardClick={handleCardClick}
          activeItem={activeItem}
        />
      </Box>
    </Box>
  );
};

export default ExpandedMap;
