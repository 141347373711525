import {Popover as MUIPopover} from '@mui/material';

import styles from './Popover.module.scss';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  children: React.ReactNode;
  onClose: () => void;
  noStyles?: boolean;
}

const Popover = ({children, anchorEl, onClose, noStyles}: IProps) => {
  const customClasses = noStyles
    ? {paper: styles.hidden}
    : {paper: styles.paper};
  return (
    <MUIPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transitionDuration={300}
      onClose={onClose}
      classes={customClasses}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      {children}
    </MUIPopover>
  );
};

export default Popover;
