import {ThemeOptions} from '@mui/material/styles/createTheme';

import Colors from 'styles/colors.scss';

export const MuiButton: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        lineHeight: '20px',
        fontWeight: 400,
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
          border: 'none',
          boxShadow: 'none',
        },
      },
      outlined: {
        border: `1px solid ${Colors['default-gray-700']}`,
        borderRadius: '8px',
        backgroundColor: Colors['bg-color'],
        color: Colors.white,
        '&:hover': {
          border: `1px solid ${Colors['default-gray-700']}`,
          backgroundColor: Colors['bg-color'],
        },
      },
      text: {
        border: 'none',
        borderRadius: '8px',
        backgroundColor: Colors['bg-color'],
        color: Colors.white,
        '&:hover': {
          border: 'none',
          backgroundColor: Colors['bg-color'],
        },
      },
      contained: {
        border: 'none',
        borderRadius: '8px',
        background: `linear-gradient(270deg, ${Colors['gr-landing']})`,
        color: Colors['default-gray-900'],
        '&:hover': {
          border: 'none',
          backgroundColor: Colors['bg-color'],
        },
        '&:disabled': {
          opacity: 0.24,
        },
      },
      sizeSmall: {
        padding: '8px 10px',
        minWidth: '64px',
        borderRadius: '6px',
        fontSize: '12px',
      },
      sizeMedium: {
        fontSize: '14px',
        padding: '8px 16px',
        minWidth: '88px',
        borderRadius: '8px',
        minHeight: '44px',
      },
      sizeLarge: {
        padding: '8px 16px',
        minWidth: '140px',
        borderRadius: '12px',
        fontSize: '16px',
        minHeight: '48px',
        fontWeight: 500,
        lineHeight: '24px',
      },
    },
  },
};

// declare module '@mui/material/Button' {
//   interface ButtonPropsVariantOverrides {
//     outlined: true;
//   }
// }
