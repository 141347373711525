import {DevTool} from '@hookform/devtools';
import {Box, Button, Typography} from '@mui/material';
import {
  GoBackLink,
  IconMark,
  LoaderOverlay,
  ServerErrorMessage,
} from 'components/atoms';
import SettingsModal from 'components/molecules/settingsModal/SettingsModal';
import SignUpScheduleForm from 'components/organisms/signUpForms/SignUpScheduleForm/SignUpScheduleForm';
import axios from 'axios';
import {updateBusinessUnit} from 'environment/api/services/business-units';
import {Schedule} from 'environment/types';
import {castScheduleForBackend} from 'hooks/forms/helpers';
import useUnits from 'hooks/useUnits';
import {useState, type FC} from 'react';
import {FormProvider, useForm, type SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'store';
import Colors from 'styles/colors.scss';
import styles from './EditSchedule.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  defaultValues: {schedule: Schedule};
}

const EditSchedule: FC<IProps> = ({open, onClose, defaultValues}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const {
    currentUnit: {id},
  } = useUnits();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm({
    defaultValues,
    values: defaultValues,
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({schedule}) => {
    try {
      setIsLoading(true);

      const {data} = await updateBusinessUnit(id, {
        operationHours: castScheduleForBackend(schedule),
      });

      dispatch.units.updateCurrentBusinessUnit({
        id,
        operationHours: data.operationHours,
      });

      handleClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        form.setError('root.serverError', {
          type: error.code,
          message: error.response?.data?.message ?? error.message,
        });
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <SettingsModal open={open} onClose={handleClose}>
      {isLoading && <LoaderOverlay />}
      <FormProvider {...form}>
        <Box className={styles.container}>
          <GoBackLink onClick={onClose} />
          <Typography component="p" textAlign="center" variant="text-2xl-light">
            {t('What.times.are.you.open?')}
          </Typography>
          <SignUpScheduleForm />
          <Box className={styles.addHolidaySchedule}>
            <Typography variant="text-sm-semibold">
              {t('Add.holiday.schedule')}
            </Typography>
            <IconMark name="plus" size="24px" />
          </Box>

          {form.formState.errors.root?.serverError?.message ? (
            <ServerErrorMessage
              message={form.formState.errors.root.serverError.message}
            />
          ) : null}

          <Button variant="contained" onClick={form.handleSubmit(onSubmit)}>
            <Typography
              variant="text-base-semibold"
              color={Colors['default-gray-900']}>
              {t('Save.&.Update')}
            </Typography>
          </Button>
        </Box>
        <DevTool control={form.control} />
      </FormProvider>
    </SettingsModal>
  );
};

export default EditSchedule;
