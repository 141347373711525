import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';

import App from './App';
import {theme} from 'styles/mui-theme';
import {store} from 'store/index';

import './environment/plugins/i18n';
import 'styles/index.scss';
import {APP_VERSION} from './app.config'
Sentry.init({
  dsn: 'https://484d2aa7c6ba0c6822951d5c9881f132@o4506184016658432.ingest.sentry.io/4506308538204160',
  tunnel: 'https://node-js-vercel-eight.vercel.app/home/tunnel',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: process.env.REACT_APP_TYPE,
  // Performance Monitoring
  release: APP_VERSION,
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
);
