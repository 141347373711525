import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'store/index';
import {RootState} from 'store/index';

const useFolders = () => {
  const dispatch = useDispatch<Dispatch>();

  const {allFolders} = useSelector((state: RootState) => state.folders);

  const getAllBusinessFolders = () => {
    dispatch.folders.getAllFolders();
  };

  const createNewFolder = (folderName: string) => {
    dispatch.folders.postCreateNewFolder({folderName});
  };

  const editFolderName = (folderId: string, folderName: string) => {
    dispatch.folders.updateFolderName({folderId, folderName});
  };

  const moveUnitToFolder = (businessUnitId: string, folderId: string) => {
    dispatch.folders.addUnitToFolder({businessUnitId, folderId});
  };

  return {
    allFolders,
    getAllBusinessFolders,
    createNewFolder,
    editFolderName,
    moveUnitToFolder,
  };
};

export default useFolders;
